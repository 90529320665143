import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Button, H2, P } from "@spillchat/puddles"

import type { FunctionComponent } from "react"

export const CheckoutFailure: FunctionComponent = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet title="Checkout Success | Spill" />
      <div className="flex flex-col gap-8 max-w-screen-sm">
        <div className="flex flex-col gap-4 ">
          <H2>
            Oh no! We didn't manage to confirm your purchase. Please try again.
          </H2>
          <P>
            If you continue to have problems please email accounts@spill.chat
            and we'll be happy to help.
          </P>
        </div>
        <Button
          variant="primary"
          onClick={() => navigate("/admin/settings/billing")}
        >
          Try again
        </Button>
      </div>
    </>
  )
}
