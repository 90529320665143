import { Button, H2, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

export const TherapyAdhdCopayRequestConfirmation: FunctionComponent = () => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <H2>We've sent your request</H2>
        <P muted>
          Your company admin will review and process your request within 7 days.
          If you get approved, you'll receive an email with further
          instructions.
        </P>
      </div>
      <Button variant="primary" asChild>
        <Link to="/admin/therapy/specialised-support/adhd">Finish</Link>
      </Button>
    </div>
  )
}
