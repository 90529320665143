import { gql } from "@apollo/client"

export const mutations = {
  restrictUsers: gql(`
    mutation RestrictUsers($platformUserIds: [ID!]!) {
      disablePlatformUsers(platformUserIds: $platformUserIds) {
        success
        errorMessage
      }
    }
  `),
}
