// This file is intended to be used for the copy of the admin therapy specialised support guide page.
// In the future this could be used replaced by a query to get this data from the backend.

import {
  LightBulbIcon,
  HeartIcon,
  CalendarDaysIcon,
  UsersIcon,
  UserGroupIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline"
import { P } from "@spillchat/puddles"
import { ReactNode } from "react"

import { PackageType } from "types/graphql"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import imageBereavement from "common/assets/images/product/specialised-support/therapy-bereavement.png"
import imageParenthood from "common/assets/images/product/specialised-support/therapy-parenthood.png"

export type AdminSpecialisedSupportGuideContentFeature = {
  icon: ReactNode
  text: string
}

export type AdminSpecialisedSupportGuideContent = {
  image: string
  title: string
  description: ReactNode
  featureList: AdminSpecialisedSupportGuideContentFeature[]
  link: string
}

export const getContentFromPackageType = (
  _packageType: PackageType
): AdminSpecialisedSupportGuideContent => {
  switch (_packageType) {
    case PackageType.ADHD:
      return {
        title: "ADHD Support",
        description: (
          <>
            <P>
              With ADHD support, you can provide specialist help for employees
              who experience ADHD.
            </P>
            <P>
              This can be turned on for your whole team, and employees can
              access it regardless of whether they have received an official
              diagnosis or not. Employees do not need to make themselves known
              to an admin in order to access the support.
            </P>
          </>
        ),
        image: imageAdhd,
        featureList: [
          {
            icon: <LightBulbIcon />,
            text: "Sessions with an ADHD specialist",
          },
          {
            icon: <HeartIcon />,
            text: "A recommended 2 sessions per employee",
          },
          {
            icon: <CalendarDaysIcon />,
            text: "Sessions come out of employee's existing credits",
          },
        ],
        link: "/admin/specialised-support/create/adhd-support",
      }
    case PackageType.PARENTHOOD:
      return {
        title: "Parenthood Support",
        description: (
          <>
            <P muted size="lg">
              Support for new parents going through the joys of becoming a
              parent and the challenges around returning to work.
            </P>
            <P muted size="lg">
              This support package will give employees extra sessions, on top of
              their regular Spill allowance, which they can use to speak to a
              Parenthood specialist.
            </P>
            <P muted size="lg">
              After the support policy has been set up, employees can request to
              use the policy. They can do this anonymously if they choose.
            </P>
          </>
        ),
        image: imageParenthood,
        featureList: [
          {
            icon: <UserGroupIcon />,
            text: "Sessions with an parenthood specialist",
          },
          {
            icon: <HeartIcon />,
            text: "Minimum 4 extra sessions",
          },
          {
            icon: <CalendarDaysIcon />,
            text: "Support for 2 years",
          },
        ],
        link: "/admin/specialised-support/create/parenthood-support",
      }

    case PackageType.BEREAVEMENT:
      return {
        title: "Bereavement Support",
        description: (
          <>
            <P muted size="lg">
              Support a grieving employee, whether they’re returning to work
              after bereavement leave or struggling on the anniversary of losing
              a loved one.
            </P>
            <P muted size="lg">
              They will be able to access support with a bereavement specialist
              over the course of 2 years, giving them the support and time to
              process their loss at their own pace.
            </P>
          </>
        ),
        image: imageBereavement,
        featureList: [
          {
            icon: <LifebuoyIcon />,
            text: "Sessions with a bereavement specialist",
          },
          {
            icon: <HeartIcon />,
            text: "Minimum 4 extra sessions",
          },
          {
            icon: <CalendarDaysIcon />,
            text: "Support for 2 years",
          },
        ],
        link: "/admin/specialised-support/create/bereavement-support",
      }

    case PackageType.CUSTOM:
    default:
      return {
        title: "Custom Support",
        description: (
          <>
            <P muted>
              Custom support allows you to customise Spill to the unique
              situations people on your team are in.
            </P>
            <P muted>
              Support can be offered company-wide or to a group of one or more
              individuals. Recent examples of custom support include offering
              additional sessions to people during menopause, giving support to
              an individual caring for a sick parent and rolling out additional
              company-wide support following redundancies. You can make the
              support as specific as you need.
            </P>
          </>
        ),
        image:
          "https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/generic-green.svg",
        featureList: [
          {
            icon: <UsersIcon />,
            text: "Specialists tailored to your needs",
          },
          {
            icon: <HeartIcon />,
            text: "Set the number of sessions",
          },
          {
            icon: <CalendarDaysIcon />,
            text: "Set the support duration and timing",
          },
        ],
        link: "/admin/specialised-support/create/custom",
      }
  }
}
