import { FunctionComponent, useEffect, useState } from "react"
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Breadcrumbs, ModalFullScreenInner } from "@spillchat/puddles"

import { useApp } from "common/context/appContext"
import { useAuth } from "common/context/authContext"
import { NotFound404Page } from "common/components/NotFound404Page"
import { RequireAuth } from "features/auth/components/RequireAuth"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

import { GroupSelect } from "./GroupSelect"
import { InitialPlatformSetup } from "./InitialPlatformSetup"
import { PlatformSelect } from "./PlatformSelect"
import { SetupSuccessUnauthenticated } from "./SetupSuccessUnauthenticated"
import { TeamsPermissionsPending } from "./TeamsPermissionsPending"
import { LinkUserAccounts } from "./LinkUserAccounts"
import { RestrictUsers } from "./RestrictUsers"
import { PlatformConfirmation } from "./PlatformConfirmation"

export const platformSetupAnimationVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
}

export type PlatformTypeInfo = {
  name: string
  gatewayPath: string
  companyPlatformTerm: string
  tutorialWistiaId: string
}

export const platformTypesInfo: Record<string, PlatformTypeInfo> = {
  slack: {
    name: "Slack",
    gatewayPath: "/auth/slack/set-up",
    companyPlatformTerm: "workspace",
    tutorialWistiaId: "90xfxev8cn",
  },
  teams: {
    name: "Teams",
    gatewayPath: "/auth/teams/set-up",
    companyPlatformTerm: "organization",
    tutorialWistiaId: "",
  },
}
const urlBase = "/admin/settings/access/install"

/**
 * Steps for setting up Spill on a platform.
 *
 * Partially works for unauthenticated users, so parts can be completed by a Slack/Teams admin even
 * if they're not the buyer.
 */
export const PlatformSetup: FunctionComponent = () => {
  const location = useLocation()
  const { user } = useAuth()
  const { flags } = useUser()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const companyPlatformId = searchParams.get("companyPlatformId")
  const companyPlatformIdUrlParameter =
    companyPlatformId != null ? `?companyPlatformId=${companyPlatformId}` : ""

  const [platformType, setPlatformType] = useState<string | undefined>(
    undefined
  )
  const setParentPlatformType = (currentPlatformType: string | undefined) => {
    if (currentPlatformType != platformType) {
      setPlatformType(currentPlatformType)
    }
  }

  const { setPageBlob } = useApp()
  const [step, setStep] = useState<number>(0)
  useEffect(() => setPageBlob("none"), [])

  const useRestrictUsers = flags[FEATURE_FLAGS.USE_RESTRICT_USERS]

  const tabs = [
    { label: "Choose Platform", isActive: step >= 0, step: 0 },
    { label: "Add Spill", isActive: step >= 1, step: 1 },
    { label: "Add to channels", isActive: step >= 2, step: 2 },
    { label: "Link Users", isActive: step >= 3, step: 3 },
    ...(useRestrictUsers
      ? [{ label: "Restrict", isActive: step >= 4, step: 4 }]
      : []),
    { label: "Confirmation", isActive: step >= 5, step: 5 },
  ]

  const onClose = () => {
    navigate(user == null ? "/signin" : "/admin/settings/access")
  }

  const breadcrumbClick = (clickedStep: number) => {
    if (clickedStep > step) return
    switch (clickedStep) {
      case 0:
        navigate(urlBase)
        break
      case 1:
        navigate(`${urlBase}/${platformType}`)
        break
      case 2:
        navigate(
          `${urlBase}/${platformType}/success${companyPlatformIdUrlParameter}`
        )
        break
      case 3:
        navigate(
          `${urlBase}/${platformType}/link${companyPlatformIdUrlParameter}`
        )
        break
      case 4:
        navigate(
          `${urlBase}/${platformType}/restrict${companyPlatformIdUrlParameter}`
        )
        break
    }
  }

  return (
    <>
      <Helmet title={`Add Spill to your team | Spill`} />

      <ModalFullScreenInner
        onClose={onClose}
        onBack={() => breadcrumbClick(Math.floor(step - 1))}
        title="Set up Slack or MS Teams"
        showBackButton={user != null}
      >
        <div className="max-w-screen-sm mx-auto w-full px-3 lg:px-0 flex flex-col gap-y-12 pb-12">
          {user != null && (
            <Breadcrumbs
              onStepChange={breadcrumbClick}
              currentStep={step}
              tabs={tabs}
            />
          )}
          <Routes location={location}>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Step expectedStep={0} step={step} setStep={setStep} />
                  <PlatformSelect />
                </RequireAuth>
              }
            />
            <Route
              path="/:platformType"
              element={
                <>
                  <Step expectedStep={1} step={step} setStep={setStep} />
                  <InitialPlatformSetup
                    setParentPlatformType={setParentPlatformType}
                  />
                </>
              }
            />
            <Route
              path="/teams/pending/:platformExternalId"
              element={
                <>
                  <Step expectedStep={1.5} step={step} setStep={setStep} />
                  <TeamsPermissionsPending
                    setParentPlatformType={setParentPlatformType}
                  />
                </>
              }
            />
            <Route
              path="/:platformType/success"
              element={
                // The backend redirects here after installing the Slack app.
                // It doesn't know if the user is authenticated at that point,
                // so both of these pages have to be at the same route.
                <>
                  {user ? (
                    <>
                      <Step expectedStep={2} step={step} setStep={setStep} />
                      <GroupSelect
                        setParentPlatformType={setParentPlatformType}
                      />
                    </>
                  ) : (
                    <SetupSuccessUnauthenticated />
                  )}
                </>
              }
            />
            <Route
              path="/:platformType/link"
              element={
                <RequireAuth>
                  <Step expectedStep={3} step={step} setStep={setStep} />
                  <LinkUserAccounts
                    setParentPlatformType={setParentPlatformType}
                    restrictFlag={useRestrictUsers}
                  />
                </RequireAuth>
              }
            />
            {useRestrictUsers && (
              <Route
                path="/:platformType/restrict"
                element={
                  <RequireAuth>
                    <Step expectedStep={4} step={step} setStep={setStep} />
                    <RestrictUsers
                      setParentPlatformType={setParentPlatformType}
                    />
                  </RequireAuth>
                }
              />
            )}
            <Route
              path="/:platformType/confirmation"
              element={
                <RequireAuth>
                  <Step expectedStep={5} step={step} setStep={setStep} />
                  <PlatformConfirmation
                    setParentPlatformType={setParentPlatformType}
                  />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound404Page />} />
          </Routes>
        </div>
      </ModalFullScreenInner>
    </>
  )
}

const Step: FunctionComponent<{
  expectedStep: number
  step: number
  setStep: (step: number) => void
}> = ({ expectedStep, step, setStep }) => {
  useEffect(() => {
    if (step != expectedStep) {
      setStep(expectedStep)
    }
  }, [step, expectedStep])
  return <></>
}
