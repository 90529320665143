import { useMutation, useQuery } from "@apollo/client"
import {
  CalendarDateRangeIcon,
  ChatBubbleLeftIcon,
  CheckIcon,
  ChevronLeftIcon,
  StarIcon,
} from "@heroicons/react/24/outline"
import {
  Button,
  H1,
  H2,
  H3,
  HeaderCard,
  P,
  ProgressBar,
  Tooltip,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useParams } from "react-router-dom"
import { toast } from "sonner"
import { format, isAfter, subMinutes } from "date-fns"

import customPlaceholder from "common/assets/images/product/specialised-support/therapy-custom-grey.png"
import { Dialog } from "common/components/Dialog"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  AppointmentStatus,
  PackageType,
  PackageRequestStatus,
  TherapySpecialisedSupportPackageCancelMutation,
  TherapySpecialisedSupportPackageCancelMutationVariables,
  TherapySpecialisedSupportPackageLifeEventQuery,
  TherapySpecialisedSupportPackageLifeEventQueryVariables,
  TherapySpecialisedSupportPackageQuery,
  TherapySpecialisedSupportPackageQueryVariables,
} from "types/graphql"
import { getPackageConfig } from "features/admin-therapy/packages/specialised-package-config"
import { AnonymityInSpill } from "common/components/AnonymityInSpill"
import { formatMonthsNumberToYearOrMonthString } from "common/helpers/formatMonth"
import { getIconByDisplayName, HeroIconName } from "common/helpers/iconPalette"

import { mutations } from "./therapy-specialised-support-package.mutations"
import { queries } from "./therapy-specialised-support-package.queries"

export const TherapySpecialisedSupportPackagePage: FunctionComponent = () => {
  const params = useParams<{ packageId: string | undefined }>()

  const [cancelRequestModal, setCancelRequestModal] = useState(false)
  const toggleCancelRequestModal = () => {
    setCancelRequestModal(!cancelRequestModal)
  }
  const { data, called, loading } = useQuery<
    TherapySpecialisedSupportPackageQuery,
    TherapySpecialisedSupportPackageQueryVariables
  >(queries.getPackage, {
    variables: {
      id: params.packageId ?? "",
    },
    nextFetchPolicy: "network-only",
  })

  const { data: lifeEvent } = useQuery<
    TherapySpecialisedSupportPackageLifeEventQuery,
    TherapySpecialisedSupportPackageLifeEventQueryVariables
  >(queries.getLifeEvent, {
    variables: {
      id: data?.getCompanyPackageSettingById?.lifeEventId ?? "",
    },
    skip: data == null,
  })

  const user = data?.user

  const packageType =
    data?.getCompanyPackageSettingById?.packageType ?? PackageType.CUSTOM

  const config = getPackageConfig(packageType)

  const pkg = data?.getCompanyPackageSettingById
  const pkgRequests = data?.user?.packageRequests.filter(
    extension => extension.companyPackageSetting?.id === params.packageId
  )

  const currentTherapyPackageDetails = data?.user?.therapyPackages?.find(
    pkg2 => {
      return pkg2.companyPackageSetting?.id === pkg?.id
    }
  )

  const currentTherapyPackage = currentTherapyPackageDetails?.therapyPackages[0]

  const usedTherapyPackageSessions =
    currentTherapyPackageDetails?.therapyPackages?.reduce(
      (sum, session) => sum + (session.numberSessionsUsed ?? 0),
      0
    ) ?? 0

  //Return the null date if it exists as it means the package has no expiration date
  const getLatestDateOrNull = (dates: (string | null)[]) => {
    //First check if there is a null
    if (dates.includes(null)) return null

    if (dates.length === 0) return undefined

    //Then return the latest date
    return dates.reduce((latest, current) => {
      if (latest == null) return current
      return new Date(latest) > new Date(current!) ? latest : current
    })
  }

  const therapyPackageExpirationDate = getLatestDateOrNull(
    currentTherapyPackageDetails?.therapyPackages.map(
      pkg => pkg.expirationDate ?? null
    ) ?? []
  )

  const pkgRequest =
    pkgRequests && pkgRequests.length > 0
      ? pkgRequests.reduce((a, b) => {
          return new Date(a.createdAt) > new Date(b.createdAt) ? a : b
        })
      : null

  const remainingStandardSpillCredits =
    (user?.therapyUsageCap ?? 0) +
    (user?.additionalApprovedTherapySessions ?? 0) -
    (user?.numberSessionsUsedInCapPeriod ?? 0)

  const appointments = data?.user?.appointments

  const upcomingSessions = appointments?.filter(session => {
    return (
      session.status === AppointmentStatus.CONFIRMED &&
      isAfter(new Date(session.startsAt), subMinutes(new Date(), 50))
    )
  })

  const hasUpcomingSessions =
    upcomingSessions?.length != null && upcomingSessions?.length > 0

  const canBookSession =
    !hasUpcomingSessions &&
    currentTherapyPackageDetails?.numberSessionsGiven != null &&
    (usedTherapyPackageSessions == null ||
      usedTherapyPackageSessions <
        currentTherapyPackageDetails?.numberSessionsGiven +
          remainingStandardSpillCredits)

  const unlimitedTherapyCap = user?.therapyUsageCap == Number.MAX_SAFE_INTEGER

  const getDynamicIcon = (icon: HeroIconName) => {
    return getIconByDisplayName(icon)?.icon ?? StarIcon
  }

  const iconName = pkg?.icon as HeroIconName

  const CustomIcon = getDynamicIcon(iconName)

  const [cancelRequest, { loading: cancelLoading }] = useMutation<
    TherapySpecialisedSupportPackageCancelMutation,
    TherapySpecialisedSupportPackageCancelMutationVariables
  >(mutations.cancelRequest, {
    refetchQueries: ["TherapySpecialisedSupportPackage"],
    variables: {
      id: pkgRequest?.id ?? "",
      status: PackageRequestStatus.CANCELLED_BY_USER,
    },
    onCompleted() {
      toggleCancelRequestModal()
      toast("Request has been cancelled")
    },
  })

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  return (
    <>
      <Helmet title={`${pkg?.name ?? "Specialised support"} | Spill`} />
      <div className="flex flex-col gap-12">
        <Button variant="tertiary" asChild>
          <Link to="/therapy/specialised-support" className="no-underline">
            <div className="flex gap-1 items-center">
              <ChevronLeftIcon className="size-4" />
              <P weight="medium">Back</P>
            </div>
          </Link>
        </Button>
        <div className="flex flex-col-reverse lg:flex-row gap-8 lg:grow">
          <div className="flex flex-col gap-5 lg:w-2/3">
            <H1>{pkg?.name}</H1>
            <div className="flex flex-col gap-3">
              <H3>How it works</H3>
              <P muted className="whitespace-pre-line">
                {pkg?.userFacingDescription}
              </P>
            </div>
          </div>
          <div className="grow">
            <div className="lg:bg-transparent bg-grey-100 rounded-lg flex items-center justify-center lg:min-w-32 lg:min-h-32 aspect-square">
              <img
                src={pkg?.iconUrl ?? customPlaceholder}
                alt="Custom support"
                className="rounded-lg lg:w-full lg:h-full flex aspect-ratio"
              />
            </div>
          </div>
        </div>
        {currentTherapyPackage == null && (
          <>
            <hr />
            <div className="flex flex-col gap-8 max-w-md">
              <div className="flex flex-col gap-3">
                <H2>Request this support</H2>
                <P muted>
                  Send a request to your admin to access this support.
                </P>
              </div>
              <div className="grid">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-4">
                    <H3>What's included</H3>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-4">
                        <div className="text-spill-blue-800">
                          {<config.uspLineItem.icon width={24} height={24} />}
                        </div>
                        <P muted>{config.uspLineItem.text}</P>
                      </div>
                      <div className="flex items-center gap-4">
                        <ChatBubbleLeftIcon className="text-spill-blue-800 size-6" />
                        <P muted>
                          {pkg?.numberSessions} sessions on top of your regular
                          allowance
                        </P>
                      </div>
                      <div className="flex items-center gap-4">
                        <CalendarDateRangeIcon className="text-spill-blue-800 size-6" />
                        <P muted>
                          {pkg?.numberMonthsToUse != null
                            ? `Support for ${formatMonthsNumberToYearOrMonthString(pkg?.numberMonthsToUse)}`
                            : "Support indefinitely"}
                        </P>
                      </div>
                    </div>
                  </div>
                  {(pkgRequest == null ||
                    pkgRequest?.status === PackageRequestStatus.DECLINED ||
                    pkgRequest?.status ===
                      PackageRequestStatus.CANCELLED_BY_USER) && (
                    <>
                      <Button size="sm" variant="primary" asChild>
                        <Link to="request">Request support</Link>
                      </Button>
                      <AnonymityInSpill description="Your name won't be shared unless you choose to." />
                    </>
                  )}
                  {pkgRequest?.status === PackageRequestStatus.PENDING && (
                    <>
                      <div className="flex gap-2">
                        <Button size="sm" variant="readonly" asChild>
                          <div className="flex gap-1 items-center">
                            <CheckIcon width={16} />
                            <span>Requested</span>
                          </div>
                        </Button>
                        <Button
                          size="sm"
                          variant="destructive"
                          disabled={cancelLoading}
                          onClick={() => toggleCancelRequestModal()}
                        >
                          Cancel request
                        </Button>
                      </div>
                      <P size="xs" muted>
                        We've sent the request to your admins. They'll have 7
                        days to make a decision. Once they've done this, we'll
                        let you know.
                      </P>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {currentTherapyPackage != null && (
          <div className="flex flex-col gap-8 xl:max-w-lg">
            <div className="flex flex-col gap-3">
              <H2>Support</H2>
              <P muted>
                You get extra sessions on top of your regular allowance to speak
                to a&nbsp;specialist.
              </P>
            </div>
            <div className="flex gap-8 w-full">
              <div className="flex flex-col lg:flex-row gap-5 items-center">
                <ProgressBar
                  progressColour="spill-teal-600"
                  progressBackgroundColour="spill-teal-100"
                  currentProgress={usedTherapyPackageSessions}
                  totalProgress={
                    currentTherapyPackageDetails?.numberSessionsGiven ?? 0
                  }
                  title={`${pkg?.name} credits`}
                  progressDetail={`credits used`}
                />
                {unlimitedTherapyCap && (
                  <>
                    <H3>Spill credits: unlimited</H3>
                  </>
                )}
                {!unlimitedTherapyCap && (
                  <ProgressBar
                    totalProgress={Math.max(
                      user?.therapyUsageCap ?? 0,
                      user?.numberSessionsUsedInCapPeriod ?? 0
                    )}
                    currentProgress={user?.numberSessionsUsedInCapPeriod ?? 0}
                    title={`Spill credits`}
                    progressDetail={`credits used`}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <P weight="medium">
                You were given {pkg?.name}{" "}
                {therapyPackageExpirationDate != null
                  ? `until ${format(new Date(therapyPackageExpirationDate), "dd MMM yyyy")}`
                  : "indefinitely"}
              </P>
              <P size="xs" muted>
                If you use all your specialist credits before then, you can use
                your regular session credits to book with the same specialist.
              </P>
            </div>
            <div className="max-w-sm">
              <HeaderCard
                title={`${pkg?.name ?? "Specialised Support"} session`}
                icon={<CustomIcon width={24} height={24} />}
                description="Book a session with your specialist"
              >
                <div className="flex flex-wrap gap-4">
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger className="text-start" type="button">
                        <Button
                          variant="secondary"
                          size="sm"
                          disabled={!canBookSession}
                          asChild
                        >
                          {config.isConsultation &&
                          usedTherapyPackageSessions > 0 ? (
                            <Link to="/therapy/sessions">Go to sessions</Link>
                          ) : config.isConsultation &&
                            usedTherapyPackageSessions === 0 ? (
                            <Link
                              to={`/therapy/sessions/book?appointmentType=${config.appointmentTypeFeature}`}
                            >
                              Get started
                            </Link>
                          ) : (
                            <Link
                              to={`/therapy/sessions/book?appointmentType=${config.appointmentTypeFeature}&lifeEvent=${lifeEvent?.getLifeEventFromId?.slug}`}
                            >
                              Book a session
                            </Link>
                          )}
                        </Button>
                      </Tooltip.Trigger>
                      {hasUpcomingSessions && (
                        <Tooltip.Content>
                          Since you have an upcoming therapy session, you can't
                          book a {pkg?.name ?? "Specialised Support"} session.
                          Email therapy@spill.chat with questions.
                        </Tooltip.Content>
                      )}
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </HeaderCard>
            </div>
          </div>
        )}

        <div className="lg:w-2/3">{config.commonQuestions}</div>
      </div>
      <Dialog
        canClose
        isOpen={cancelRequestModal}
        onClose={() => toggleCancelRequestModal()}
        title="Cancel request?"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Not now",
            onClick: () => toggleCancelRequestModal(),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Cancel request",
            onClick: async () => {
              await cancelRequest()
              toggleCancelRequestModal()
            },
          },
        ]}
      >
        <P>
          You will be able to request this again if you change your&nbsp;mind.
        </P>
      </Dialog>
    </>
  )
}
