import {
  ChatBubbleLeftRightIcon,
  CheckIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline"
import { Alert, Button, DropdownMenu, H3, H4, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { toast } from "sonner"

import {
  PlatformType,
  AccessRemoveWorkspaceMutationVariables,
  AccessRemoveWorkspaceMutation,
  PlatformStatus,
} from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"
import { Dialog } from "common/components/Dialog"

import { CompanyPlatform } from "../access.page"
import { mutations } from "../access.mutations"

import { EditChannels } from "./EditChannels"

export type PlatformTypeInfo = {
  name: string
  companyPlatformTerm: string
}

interface IntegrationsAccessProps {
  userId: string
  companyId: string
  companyPlatforms: CompanyPlatform[]
  openInviteDialog: (companyPlatformId: string) => void
  refetch: () => void
}

export const IntegrationsAccess: FunctionComponent<IntegrationsAccessProps> = ({
  companyPlatforms,
  userId,
  companyId,
  openInviteDialog,
  refetch,
}) => {
  const { track } = useAnalytics()

  const integrationsPlatforms = companyPlatforms.filter(companyPlatform => {
    return (
      (companyPlatform.platformType == PlatformType.SLACK ||
        companyPlatform.platformType == PlatformType.TEAMS) &&
      companyPlatform.status != PlatformStatus.DISABLED
    )
  })

  return (
    <div className="flex flex-col gap-y-5 w-2/3">
      {/* Header of app integrations section */}
      <div className="flex flex-col gap-y-3">
        <ChatBubbleLeftRightIcon className="size-6" />
        <H3>App integrations</H3>
        {integrationsPlatforms.length > 0 ? (
          <P muted>You've added Spill to a workspace</P>
        ) : (
          <div className="flex flex-row gap-x-1">
            <P muted>Access Spill directly through Slack or MS Teams.</P>
            <Button variant="tertiary" asChild>
              <Link
                onClick={() =>
                  track("Admin clicks to setup up first integration", {
                    userId: userId,
                    companyId: companyId,
                    sourceUrl: window.location.href,
                  })
                }
                to={{ pathname: "/admin/settings/access/install" }}
              >
                Set up
              </Link>
            </Button>
          </div>
        )}
      </div>

      {/* Showing the integrations if they exist */}
      {integrationsPlatforms.length > 0 && (
        <>
          {integrationsPlatforms.map(integrationPlatform => (
            <IntegrationAccess
              key={integrationPlatform.id}
              companyPlatform={integrationPlatform}
              userId={userId}
              companyId={companyId}
              openInviteDialog={openInviteDialog}
              refetch={refetch}
            />
          ))}
          <Button variant="secondary" asChild>
            <Link
              onClick={() =>
                track("Admin clicks to add another workspace", {
                  userId: userId,
                  companyId: companyId,
                  sourceUrl: window.location.href,
                })
              }
              to={{ pathname: "/admin/settings/access/install" }}
            >
              Add another workspace
            </Link>
          </Button>
        </>
      )}
    </div>
  )
}

type IntegrationAccessProps = {
  userId: string
  companyId: string
  companyPlatform: CompanyPlatform
  openInviteDialog: (companyPlatformId: string) => void
  refetch: () => void
}

const IntegrationAccess: FunctionComponent<IntegrationAccessProps> = ({
  userId,
  companyId,
  companyPlatform,
  openInviteDialog,
  refetch,
}) => {
  const { track } = useAnalytics()
  const [editChannels, setEditChannels] = useState<boolean>(false)

  const [isRemoveWorkspaceOpen, setRemoveWorkspaceOpen] =
    useState<boolean>(false)

  let platformTypeInfo: PlatformTypeInfo
  if (companyPlatform.platformType == PlatformType.SLACK) {
    platformTypeInfo = {
      name: "Slack",
      companyPlatformTerm: "workspace",
    }
  } else {
    platformTypeInfo = {
      name: "Teams",
      companyPlatformTerm: "organization",
    }
  }

  const invitePlatform = () => {
    openInviteDialog(companyPlatform.id)
    track("Admin clicks to invite workspace", {
      userId: userId,
      companyPlatformId: companyPlatform.id,
      companyId: companyId,
      sourceUrl: window.location.href,
    })
  }

  const allChannels = companyPlatform.allowAccessAll
  let channels = companyPlatform.groups
    .map(group => {
      return `#${group.name}`
    })
    .join(" ")

  if (channels.length > 50) {
    channels = channels.slice(0, 50) + "..."
  }

  const editChannelsOnClick = () => {
    track("Admin clicks to edit workspace", {
      userId: userId,
      companyPlatformId: companyPlatform.id,
      companyId: companyId,
      sourceUrl: window.location.href,
    })
    setEditChannels(true)
  }

  const removeWorkspaceOnClick = () => {
    track("Admin clicks to remove workspace", {
      userId: userId,
      companyPlatformId: companyPlatform.id,
      companyId: companyId,
      sourceUrl: window.location.href,
    })
    setRemoveWorkspaceOpen(true)
  }

  return (
    <>
      <RemoveWorkspaceDialog
        isRemoveWorkspaceOpen={isRemoveWorkspaceOpen}
        setRemoveWorkspaceOpen={setRemoveWorkspaceOpen}
        companyPlatform={companyPlatform}
        platformTypeInfo={platformTypeInfo}
        refetch={refetch}
      />
      <EditChannels
        editChannels={editChannels}
        setEditChannels={setEditChannels}
        platformTypeInfo={platformTypeInfo}
        companyPlatformId={companyPlatform.id}
        refetch={refetch}
      />
      <div className="flex flex-col gap-y-3">
        <div className="border p-3 rounded-lg flex flex-row gap-x-3 justify-between items-center">
          {companyPlatform.avatarUrl != null ? (
            <img
              src={companyPlatform.avatarUrl}
              className="rounded-xl size-[60px]"
              alt=""
            />
          ) : (
            <div className="size-[60px] bg-spill-teal-600 rounded-xl"></div>
          )}
          <div className="grow flex flex-col">
            <H3>{companyPlatform.name}</H3>
            <P muted>{allChannels ? "All channels" : channels}</P>
          </div>
          <Button asChild variant="tertiary">
            <div className={`text-right flex items-center justify-end`}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <Button variant="tertiary" size="sm">
                    <EllipsisHorizontalIcon className="size-6" />
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content side="right" sideOffset={24}>
                  <DropdownMenu.Item
                    onClick={editChannelsOnClick}
                    icon={<PencilIcon width={24} />}
                    weight="medium"
                  >
                    Edit channels
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator />
                  <DropdownMenu.Item
                    onClick={removeWorkspaceOnClick}
                    icon={<TrashIcon width={24} />}
                    weight="medium"
                  >
                    Remove {platformTypeInfo.companyPlatformTerm}
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator />
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          </Button>
        </div>
        {companyPlatform.hasAnnouncedOnboarding ? (
          <div className="flex flex-row gap-x-4 items-center">
            <CheckIcon className="size-6 text-spill-blue-800" />
            <P muted>Channel members are automatically invited</P>
          </div>
        ) : (
          <Alert
            variant="warning"
            title="Channel members haven't been invited"
            action={{
              label: "Invite",
              onClick: invitePlatform,
              bottom: true,
            }}
          >
            <P className="mb-5">
              Once you’ve checked your user access list, click invite to send an
              automatic DM to all channel members. You only need to do this once
              – in future, anyone who joins these channels will also receive a
              DM.
            </P>
          </Alert>
        )}
      </div>
    </>
  )
}

interface RemoveWorkspaceDialogProps {
  isRemoveWorkspaceOpen: boolean
  setRemoveWorkspaceOpen: (value: boolean) => void
  companyPlatform: CompanyPlatform
  platformTypeInfo: PlatformTypeInfo
  refetch: () => void
}

const RemoveWorkspaceDialog: FunctionComponent<RemoveWorkspaceDialogProps> = ({
  isRemoveWorkspaceOpen,
  setRemoveWorkspaceOpen,
  companyPlatform,
  platformTypeInfo,
  refetch,
}) => {
  const [removing, setRemoving] = useState<boolean>(false)

  const onClose = () => {
    setRemoveWorkspaceOpen(false)
  }

  const [removeWorkspaceMutation] = useMutation<
    AccessRemoveWorkspaceMutation,
    AccessRemoveWorkspaceMutationVariables
  >(mutations.removeWorkspace)

  const removeWorkspace = async () => {
    setRemoving(true)
    const { data: data } = await removeWorkspaceMutation({
      variables: { companyPlatformId: companyPlatform.id },
    })

    if (data?.uninstallAppFromPlatform === true) {
      toast.success(
        `${platformTypeInfo.name} ${platformTypeInfo.companyPlatformTerm} was removed`
      )
      refetch()
    } else {
      toast.error(
        `We weren't able to remove your ${platformTypeInfo.companyPlatformTerm}. Please try again.`
      )
    }
    setRemoving(false)
    setRemoveWorkspaceOpen(false)
  }

  return (
    <Dialog
      isOpen={isRemoveWorkspaceOpen}
      onClose={onClose}
      buttons={[
        {
          children: "Not now",
          onClick: onClose,
          variant: "secondary",
        },
        {
          children: "Yes, remove",
          onClick: removeWorkspace,
          variant: "destructive",
          loading: removing,
        },
      ]}
    >
      <div className="flex flex-col gap-y-2">
        <H4>
          Are you sure you want to remove the {companyPlatform.name}{" "}
          {platformTypeInfo.companyPlatformTerm}?
        </H4>
        <P muted>
          This will remove {platformTypeInfo.name} access to Spill for everyone
          who is a member of this {platformTypeInfo.companyPlatformTerm}. If you
          change your mind, you’ll have to set it up as a new{" "}
          {platformTypeInfo.companyPlatformTerm}.
        </P>
      </div>
    </Dialog>
  )
}
