import { useQuery } from "@apollo/client"
import { FunctionComponent } from "react"
import { H1, H4 } from "@spillchat/puddles"

import {
  AdminTherapyActivityQuery,
  AdminTherapyActivityQueryVariables,
} from "types/graphql"
import { TherapyOutcomes } from "features/admin/components/TherapyOutcomes"
import { ExtensionRequestList } from "features/admin/pages/Settings/components/ExtensionRequestList"

import { queries } from "./admin-activity.queries"

export const AdminActivity: FunctionComponent = () => {
  const { data } = useQuery<
    AdminTherapyActivityQuery,
    AdminTherapyActivityQueryVariables
  >(queries.getActivityData)

  return (
    <>
      <H1>Activity</H1>
      <div className="flex flex-col sm:flex-row gap-9 mt-8">
        <div className="overflow-y-auto w-full sm:w-2/3">
          <TherapyOutcomes />
        </div>
        <div className="w-full sm:w-1/3 flex flex-col gap-4">
          {data?.user?.company?.featuresAndSettings?.userCanRequestTherapy
            ?.value === true && (
            <>
              <H4 sectionHeader>Extension requests</H4>
              <ExtensionRequestList />
            </>
          )}
        </div>
      </div>
    </>
  )
}
