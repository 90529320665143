import { gql } from "@apollo/client"

export const mutations = {
  sendInvites: gql`
    mutation AccessSendInvites($companyPlatformId: ID!) {
      broadcastOnboardingMessage(companyPlatformId: $companyPlatformId)
    }
  `,
  removeWorkspace: gql(`
    mutation AccessRemoveWorkspace($companyPlatformId: ID!) {
      uninstallAppFromPlatform(companyPlatformId: $companyPlatformId)
    }
    `),
}
