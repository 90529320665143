import { Button, H2, P } from "@spillchat/puddles"
import { FunctionComponent, useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import { ErrorPage } from "common/components/ErrorPage"

import { platformTypesInfo } from "."

interface Props {
  setParentPlatformType: (platformType: string | undefined) => void
}

export const PlatformConfirmation: FunctionComponent<Props> = ({
  setParentPlatformType,
}) => {
  const { platformType } = useParams()
  useEffect(() => {
    setParentPlatformType(platformType)
  }, [platformType])
  const platformTypeInfo = platformTypesInfo[platformType ?? ""]

  if (!platformTypeInfo) {
    return <ErrorPage />
  }

  return (
    <div className="flex flex-col gap-3">
      <H2>Spill has been added to your {platformTypeInfo.name} channels</H2>
      <P muted>
        Everyone in those channels will be able to see Spill is enabled, and use{" "}
        {platformTypeInfo.name} to log in.
      </P>
      <P muted>
        You can edit your {platformTypeInfo.name} integration settings from the
        Access page
      </P>
      <Button asChild>
        <Link to={"/admin/settings/access"}>Finish</Link>
      </Button>
    </div>
  )
}
