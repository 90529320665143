/**
 * This component shows us the initial view for setting up Spill on a platform.
 */
import { FunctionComponent, useEffect } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { H2, H3, P, Alert, CopyField, Button } from "@spillchat/puddles"
import { LinkIcon } from "@heroicons/react/24/outline"
import { toast } from "sonner"

import { config } from "config"
import { useAuth } from "common/context/authContext"
import { NotFound404Page } from "common/components/NotFound404Page"
import { useAnalytics } from "common/context/analyticsContext"
import { WistiaPlayer } from "common/components/WistiaPlayer"

import { platformTypesInfo } from "."

interface Props {
  setParentPlatformType: (platformType: string | undefined) => void
}

/**
 * Instructions for adding Spill to a particular platform.
 *
 * The platform is taken from the `:platformType` path parameter.
 *
 * This page may also be shown to unauthenticated users (for example, the buyer may not be a Teams
 * admin so will send the link to them). So this page must work for both authenticated and
 * unauthenticated users. The company id is also automatically added to the `state` query param.
 */
export const InitialPlatformSetup: FunctionComponent<Props> = ({
  setParentPlatformType,
}) => {
  const { user, isUserLoading } = useAuth()
  const { track } = useAnalytics()
  const { platformType } = useParams()
  useEffect(() => {
    setParentPlatformType(platformType)
  }, [platformType])

  const platformTypeInfo = platformTypesInfo[platformType ?? ""]

  const [searchParams, setSearchParams] = useSearchParams()
  const stateParam = searchParams.get("state")
  const errorParam = searchParams.get("error")

  // Set the state query param so the link can be shared with unauthenticated users.
  useEffect(() => {
    const id = user?.company?.id
    if (user && id != null) {
      setSearchParams(prev => ({ ...Object.fromEntries(prev), state: id }), {
        replace: true,
      })
    }
  }, [user])

  useEffect(() => {
    if (errorParam === null) return

    track("User had error during setup", {
      Platform: platformTypeInfo?.name ?? "Unknown",
      Error: errorParam,
    })
  }, [errorParam])

  if (!platformTypeInfo || (stateParam === null && !user && !isUserLoading)) {
    return <NotFound404Page />
  }

  const trackWithProperties = (eventName: string) => {
    track(eventName, { Platform: platformTypeInfo.name, IsBuyer: !!user })
  }

  const gatewayUrl =
    stateParam !== null
      ? new URL(platformTypeInfo.gatewayPath, config.spill.gateway.baseUrl)
      : null
  gatewayUrl?.searchParams.set("state", stateParam ?? "")

  return (
    <>
      <div className="flex justify-center flex-col gap-4">
        {/* Error alert */}
        {errorParam !== null && (
          <Alert variant="danger" title="Couldn't install Spill">
            <P size="xs">
              There was an issue adding Spill: {errorParam}. Please make sure
              you're a {platformTypeInfo.name} admin and signed in to the right
              account, then try again. If the issue persists, get in touch at
              hi@spill.chat
            </P>
          </Alert>
        )}

        {/* Page introduction */}
        <div className="flex flex-col gap-y-3">
          <H2>Add Spill to {platformTypeInfo.name}</H2>
          <P muted>
            Sign in with {platformTypeInfo.name} to get started – setup takes
            less than 3 minutes. We’ll ask for a few permissions in order to
            securely connect Spill to your {platformTypeInfo.name}{" "}
            {platformTypeInfo.companyPlatformTerm}.
          </P>
        </div>

        {/* Tutorial video */}
        {platformTypeInfo.tutorialWistiaId !== "" && (
          <WistiaPlayer
            elementId="wistia-player"
            videoId={platformTypeInfo.tutorialWistiaId}
          />
        )}

        {/* Sign into integration button */}
        <Button asChild variant="primary">
          {gatewayUrl ? (
            <Link to={gatewayUrl?.toString() ?? ""}>
              {`Add Spill to ${platformTypeInfo.name}`}
            </Link>
          ) : (
            <P>Loading...</P>
          )}
        </Button>
      </div>
      <hr></hr>
      <div className="flex flex-col w-full gap-5">
        <div className="flex flex-col gap-3">
          <H3>Not your company’s {platformTypeInfo.name} admin?</H3>
          <P muted>
            Send your company's {platformTypeInfo.name} admin a link to this
            page to get set up.
          </P>
        </div>
        <div className="mb-8">
          <P weight="bold" size="xs" className="mb-2">
            Link
          </P>
          <CopyField
            icon={<LinkIcon />}
            copyValue={window.location.href}
            onCopied={() => {
              toast.success(
                `You can now paste it into a message to your ${
                  platformTypeInfo?.name ?? ""
                } admin.`
              )
              trackWithProperties("User copied setup instructions link")
            }}
            onFail={() =>
              toast.error(
                "There was an issue copying the link. You can copy it manually from the URL bar."
              )
            }
          />
        </div>
      </div>
    </>
  )
}
