import { H3, P } from "@spillchat/puddles"
import React from "react"
import {
  LifebuoyIcon,
  LightBulbIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"

import { FAQParenthood } from "common/components/FAQ/FAQParenthood"
import {
  AppointmentTypeFeature,
  PackageState,
  PackageType,
} from "types/graphql"
import { FAQBereavement } from "common/components/FAQ/FAQBereavement"
import { HeroIcon } from "common/helpers/heroIcons"

/**
 * The defaults for creating a package
 */
export type PackageCreationDefaults = {
  numberSessions: number
  forAllEmployees: boolean
  numberMonthsToUse: number | null
  adminReason: string
  packageType: PackageType
  lifeEventId?: string
  iconUrl: string
  userFacingDescription: string
  visibleToAllEmployees: boolean
  icon: string
  name?: string
  state: PackageState
  coPayEnabled: boolean
  companyContribution: number
}

export type PackageConfig = {
  /**
   * Only used in the creation of a package by the Admin
   * Such as 'Create Custom Support' or 'Create Parenthood Support'
   */
  title: string

  /**
   * Tile Description. Only used in the tile view of the package
   * If left undefined, use the userFacingDescription that is configured in the creation
   */
  tileDescription?: string

  /**
   * NOT FULLY IMPLEMENTED. TBC for ADHD
   * Used to determine if the Admin can configure copay for the package
   */
  coPayAvailable: boolean

  /**
   * Text to be displayed under the sessions selector.
   * Used in the Admin UI for creating and editing a package
   * E.g. "We recommend 6 sessions or Charged at the normal rate"
   */
  sessionsPerPersonText: string

  /**
   * TODO TEST THIS MORE - VALIDATION IS NOT WORKING PROPERLY
   * Minimum number of sessions required for the package.
   * Used in the validation for in Admin UI for creating and editing a package
   */
  minSessions: number

  /**
   * FAQs displayed to the user when they visit the package. - Optional
   * As of this implementation, this is expected to be an accordian component
   */
  commonQuestions: React.ReactNode | undefined

  /**
   * Introduction content displayed to the admin when creating a package.
   * This is not displayed anywhere else.
   */
  introduction:
    | {
        content: React.ReactNode
      }
    | undefined

  /**
   * Whether the package can be edited by the admin.
   * This is the main difference between a custom package and the other packages
   * If False, cannot change the name, icon, colours, description
   * Admin can still adjust the number of sessions and the number of months to use,
   * as well as how they invite employees to use the package
   */
  customisable: boolean

  /**
   * Whether the admin can edit "sessions per person" or "support duration"
   */
  customSettings: boolean

  /**
   * Whether the package requires approval from Spill
   * If True, the package will be in a state of AWAITING_COUNSELLORS
   * and will not be visible to employees until approved
   */
  requireApproval: boolean

  /**
   * Used on the package page displayed to the user.
   * Sets the first line of the list of features.
   */
  uspLineItem: {
    icon: HeroIcon
    text: string
  }

  /**
   * The Feature to be used when booking the appointment
   */
  appointmentTypeFeature: AppointmentTypeFeature

  /**
   * Package is part of a series of consultations.
   * If this is on, the package will let you book the first session.
   * But after that the user will be directed back to their session homepage
   */
  isConsultation: boolean
}

export const getPackageConfig = (packageType: PackageType): PackageConfig => {
  switch (packageType) {
    case PackageType.PARENTHOOD:
      return {
        title: "Parenthood Support",
        appointmentTypeFeature: AppointmentTypeFeature.PARENTHOOD_CONSULTATION,
        isConsultation: true,
        coPayAvailable: false,
        customisable: false,
        customSettings: true,
        requireApproval: false,
        sessionsPerPersonText: "We recommend 8 sessions",
        commonQuestions: <FAQParenthood />,
        uspLineItem: {
          icon: UserGroupIcon,
          text: "Sessions with a parenthood specialist",
        },
        minSessions: 4,
        tileDescription:
          "Support for new parents going through the joys of becoming a parent and the challenges around returning to work.",
        introduction: {
          content: (
            <>
              <div className="flex flex-col gap-8">
                <div className="space-y-3">
                  <H3>You won't be charged extra</H3>
                  <P muted>There is no fee for enabling this support. Nice!</P>
                </div>
                <div className="space-y-3">
                  <H3>Parenthood sessions are separate to regular sessions</H3>
                  <P muted>
                    Any sessions you add to this package will be separate to
                    your employees' regular session allowance. Employees will
                    still be able to book regular therapy sessions even if they
                    are also accessing a specialised support package.{" "}
                  </P>
                </div>
                <div className="space-y-3">
                  <H3>How it's paid for</H3>
                  <P muted>
                    On your plan, you'll pay for each specialist session any
                    employees use. These will come out of your overall company
                    budget, just like regular session credits.{" "}
                  </P>
                </div>
              </div>
            </>
          ),
        },
      }
    case PackageType.BEREAVEMENT:
      return {
        title: "Bereavement Support",
        appointmentTypeFeature: AppointmentTypeFeature.BEREAVEMENT_CONSULTATION,
        isConsultation: true,
        coPayAvailable: false,
        customisable: false,
        customSettings: true,
        requireApproval: false,
        sessionsPerPersonText: "We recommend 6 sessions",
        commonQuestions: <FAQBereavement />,
        uspLineItem: {
          icon: LifebuoyIcon,
          text: "Sessions with a bereavement specialist",
        },
        minSessions: 4,
        tileDescription:
          "For grieving employees who might be on leave, or struggling on the anniversary of losing a loved one.",
        introduction: {
          content: (
            <>
              <div className="flex flex-col gap-8">
                <div className="space-y-3">
                  <H3>You won't be charged extra</H3>
                  <P muted>There is no fee for enabling this support. Nice!</P>
                </div>
                <div className="space-y-3">
                  <H3>Bereavement sessions are separate to regular sessions</H3>
                  <P muted>
                    Any sessions you add to this package will be separate to
                    your employees' regular session allowance. Employees will
                    still be able to book regular therapy sessions even if they
                    are also accessing a specialised support package.
                  </P>
                </div>
                <div className="space-y-3">
                  <H3>How it's paid for</H3>
                  <P muted>
                    On your plan, you'll pay for each specialised session any
                    employees use. These will come out of your overall company
                    budget, just like regular session credits.
                  </P>
                </div>
              </div>
            </>
          ),
        },
      }
    case PackageType.ADHD:
      return {
        title: "ADHD Support",
        appointmentTypeFeature: AppointmentTypeFeature.ONE_OFF,
        isConsultation: false,
        coPayAvailable: true,
        customisable: false,
        customSettings: false,
        requireApproval: false,
        sessionsPerPersonText: "We recommend 6 sessions",
        commonQuestions: <></>,
        uspLineItem: {
          icon: LightBulbIcon,
          text: "Sessions with an ADHD specialist",
        },
        minSessions: 0,
        tileDescription:
          "For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not.",
        introduction: {
          content: (
            <>
              <div className="flex flex-col gap-8">
                <div className="space-y-3">
                  <H3>You won't be charged extra</H3>
                  <P muted>There is no fee for enabling this support. Nice!</P>
                </div>
                <div className="space-y-3">
                  <H3>Regular sessions can be used as ADHD sessions</H3>
                  <P muted>
                    Employees will be able to use their regular therapy sessions
                    to book ADHD sessions. If they run out of sessions they will
                    be able to request more sessions or pay for them privately.
                  </P>
                </div>
              </div>
            </>
          ),
        },
      }
    default:
      return {
        title: "Custom Support",
        uspLineItem: {
          icon: UserPlusIcon,
          text: "Specialists tailored to your needs",
        },
        isConsultation: false,
        appointmentTypeFeature: AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION,
        coPayAvailable: false,
        customisable: true,
        customSettings: true,
        sessionsPerPersonText: "Charged at the normal rate",
        requireApproval: true,
        introduction: undefined,
        commonQuestions: undefined,
        minSessions: 2,
      }
  }
}

export const getPackageDefaults = (
  packageType: PackageType
): PackageCreationDefaults => {
  switch (packageType) {
    case PackageType.PARENTHOOD:
      return {
        packageType,
        name: "Parenthood Support",
        icon: "UserGroup",
        iconUrl: "therapy-parenthood.png",
        state: PackageState.ACTIVE,
        numberSessions: 8,
        numberMonthsToUse: 24,
        lifeEventId: "a3a50f0b-2084-434e-afcb-bf74dc2e56dc",
        visibleToAllEmployees: false,
        forAllEmployees: false,
        adminReason: "",
        userFacingDescription: `Support for new parents going through the joys of becoming a parent and the challenges around returning to work.

This support package will give employees extra sessions, on top of their regular Spill allowance, which they can use to speak to a Parenthood specialist.`,
        coPayEnabled: false,
        companyContribution: 0,
      }
    case PackageType.BEREAVEMENT:
      return {
        packageType,
        name: "Bereavement Support",
        icon: "Lifebuoy",
        iconUrl: "therapy-bereavement.png",
        state: PackageState.ACTIVE,
        numberSessions: 6,
        numberMonthsToUse: 24,
        lifeEventId: "db5de9c5-044f-4028-aa44-901e53df4bf0",
        visibleToAllEmployees: false,
        forAllEmployees: false,
        adminReason: "",
        userFacingDescription: `For grieving employees who might be on leave, or struggling on the anniversary of a loved one.

This support package gives an employee access to a bereavement specialist over the course of 2 years, giving them the support and time to process their loss at their own pace.
`,
        coPayEnabled: false,
        companyContribution: 0,
      }
    case PackageType.ADHD:
      return {
        packageType,
        name: "ADHD Support",
        icon: "LightBulb",
        iconUrl: "therapy-adhd.png",
        state: PackageState.ACTIVE,
        numberSessions: 0,
        numberMonthsToUse: 6,
        lifeEventId: "10c92c58-0d9f-4173-9f88-de1dec623e70",
        visibleToAllEmployees: false,
        forAllEmployees: false,
        adminReason: "",
        userFacingDescription:
          "For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not.",
        coPayEnabled: true,
        companyContribution: 0,
      }
    default:
      return {
        packageType,
        numberSessions: 4,
        forAllEmployees: false,
        numberMonthsToUse: 1,
        adminReason: "",
        iconUrl: "generic-red.svg",
        userFacingDescription: "",
        visibleToAllEmployees: false,
        icon: "",
        name: "",
        state: PackageState.AWAITING_COUNSELLORS,
        lifeEventId: undefined,
        coPayEnabled: false,
        companyContribution: 0,
      }
  }
}
