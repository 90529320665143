import { FunctionComponent } from "react"
import { useQuery } from "@apollo/client"
import { Button, H1, P, Tooltip, HeaderCard, H3, H2 } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { LightBulbIcon } from "@heroicons/react/24/outline"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import ImageLifeEvents from "common/assets/images/product/specialised-support/therapy-adhd.png"
import ImageCoPay from "common/assets/images/product/specialised-support/therapy-copay.png"
import {
  AppointmentTypeFeature,
  TherapyAdhdDataQuery,
  TherapyAdhdDataQueryVariables,
  SpillSubscriptionStatus,
  TherapyAccessReason,
  AddOnType,
} from "types/graphql"
import { bookingAvailabilityMessage } from "features/therapy/pages/SessionsPage/bookingAvailabilityMessages"
import { UsageAsPercentage } from "features/therapy/pages/SessionsPage/components/UsageAsPercentage"

import { queries } from "./therapy-adhd.queries"

export const TherapyAdhdPage: FunctionComponent = () => {
  const navigate = useNavigate()

  const { data, called, loading } = useQuery<
    TherapyAdhdDataQuery,
    TherapyAdhdDataQueryVariables
  >(queries.getData)

  const { adhdCompanyPackageSetting } = data?.user?.company ?? {}
  const coPaySettings = adhdCompanyPackageSetting?.addOns.find(
    addon => addon.addOnType === AddOnType.CO_PAY
  )

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const user = data?.user
  const company = data?.user?.company

  if (!user || !company) {
    return <Navigate to="/" />
  }

  const userCanRequestTherapy =
    user?.featuresAndSettings?.userCanRequestTherapy.value ?? false

  const noAccessToCompanySessions =
    user.revokedAt != null ||
    (user.therapyUsageCap === 0 && !userCanRequestTherapy)

  const hasNoSubscription =
    company?.subscriptionStatus === null && !company?.inActiveTrialPeriod

  const hasInactiveSubscription =
    company?.subscriptionStatus === SpillSubscriptionStatus.INACTIVE &&
    !company?.inActiveTrialPeriod

  const canBookOneOffs =
    user.accessToTherapyFeatures.oneOffs.at(0)?.hasAccess ?? false

  const oneOffBookingSessionMessage = bookingAvailabilityMessage(
    AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION,
    user.accessToTherapyFeatures.oneOffs.at(0)?.reason ?? TherapyAccessReason.OK
  )

  const noSubscriptionMessage = {
    title: "No active subscription",
    message: "Your company hasn't yet added their payment details.",
  }

  // Additional resources - If anything else is added to the section, update the conditional so it appears

  return (
    <>
      <Helmet title="ADHD support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-5 max-w-screen-sm">
            <H1>ADHD support</H1>
            <H3>How it works</H3>
            <div className="max-w-md flex flex-col gap-3">
              <P muted>
                This support package lets you book in with an ADHD specialist to
                get support for ADHD in or out of work.
              </P>
            </div>
          </div>
          <div className="hidden max-w-xs lg:flex">
            <img
              src={ImageLifeEvents}
              alt="Someone has a Spill session using their laptop"
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-3">
            <H2>Support</H2>
            <P muted>
              You can use your Spill credits to talk to an ADHD specialist.
            </P>
          </div>
          <UsageAsPercentage
            therapyUsageCap={user.therapyUsageCap ?? 0}
            companyTherapyCap={user.companyTherapyCap ?? 0}
            numberSessionsUsedInCapPeriod={user.numberSessionsUsedInCapPeriod}
            companyNumberSessionsUsedInBudgetYear={
              company?.budgetYearUsage?.totalBillableUsage ?? 0
            }
            companyYearlyTherapyBudget={
              user?.featuresAndSettings?.yearlyTherapyBudget?.value
            }
            companyName={company.name}
            userCanRequestTherapy={userCanRequestTherapy}
            userTherapyCapActive={
              user.featuresAndSettings.userTherapyCap.active ?? false
            }
            hasInactiveSubscription={hasInactiveSubscription}
            hasLifeEventPackage={false}
            companySessionPackLimit={
              user?.featuresAndSettings?.sessionPack.value
            }
            companySessionPackUsage={company?.currentSessionPackUsage}
            companyAllowsCourses={
              user.featuresAndSettings.courses.value ?? false
            }
            inActiveTrialPeriod={company?.inActiveTrialPeriod}
            capPeriod={company?.capPeriod}
          />
          <div>
            <Tooltip.Provider>
              {!noAccessToCompanySessions &&
                user.featuresAndSettings?.oneOffs.value === true &&
                !hasInactiveSubscription && (
                  <Tooltip.Root>
                    <div className="max-w-md">
                      <HeaderCard
                        title="ADHD support session"
                        icon={<LightBulbIcon width={24} />}
                        description="Book a session with your specialist"
                      >
                        <div className="flex gap-4">
                          <Tooltip.Trigger>
                            <Button
                              variant="secondary"
                              size="sm"
                              disabled={!canBookOneOffs || hasNoSubscription}
                              onClick={() => {
                                const appointmentTypeFeature =
                                  AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION
                                navigate(
                                  `/therapy/sessions/book?appointmentType=${appointmentTypeFeature}&lifeEvent=adhd`
                                )
                              }}
                            >
                              Book a session
                            </Button>
                          </Tooltip.Trigger>
                        </div>
                      </HeaderCard>
                    </div>
                    {hasNoSubscription && (
                      <Tooltip.Content
                        sideOffset={20}
                        title={noSubscriptionMessage.title}
                      >
                        <>{noSubscriptionMessage.message}</>
                      </Tooltip.Content>
                    )}
                    {!hasNoSubscription && !canBookOneOffs && (
                      <Tooltip.Content
                        sideOffset={20}
                        title={oneOffBookingSessionMessage.title}
                      >
                        <>{oneOffBookingSessionMessage.message}</>
                      </Tooltip.Content>
                    )}
                  </Tooltip.Root>
                )}
            </Tooltip.Provider>
          </div>
        </div>
        {coPaySettings?.properties.enabled === true && (
          <>
            <hr />
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-3">
                <H2>Extra support</H2>
                <P muted>
                  You're able to request additional features of your ADHD
                  Support.
                </P>
              </div>
              <div className="flex flex-col gap-5 max-w-xs">
                <img
                  src={ImageCoPay}
                  alt="Someone has a Spill session using their phone"
                  className="aspect-video object-cover rounded-lg"
                />
                <div className="flex flex-col gap-2">
                  <H3>Assessment Co-Pay</H3>
                  <P muted>
                    Get an ADHD assessment partly paid for by your company
                  </P>
                </div>
                <Button variant="tertiary" asChild>
                  <Link to="/therapy/specialised-support/adhd/co-pay">
                    Learn more
                  </Link>
                </Button>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  )
}
