import { gql, useMutation, useQuery } from "@apollo/client"
import { Alert, Button, H1, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"

import { useAnalytics } from "common/context/analyticsContext"
import {
  AdminDeclineTherapyExtensionDeclineExtensionMutationVariables,
  AdminDeclineTherapyExtensionGetTherapyExtensionQuery,
  AdminDeclineTherapyExtensionGetTherapyExtensionQueryVariables,
  AdminDeclineTherapyExtensionGetUserQuery,
  TherapyExtensionStatus,
} from "types/graphql"

import { queries as extensionListQueries } from "./components/ExtensionRequestList"

const queries = {
  getTherapyExtension: gql`
    query AdminDeclineTherapyExtensionGetTherapyExtension($id: ID!) {
      therapyExtension: getTherapyExtension(id: $id) {
        id
        expiryDate
        isAnonymous
        noteFromUser
        numberOfSessions
        status
        user {
          id
          displayName
        }
      }
    }
  `,
  getUser: gql`
    query AdminDeclineTherapyExtensionGetUser {
      user {
        id
        displayName
      }
    }
  `,
}

export const mutations = {
  declineExtension: gql`
    mutation AdminDeclineTherapyExtensionDeclineExtension(
      $id: ID!
      $noteFromAdmin: String
    ) {
      declineTherapyExtension(id: $id, noteFromAdmin: $noteFromAdmin) {
        id
        status
      }
    }
  `,
}

export const AdminDeclineTherapyExtension: FunctionComponent = () => {
  const { id } = useParams()
  const [note, setNote] = useState("")
  const { track } = useAnalytics()
  const navigate = useNavigate()

  const { data: getExtensionData, loading: loadingExtension } = useQuery<
    AdminDeclineTherapyExtensionGetTherapyExtensionQuery,
    AdminDeclineTherapyExtensionGetTherapyExtensionQueryVariables
  >(queries.getTherapyExtension, {
    variables: { id: id as string },
    fetchPolicy: "cache-first",
  })

  const { data: getUserData, loading: loadingUser } =
    useQuery<AdminDeclineTherapyExtensionGetUserQuery>(queries.getUser, {
      fetchPolicy: "cache-first",
    })

  const [declineExtensionMutation, { loading: isDeclining }] =
    useMutation<AdminDeclineTherapyExtensionDeclineExtensionMutationVariables>(
      mutations.declineExtension,
      {
        refetchQueries: [extensionListQueries.getData],
      }
    )

  const declineExtension = async () => {
    await declineExtensionMutation({
      variables: {
        id: id,
        noteFromAdmin: note.trim(),
      },
    })

    track("Admin declined therapy extension", {
      id: id,
      hasNote: note.trim() !== "",
    })
    toast.success("Extension declined")
    navigate("/admin/activity")
  }

  if (loadingExtension || loadingUser) {
    // this is super quick to load so no spinner feel free to update if needed
    return null
  }

  const therapyExtension = getExtensionData?.therapyExtension
  const user = getUserData?.user

  if (therapyExtension == null) {
    return (
      <>
        <Helmet title="Therapy | Spill" />
        <div className="flex flex-col gap-6">
          <button className="underline w-fit" onClick={() => navigate(-1)}>
            Back
          </button>
          <Alert variant="danger" title="Extension request not found." />
        </div>
      </>
    )
  }

  if (therapyExtension.status !== TherapyExtensionStatus.PENDING) {
    return (
      <>
        <Helmet title="Therapy | Spill" />
        <div className="flex flex-col gap-6">
          <button className="underline w-fit" onClick={() => navigate(-1)}>
            Back
          </button>
          <Alert variant="danger" title="Extension request is not pending." />
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet title="Therapy | Spill" />
      <div className="flex flex-col gap-6">
        <button className="underline w-fit" onClick={() => navigate(-1)}>
          Back
        </button>
        <H1>Decline extension request</H1>
        <P muted>
          Write a short message to accompany the update for the person who
          requested therapy.
        </P>
        <P>Your message (optional)</P>
        <textarea
          className="border border-spill-grey-200 rounded-lg p-4 w-full h-32 bg-spill-grey-100"
          onChange={e => setNote(e.target.value)}
          value={note}
        />
        {user != null && <P>From {user.displayName}</P>}
        <Button onClick={declineExtension} loading={isDeclining}>
          Decline Extension
        </Button>
      </div>
    </>
  )
}
