import { useMutation, QueryResult } from "@apollo/client"
import {
  ModalFullScreen,
  Tabs,
  Form,
  H2,
  H3,
  P,
  TextArea,
  Button,
  SelectableCard,
  Breadcrumbs,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { toast } from "sonner"

import imageSpilly from "common/assets/logo/no-background-logo.png"
import { Avatar } from "common/components/Avatar"
import {
  AskATherapistPageGetCounsellorsQuery,
  AskATherapistPageCreateAskATherapistQuestionMutation as MutationData,
  AskATherapistPageCreateAskATherapistQuestionMutationVariables as MutationVars,
} from "types/graphql"
import { useAnalytics } from "common/context/analyticsContext"
import { AnonymityInSpill } from "common/components/AnonymityInSpill"

import { mutations } from "../ask-a-therapist.mutations"
import { type AskATherapistForm, formSchema } from "../ask-a-therapist-schema"

import { CrisisAlert } from "./CrisisAlert"

type Counsellor = {
  name: string
  subtitle: string
  avatarUrl?: string
  id?: string
}

interface AskATherapistQuestionProps {
  onClose: () => void
  subscriptionActive: boolean
  counsellors: AskATherapistPageGetCounsellorsQuery["allQuestionAnsweringCounsellors"]
  refetch: QueryResult["refetch"]
  isOpen: boolean
}

export const AskATherapistQuestion: FunctionComponent<
  AskATherapistQuestionProps
> = ({ onClose, subscriptionActive, counsellors, refetch, isOpen }) => {
  const { track } = useAnalytics()
  const [step, setStep] = useState(0)

  const form = Form.useForm<AskATherapistForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      question: "",
      counsellorId: undefined,
    },
  })

  const [submitAskATherapistQuestion, { loading: isSubmitting }] = useMutation<
    MutationData,
    MutationVars
  >(mutations.createAskATherapistQuestion, {
    onError: () => {
      toast.error("Something went wrong, please try again or get in touch.")
    },
    onCompleted: () => {
      track("User submitted Ask a Therapist Question")
    },
  })

  const handleQuestion = async () => {
    const valid = await form.trigger(["question"], {
      shouldFocus: true,
    })

    if (valid) {
      setStep(1)
    }
  }

  const counsellorOptions: Counsellor[] = [
    {
      name: "First Available",
      subtitle: "Anyone can answer",
    },
  ].concat(
    counsellors.map(counsellor => ({
      name: `${counsellor.firstName} ${counsellor.lastName.slice(0, 1)}`,
      subtitle: counsellor.professionalBody ?? "Spill Therapist",
      avatarUrl: counsellor.avatarUrl,
      id: counsellor.id,
    }))
  )

  const handleSubmit = async (values: AskATherapistForm) => {
    if (!subscriptionActive) {
      toast.error("Your company does not have access to this feature.")
    }

    await submitAskATherapistQuestion({
      variables: {
        question: values.question,
        counsellorId: values.counsellorId,
      },
    })

    void refetch()
    return setStep(2)
  }

  const tabs = [
    { label: "Question", isActive: step >= 0, step: 0 },
    { label: "Therapist", isActive: step >= 1, step: 1 },
    { label: "Confirmation", isActive: step >= 2, step: 2 },
  ]

  return (
    <>
      <ModalFullScreen title="Ask a question" onClose={onClose} isOpen={isOpen}>
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
          <Tabs.Root
            defaultValue={step.toString()}
            value={step.toString()}
            className="flex flex-col gap-6 lg:gap-12"
          >
            <Form.Root {...form}>
              <Breadcrumbs
                onStepChange={setStep}
                currentStep={step}
                tabs={tabs}
              ></Breadcrumbs>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Tabs.Content value="0">
                  <div className="flex flex-col items-start gap-8">
                    <div className="flex flex-col items-start gap-3 self-stretch">
                      <H2>What is your question?</H2>
                      <P muted> Tell us what's on your mind.</P>
                    </div>
                    <Form.Field
                      control={form.control}
                      name="question"
                      render={({ field }) => (
                        <Form.Item className="w-full">
                          <Form.Control>
                            <TextArea
                              placeholder="How do I overcome imposter syndrome?"
                              className="h-36"
                              {...field}
                              label={{
                                children: "Message",
                              }}
                            />
                          </Form.Control>
                          <Form.Message />
                        </Form.Item>
                      )}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={async () => await handleQuestion()}
                    >
                      Next
                    </Button>
                    <AnonymityInSpill
                      description="We take privacy seriously. See what your employer sees when you submit a
        question."
                    />
                  </div>
                </Tabs.Content>
                <Tabs.Content value="1">
                  <div className="flex flex-col items-start gap-8">
                    <div className="flex flex-col items-start gap-3 self-stretch">
                      <H2>Who do you want to answer?</H2>
                      <P muted>
                        You can opt to have your question answered by someone
                        specific.
                      </P>
                    </div>
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <H3>Choose a therapist</H3>
                      <Form.Field
                        control={form.control}
                        name="counsellorId"
                        render={({ field }) => (
                          <Form.Item>
                            <Form.Control>
                              <div className="grid md:grid-cols-2 gap-6">
                                {counsellorOptions.map(counsellor => {
                                  return (
                                    <div
                                      key={counsellor.id}
                                      className="w-72 h-36"
                                    >
                                      <SelectableCard
                                        size="lg"
                                        title={counsellor.name}
                                        subtitle={counsellor.subtitle}
                                        type="radio"
                                        checked={field.value === counsellor.id}
                                        label={
                                          counsellor.id != undefined ? (
                                            <Avatar
                                              url={counsellor.avatarUrl}
                                              name={counsellor.name}
                                            />
                                          ) : (
                                            <div className="h-10 w-10 flex items-center justify-center bg-spill-gray-100 text-spill-gray-500 rounded-full">
                                              <img
                                                src={imageSpilly}
                                                alt="Spilly"
                                                className="h-6 w-4"
                                              />
                                            </div>
                                          )
                                        }
                                        {...form.register("counsellorId")}
                                        value={counsellor.id}
                                        onClick={() => {
                                          form.setValue(
                                            "counsellorId",
                                            counsellor.id
                                          )
                                        }}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      ></Form.Field>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                    <AnonymityInSpill
                      description="We take privacy seriously. See what your employer sees when you submit a
        question."
                    />
                  </div>
                </Tabs.Content>
                <Tabs.Content value="2">
                  <div className="flex flex-col items-start gap-8">
                    <div className="flex flex-col items-start gap-3 self-stretch">
                      <H2>Thank you for submitting your question!</H2>
                      <P muted>
                        We'll try to get back to you within 1-2 working days.
                        Very rarely is there a longer wait - but if there is,
                        write to us at
                        <a
                          href="mailto:hi@spill.chat"
                          target="_blank"
                          rel="noreferrer"
                          className="underline decoration-solid font-semibold pl-1 text-spill-mono-black"
                        >
                          hi@spill.chat
                        </a>
                      </P>
                    </div>
                    <CrisisAlert />
                    <Button variant="primary" onClick={onClose}>
                      Close
                    </Button>
                  </div>
                </Tabs.Content>
              </form>
            </Form.Root>
          </Tabs.Root>
        </div>
      </ModalFullScreen>
    </>
  )
}
