import { EyeSlashIcon } from "@heroicons/react/24/outline"
import { Button, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

interface AnonymityInSpillProps {
  description: string
}

export const AnonymityInSpill: FunctionComponent<AnonymityInSpillProps> = ({
  description,
}) => {
  return (
    <div className="flex items-center gap-4 self-stretch">
      <div>
        <EyeSlashIcon
          width={"24"}
          height={"24"}
          className="text-spill-blue-800"
        />
      </div>
      <P muted>
        {description}
        <br />
        <Button variant="tertiary" asChild>
          <Link
            rel="noreferrer"
            target="_blank"
            to="https://spill.notion.site/Anonymity-on-Spill-15e49f92fc6b80dfba75dc942ced01bf"
          >
            Anonymity on Spill
          </Link>
        </Button>
      </P>
    </div>
  )
}
