import { AppointmentTypeFeature, TherapyAccessReason } from "types/graphql"

interface AdditionalMessageData {
  therapyUsageCap: number
  numberSessionsUsedInCapPeriod: number
}

interface Message {
  title: string
  message: string
}

type MessageConfig = {
  [key in TherapyAccessReason]:
    | Message
    | ((additionalData?: AdditionalMessageData) => Message)
}

interface MessagesMap {
  [AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION]: MessageConfig
  [AppointmentTypeFeature.SERIES_CONSULTATION_SESSION]: MessageConfig
  [AppointmentTypeFeature.PRIVATE_THERAPY_SESSION]: MessageConfig
  AskATherapist: MessageConfig
  // Add other appointment types here if needed
}

const OKMessage: Message = {
  title: "",
  message: "",
}

const messages: MessagesMap = {
  [AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION]: {
    [TherapyAccessReason.FEATURE_DISABLED_BY_COMPANY]: {
      title: "",
      message: `Your company has not made one-offs available`,
    },
    [TherapyAccessReason.NOT_ENOUGH_COMPANY_THERAPY_BUDGET_CREDITS]: {
      title: "One-off session not available",
      message:
        "Your company's yearly therapy budget has been reached. You won't be able to book a session until your company has increased its budget.",
    },
    [TherapyAccessReason.NOT_FOUND]: {
      title: "",
      message: "Not found",
    },
    [TherapyAccessReason.THERAPY_BUDGET_RESET_DATE_NULL]: {
      title: "Unable to determine if you can book a session",
      message:
        "We were unable to determine your company's yearly therapy budget. Please email support@spill.chat for help",
    },
    [TherapyAccessReason.USER_HAS_UPCOMING_APPOINTMENTS]: {
      title: "",
      message: `Since you have an upcoming session, you can't book a one-off session. Email therapy@spill.chat with questions.`,
    },
    [TherapyAccessReason.YEARLY_THERAPY_BUDGET_IS_NULL]: {
      title: "",
      message:
        "We were unable to determine your company's yearly therapy budget.",
    },
    [TherapyAccessReason.NOT_ENOUGH_USER_CAP_CREDITS]: {
      title: "One-off not available",
      message: "You do not have enough credits to book a one-off session",
    },
    [TherapyAccessReason.NOT_ENOUGH_SESSION_PACK_CREDITS]: {
      title: "One-off not available",
      message:
        "Your company's monthly session pack is used up. It will reset on the first of the month.",
    },
    [TherapyAccessReason.TRIAL_CREDITS_EXHAUSTED]: {
      title: "One-off not available",
      message: "Your company has used all of its trial credits.",
    },
    [TherapyAccessReason.REVOKED_USER]: {
      title: "",
      message: "Your account has been deactivated.",
    },
    [TherapyAccessReason.OK]: {
      title: "",
      message: "",
    },
    // This reason won't happen for one-offs but we have to have a state for it
    [TherapyAccessReason.NO_ACCESS_TO_PACKAGE_TYPE]: OKMessage,
  },

  [AppointmentTypeFeature.SERIES_CONSULTATION_SESSION]: {
    [TherapyAccessReason.FEATURE_DISABLED_BY_COMPANY]: {
      title: "",
      message: `Your company has not made courses available`,
    },
    [TherapyAccessReason.NOT_ENOUGH_COMPANY_THERAPY_BUDGET_CREDITS]: {
      title: "Course of therapy not available",
      message:
        "Booking a course of therapy would exceed your company's remaining yearly therapy budget.",
    },
    [TherapyAccessReason.NOT_FOUND]: {
      title: "",
      message: "Not found",
    },
    [TherapyAccessReason.THERAPY_BUDGET_RESET_DATE_NULL]: {
      title: "Unable to determine if you can book a session",
      message:
        "We were unable to determine your company's yearly therapy budget. Please email support@spill.chat for help",
    },
    [TherapyAccessReason.USER_HAS_UPCOMING_APPOINTMENTS]: {
      title: "",
      message: `Since you have an upcoming session, you can't start a new course. Email therapy@spill.chat with questions.`,
    },
    [TherapyAccessReason.YEARLY_THERAPY_BUDGET_IS_NULL]: {
      title: "",
      message:
        "We were unable to determine your company's yearly therapy budget.",
    },
    [TherapyAccessReason.NOT_ENOUGH_USER_CAP_CREDITS]: (
      additionalData?: AdditionalMessageData
    ) => {
      const remainingCredits =
        (additionalData?.therapyUsageCap ?? 0) -
        (additionalData?.numberSessionsUsedInCapPeriod ?? 0)
      return {
        title: "Course of therapy not available",
        message: `You must have at least 4 credits to book a new course. ${
          remainingCredits > 0
            ? `You can still book ${remainingCredits} more ${
                remainingCredits === 1 ? "session" : "sessions"
              } within the course with the same therapist.`
            : ""
        }`,
      }
    },
    [TherapyAccessReason.NOT_ENOUGH_SESSION_PACK_CREDITS]: {
      title: "Course of therapy not available",
      message:
        "Your company's monthly session pack is used up. It will reset on the first of the month.",
    },
    // we don't expect consultations to happen on a trial, but it's not impossible (for example, a manual override of the feature)
    [TherapyAccessReason.TRIAL_CREDITS_EXHAUSTED]: {
      title: "Course of therapy not available",
      message: "Your company has used all of its trial credits.",
    },
    [TherapyAccessReason.REVOKED_USER]: {
      title: "",
      message: "Your account has been deactivated.",
    },
    [TherapyAccessReason.OK]: OKMessage,
    // This reason won't happen for consultations but we have to have a state for it
    [TherapyAccessReason.NO_ACCESS_TO_PACKAGE_TYPE]: OKMessage,
  },
  AskATherapist: {
    [TherapyAccessReason.FEATURE_DISABLED_BY_COMPANY]: {
      title: "",
      message: `Your company has not made Ask a Therapist available`,
    },
    [TherapyAccessReason.NOT_ENOUGH_COMPANY_THERAPY_BUDGET_CREDITS]: {
      title: "Ask a Therapist not available",
      message:
        "Your company's yearly therapy budget has been reached. You won't be able to use Ask a Therapist until your company has increased its budget.",
    },
    [TherapyAccessReason.NOT_FOUND]: {
      title: "",
      message: "Not found",
    },
    [TherapyAccessReason.THERAPY_BUDGET_RESET_DATE_NULL]: {
      title: "Unable to determine if you can ask a therapist",
      message:
        "We were unable to determine your company's yearly therapy budget. Please email support@spill.chat for help",
    },
    [TherapyAccessReason.USER_HAS_UPCOMING_APPOINTMENTS]: {
      title: "",
      message: `Since you have an upcoming session, you can't ask a therapist. Email therapy@spill.chat with questions.`,
    },
    [TherapyAccessReason.YEARLY_THERAPY_BUDGET_IS_NULL]: {
      title: "",
      message:
        "We were unable to determine your company's yearly therapy budget.",
    },
    [TherapyAccessReason.NOT_ENOUGH_USER_CAP_CREDITS]: {
      title: "Ask a therapist not available",
      message: "You don't have enough credits to ask a therapist.",
    },
    [TherapyAccessReason.REVOKED_USER]: {
      title: "",
      message: "Your account has been deactivated.",
    },
    [TherapyAccessReason.OK]: OKMessage,
    // This reason won't happen for AAT but we have to have a state for it
    [TherapyAccessReason.NO_ACCESS_TO_PACKAGE_TYPE]: OKMessage,
    [TherapyAccessReason.NOT_ENOUGH_SESSION_PACK_CREDITS]: OKMessage,
    [TherapyAccessReason.TRIAL_CREDITS_EXHAUSTED]: OKMessage,
  },
  [AppointmentTypeFeature.PRIVATE_THERAPY_SESSION]: {
    [TherapyAccessReason.NOT_FOUND]: {
      title: "",
      message: "Not found",
    },
    [TherapyAccessReason.USER_HAS_UPCOMING_APPOINTMENTS]: {
      title: "",
      message: `Since you have an upcoming session, you can't book another private session. Email therapy@spill.chat with questions.`,
    },
    [TherapyAccessReason.REVOKED_USER]: {
      title: "",
      message: `You can only book private sessions for 3 months after leaving your company.`,
    },
    [TherapyAccessReason.OK]: OKMessage,

    // These may not ever ever happen for private sessions, so we don't have messages for them and assume they're
    // ok to happen
    [TherapyAccessReason.THERAPY_BUDGET_RESET_DATE_NULL]: OKMessage,
    [TherapyAccessReason.YEARLY_THERAPY_BUDGET_IS_NULL]: OKMessage,
    [TherapyAccessReason.NOT_ENOUGH_USER_CAP_CREDITS]: OKMessage,
    [TherapyAccessReason.FEATURE_DISABLED_BY_COMPANY]: OKMessage,
    [TherapyAccessReason.NOT_ENOUGH_COMPANY_THERAPY_BUDGET_CREDITS]: OKMessage,
    [TherapyAccessReason.NO_ACCESS_TO_PACKAGE_TYPE]: OKMessage,
    [TherapyAccessReason.NOT_ENOUGH_SESSION_PACK_CREDITS]: OKMessage,
    [TherapyAccessReason.TRIAL_CREDITS_EXHAUSTED]: OKMessage,
  },
}

/**
 * Returns an object containing a message and title based on the appointment type and the API response containing
 * the reason why a user may not be able to book a session.
 */
export const bookingAvailabilityMessage = (
  appointmentType:
    | AppointmentTypeFeature.SERIES_CONSULTATION_SESSION
    | AppointmentTypeFeature.ONE_OFF_THERAPY_SESSION
    | AppointmentTypeFeature.PRIVATE_THERAPY_SESSION
    | "AskATherapist",
  reason: TherapyAccessReason,
  additionalData?: AdditionalMessageData
): { title: string; message: string } => {
  const messageConfig = messages[appointmentType]?.[reason]
  return typeof messageConfig === "function"
    ? messageConfig(additionalData)
    : messageConfig
}
