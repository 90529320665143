/**
 * This is the success/confirmation page that we show the user after they've
 * successfully installed the Slack app.
 */

import { gql, useQuery } from "@apollo/client"
import { FunctionComponent, useEffect } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { toast } from "sonner"
import { Button, H2, P } from "@spillchat/puddles"

import {
  GroupSelectGetCompanyQuery,
  CompanyOnboardingStep,
  GroupSelectGetCompanyQueryVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { NotFound404Page } from "common/components/NotFound404Page"
import { useOnboarding } from "common/context/onboardingContext"
import { useUser } from "common/context/userContext"
import { config } from "config"
import { FEATURE_FLAGS } from "common/constants/flags"
import { ChannelChooser } from "common/components/IntegrationComponents/ChannelChooser"

import { platformTypesInfo } from "."

const queries = {
  getCompany: gql`
    query GroupSelectGetCompany($companyPlatformId: ID!) {
      company {
        id
        platforms(filter: { ids: [$companyPlatformId] }) {
          id
          name
          externalId
          platformType
          avatarUrl
          domain
          allowAccessAll
          externalGroups {
            id
            name
            hasSyncGroup
          }
        }
      }
    }
  `,
}

interface Props {
  setParentPlatformType: (platformType: string | undefined) => void
}

export const GroupSelect: FunctionComponent<Props> = ({
  setParentPlatformType,
}) => {
  const { platformType } = useParams()
  useEffect(() => {
    setParentPlatformType(platformType)
  }, [platformType])
  const { flags } = useUser()
  const { markAsComplete } = useOnboarding()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const companyPlatformId = searchParams.get("companyPlatformId")

  const { data: companyData, loading } = useQuery<
    GroupSelectGetCompanyQuery,
    GroupSelectGetCompanyQueryVariables
  >(queries.getCompany, {
    fetchPolicy: "cache-first",
    skip: companyPlatformId == null,
    variables: { companyPlatformId: companyPlatformId as string },
  })

  const platformTypeInfo = platformTypesInfo[platformType ?? ""]

  if (!platformTypeInfo || companyPlatformId === null) {
    return <NotFound404Page />
  }

  const gatewayUrl =
    companyData?.company.id !== null
      ? new URL(platformTypeInfo.gatewayPath, config.spill.gateway.baseUrl)
      : null
  gatewayUrl?.searchParams.set("state", companyData?.company.id ?? "")

  const platformData = companyData?.company?.platforms[0]

  if (!platformData && !loading) {
    return (
      <div className="flex flex-col gap-3">
        <H2>
          We weren't able to find your {platformTypeInfo.companyPlatformTerm}.
        </H2>
        <P muted>
          We weren't able to find your {platformTypeInfo.name}{" "}
          {platformTypeInfo.companyPlatformTerm}. Please try again. If the issue
          persists, please email hi@spill.chat
        </P>
        <Button asChild variant="primary">
          <Link
            to={`/admin/settings/access/install/${platformTypeInfo.name.toLowerCase()}/success?companyPlatformId=${companyPlatformId}`}
          >
            Try again
          </Link>
        </Button>
      </div>
    )
  }

  const afterConfirmation = async (success: boolean, errorMessage: string) => {
    if (markAsComplete != null) {
      await markAsComplete(CompanyOnboardingStep.ADDED_TEAM)
    }

    if (success) {
      toast.success("Successfully added Spill")
    } else {
      toast.error(errorMessage, {
        description: "If the issue persists, please get in touch",
      })
    }

    const nextPageUrl = createNextPageUrl(
      companyPlatformId,
      flags[FEATURE_FLAGS.USE_RESTRICT_USERS]
    )
    navigate(nextPageUrl)
  }

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <H2>Add Spill to specific channels</H2>
        <P muted>
          By default, Spill will create an account for everyone in the
          workspace. To restrict access, add Spill to a channel from the list
          below and only users in the chosen channel will get access. Nobody
          will be contacted by Spill until you invite them.
        </P>
        <P muted>
          You can uninstall the Slack integration from your access settings.
        </P>
      </div>
      <div className="flex items-center justify-center">
        {platformData ? (
          <ChannelChooser
            platformData={platformData}
            gatewayUrl={gatewayUrl?.toString() ?? ""}
            platformTypeInfo={platformTypeInfo}
            afterConfirmation={afterConfirmation}
          />
        ) : (
          <LoadingSpinner sizeInPixels={50} />
        )}
      </div>
    </div>
  )
}

const createNextPageUrl = (
  companyPlatformId: string,
  flag: boolean
): string => {
  const restrictUrl = "/admin/settings/access/install/slack/restrict"
  const linkUsersUrl = "/admin/settings/access/install/slack/link"

  const nextPageUrl = flag ? restrictUrl : linkUsersUrl

  return nextPageUrl.toString() + `?companyPlatformId=${companyPlatformId}`
}
