import { FunctionComponent, useState } from "react"
import { Link } from "react-router-dom"
import { Button, H2, P, SelectableCard } from "@spillchat/puddles"

import TeamsLogo from "common/assets/images/Microsoft-Teams-logo.svg?react"
import SlackLogo from "common/assets/images/Slack-logo.svg?react"

enum Integration {
  SLACK = "slack",
  TEAMS = "teams",
}

export const PlatformSelect: FunctionComponent = () => {
  const [integration, setIntegration] = useState<Integration | null>(null)

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <H2>Create accounts for your team</H2>

        <P muted>
          Adding Spill to your company workspace will give employees an easy way
          to book therapy in just a few clicks.
        </P>
        <P muted>
          Don’t worry, your employees won’t be contacted by Spill until later in
          the process.
        </P>
      </div>

      <div className="flex md:flex-row flex-col gap-5 justify-center w-full">
        <SelectableCard
          size="lg"
          title="Slack"
          value={Integration.SLACK}
          checked={integration == Integration.SLACK}
          onClick={() => {
            setIntegration(Integration.SLACK)
          }}
          subtitle="Select to add"
          type="radio"
          label={
            <div className="size-12 flex flex-col items-center mb-4">
              <SlackLogo className="w-full" />
            </div>
          }
          className="w-full"
        />
        <SelectableCard
          size="lg"
          title="Microsoft Teams"
          value={Integration.TEAMS}
          checked={integration == Integration.TEAMS}
          onClick={() => {
            setIntegration(Integration.TEAMS)
          }}
          subtitle="Select to add"
          type="radio"
          label={
            <div className="size-12 flex flex-col items-center mb-4">
              <TeamsLogo className="w-full" />
            </div>
          }
          className="w-full"
        />
      </div>

      {integration != null && (
        <Button variant="primary" asChild>
          <Link to={`/admin/settings/access/install/${integration}`}>Next</Link>
        </Button>
      )}
    </div>
  )
}
