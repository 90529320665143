import { z } from "zod"
import { CountryCode, getCountryCallingCode } from "libphonenumber-js"
import { fromZonedTime } from "date-fns-tz"
import { isValid, parse } from "date-fns"
import { isEmpty, omitBy } from "lodash"

import { BookableAppointmentType, Gender } from "types/graphql"

import {
  isBereavementAppointmentType,
  isParenthoodAppointmentType,
} from "./helpers/appointmentTypeHelper"

//Work around, select component will push an empty string. This extends to allow that in the validation
//Also this is how you merge two enums in typescript (!Wild!)
type EmptyGender = Gender | ""
const EmptyGender = { ...Gender, "": "" }

const nullableValueIsNotNull = (value: string | number | null | undefined) => {
  return value !== null && value !== undefined
}

//Presession has some complicated validation.
const hasOneOrMoreSelected = (
  required: boolean,
  options: {
    key: number
    label: string
    selected: boolean
    value: string
  }[]
) => {
  //This is optional
  if (!required) {
    return true
  }

  const selected = options.filter(option => option.selected === true)
  //If there are no selections
  return selected.length > 0
}

const otherIsSelectedAndNotEmpty = (
  required: boolean,
  options: {
    key: number
    label: string
    selected: boolean
    value: string
  }[]
) => {
  //This is optional
  if (!required) {
    return true
  }

  const selected = options.filter(option => option.selected === true)

  //If there is only one selection and it is the "Other" option and it's value is empty
  if (
    selected.length === 1 &&
    selected.some(option => option.label === "Other" && option.value === "")
  ) {
    return false
  }

  return true
}

const valueIsOptionalOrNotEmpty = (
  required: boolean,
  value: string | null | undefined
) => {
  if (!required) {
    return true
  }

  return value?.trim() !== "" && value !== undefined && value !== null
}

export const therapyBookingFormSchema = z
  .object({
    appointmentType: z.string(),
    startTime: z.string(),
    timeZone: z.string(),
    selectedCounsellorInfo: z
      .object({
        id: z.string(),
        firstName: z.string(),
        pmiEnabledforCompany: z.boolean(),
      })
      .optional(),
    filter: z.object({
      counsellorIds: z.array(z.string()).optional(),
      previousCounsellorId: z.string().optional().nullable(),
      specialism: z.string().optional().nullable(),
      gender: z.nativeEnum(EmptyGender).optional(),
      pmiOnly: z.boolean().optional(),
      pmiProvider: z.string().optional().nullable(),
      language: z.string().optional(),
      lifeEvent: z.string().optional(),
    }),
    //The order is important, as the validation will scroll to the error element in the order of the schema
    userInfo: z.object({
      firstName: z.string().refine(value => value.trim().length > 0, {
        message: "First name is required",
      }),
      lastName: z.string().refine(value => value.trim().length > 0, {
        message: "Last name is required",
      }),
      pronouns: z.string().optional(),
      dateOfBirth: z
        .object({
          dayOfBirth: z.string().optional(),
          monthOfBirth: z.string().optional(),
          yearOfBirth: z.string().optional(),
        })
        .refine(
          value => {
            if (
              value.dayOfBirth == undefined ||
              value.monthOfBirth == undefined ||
              value.yearOfBirth === undefined
            ) {
              return false
            }
            const dayOfBirth = value.dayOfBirth.padStart(2, "0")
            const parsedDate = parse(
              `${dayOfBirth}${value.monthOfBirth}${value.yearOfBirth}`,
              "ddMMyyyy",
              new Date()
            )
            return isValid(parsedDate)
          },
          {
            message: "Please enter a valid date of birth",
          }
        ),
      phoneCountryCode: z.custom<CountryCode>((value: CountryCode) => {
        if (value === undefined) {
          return "en"
        }

        return value
      }),
      phone: z.string().refine(value => value.trim().length > 0, {
        message: "Please enter a valid phone number",
      }),
      email: z
        .string({ required_error: "Email is required" })
        .email("Please enter a valid email"),
    }),
    clinicalInfo: z.object({
      locationPostCode: z.string().refine(value => value.trim().length > 0, {
        message: "Please enter a postcode",
      }),
      locationTown: z.string().refine(value => value.trim().length > 0, {
        message: "Please enter a city/town",
      }),
      emergencyContactName: z
        .string()
        .refine(value => value.trim().length > 0, {
          message: "Please enter an emergency contact name",
        }),
      emergencyCountryCode: z.custom<CountryCode>((value: CountryCode) => {
        if (value === undefined) {
          return "en"
        }

        return value
      }),
      emergencyContactPhone: z
        .string({ required_error: "Please enter an emergency contact number" })
        .min(1, "Please enter an emergency contact number"),
      notice: z.boolean().refine(value => value === true, {
        message: "Please agree to giving notice",
      }),
      boundaries: z.boolean().refine(value => value === true, {
        message: "Please read and accept boundaries",
      }),
    }),
    preSession: z.object({
      notes: z
        .object({
          usedByAppointmentType: z.boolean(),
          value: z.string().nullable().optional(),
        })
        .refine(
          value =>
            valueIsOptionalOrNotEmpty(value.usedByAppointmentType, value.value),
          {
            message:
              "Please let your counsellor know what brought you to Spill today",
          }
        ),
      parentalLeave: z
        .object({
          usedByAppointmentType: z.boolean(),
          value: z.string().nullable().optional(),
        })
        .refine(
          value =>
            valueIsOptionalOrNotEmpty(value.usedByAppointmentType, value.value),
          {
            message: "Please select a parental leave option",
          }
        ),
      recentLoss: z
        .object({
          usedByAppointmentType: z.boolean(),
          value: z.string().nullable().optional(),
        })
        .refine(
          value =>
            valueIsOptionalOrNotEmpty(value.usedByAppointmentType, value.value),
          {
            message: "Please select an option",
          }
        ),
      recentLossAffectedHow: z
        .object({
          usedByAppointmentType: z.boolean(),
          value: z.string().nullable().optional(),
        })
        .refine(
          value =>
            valueIsOptionalOrNotEmpty(value.usedByAppointmentType, value.value),
          {
            message:
              "Please let your counsellor know how this has affected you",
          }
        ),
      previousBereavementCounselling: z
        .object({
          usedByAppointmentType: z.boolean(),
          value: z.string().nullable().optional(),
        })
        .refine(
          value =>
            valueIsOptionalOrNotEmpty(value.usedByAppointmentType, value.value),
          {
            message:
              "Please let your counsellor know if you've received support like bereavement counselling before",
          }
        ),
      supportAreas: z
        .object({
          usedByAppointmentType: z.boolean(),
          options: z.array(
            z.object({
              key: z.number(),
              label: z.string(),
              selected: z.boolean(),
              value: z.string(),
            })
          ),
        })
        .refine(
          value =>
            hasOneOrMoreSelected(value.usedByAppointmentType, value.options),
          {
            message: "Please select a support option",
          }
        )
        .refine(
          value =>
            otherIsSelectedAndNotEmpty(
              value.usedByAppointmentType,
              value.options
            ),
          {
            message: "Please add a value to the 'Other' option",
          }
        ),
      copingRating: z
        .number({ required_error: "Please select a rating" })
        .int()
        .min(1)
        .max(10)
        .nullable()
        .refine(value => nullableValueIsNotNull(value), {
          message: "Please select a rating",
        }),

      currentEmotions: z
        .array(
          z.object({
            key: z.string(),
            emotion: z.string(),
            frequency: z.string().optional(),
          })
        )
        .refine(value => value.length > 0, {
          message: "Please select at least one emotion",
        })
        .refine(
          value => {
            const invalidCustomEmotions = value.filter(
              emotion =>
                emotion.key?.startsWith("customEmotion") === true &&
                (emotion.emotion === undefined || emotion.emotion === "")
            )
            if (invalidCustomEmotions.length === 0) {
              return true
            }

            return false
          },
          { message: "Custom emotion cannot be blank" }
        )
        .refine(
          value => {
            const emptyFrequencies = value.filter(
              emotion =>
                emotion.frequency === undefined && emotion.emotion !== ""
            )
            if (emptyFrequencies.length === 0) {
              return true
            }

            return false
          },
          { message: "Please select a frequency" }
        ),
      sessionGoals: z
        .object({
          usedByAppointmentType: z.boolean(),
          options: z.array(
            z.object({
              key: z.number(),
              label: z.string(),
              selected: z.boolean(),
              value: z.string(),
            })
          ),
        })
        .refine(
          value =>
            hasOneOrMoreSelected(value.usedByAppointmentType, value.options),
          {
            message: "Please select a session goal option",
          }
        )
        .refine(
          value =>
            otherIsSelectedAndNotEmpty(
              value.usedByAppointmentType,
              value.options
            ),
          {
            message: "Please add a value to the 'Other' option",
          }
        ),
      birthComplications: z.object({
        usedByAppointmentType: z.boolean(),
        value: z.string().nullable().optional(),
      }),
    }),
  })
  .transform(data => {
    const counsellorIds =
      data.filter?.counsellorIds != undefined &&
      data.filter.counsellorIds.length > 0
        ? data.filter.counsellorIds
        : data.filter.previousCounsellorId != null &&
            data.filter.previousCounsellorId !== ""
          ? [data.filter.previousCounsellorId]
          : undefined

    const allowOtherFilters = counsellorIds === undefined

    const startTimeUtc = fromZonedTime(
      data.startTime,
      data.timeZone
    ).toISOString()

    //These are in all flows
    const standardBaseLineQuestions = [
      {
        section: "baseline",
        question:
          "With this in mind, in the last 2 weeks how have you been coping?",
        answer: data.preSession.copingRating?.toString(),
      },
      {
        section: "baseline",
        question:
          "And in the last 2 weeks have you been feeling any of the following?",
        answer: data.preSession.currentEmotions
          .map(emotion => {
            return `${emotion.emotion}`
          })
          .join(" "),
      },
      {
        section: "baseline",
        question:
          "How often in the last 2 weeks have you been feeling like this?",
        answer: data.preSession.currentEmotions
          .map(emotion => {
            return `${emotion.emotion} - ${emotion.frequency}`
          })
          .join("\n"),
      },
    ]

    const parentHoodBaseLineQuestions = [
      {
        section: "baseline",
        question: "Which best describes where you are in your parental leave?",
        answer: data.preSession.parentalLeave.value ?? "",
      },
      ...standardBaseLineQuestions,
      {
        section: "baseline",
        question:
          "Finally, are there or were there any complications around birth or pregnancy that you'd like your therapist to know about?",
        answer:
          data.preSession.birthComplications.value ?? "User left this blank",
      },
    ]

    const bereavementBaseLineQuestions = [
      {
        section: "baseline",
        question: "Have you recently lost someone close to you?",
        answer: data.preSession.recentLoss.value ?? "",
      },
      {
        section: "baseline",
        question: "How has this affected you? What has it brought up for you?",
        answer: data.preSession.recentLossAffectedHow.value ?? "",
      },
      {
        section: "baseline",
        question:
          "Have you received any form of support like bereavement counselling elsewhere?",
        answer: data.preSession.previousBereavementCounselling.value ?? "",
      },
      ...standardBaseLineQuestions,
    ]

    const generalBaseLineQuestions = [
      {
        section: "baseline",
        question: "What brought you to Spill today?",
        answer: data.preSession.notes.value ?? "",
      },
      {
        section: "baseline",
        question: "What area of your life are you looking for support with?",
        answer: data.preSession.supportAreas.options
          .map(option => option.value)
          .join(", "),
      },
      ...standardBaseLineQuestions,
      {
        section: "baseline",
        question:
          "What are you looking to get out of the session, what is your goal?",
        answer: data.preSession.sessionGoals.options
          .map(option => option.value)
          .join(", "),
      },
    ]

    const appointmentType = data.appointmentType as BookableAppointmentType
    const baseLineQuestions = isParenthoodAppointmentType(appointmentType)
      ? parentHoodBaseLineQuestions
      : isBereavementAppointmentType(appointmentType)
        ? bereavementBaseLineQuestions
        : generalBaseLineQuestions

    const dayOfBirth = data?.userInfo?.dateOfBirth?.dayOfBirth?.padStart(2, "0")

    return {
      appointmentType: data.appointmentType,
      baselineQuestions: baseLineQuestions,
      filter: {
        ...omitBy(
          {
            counsellorIds: counsellorIds,
            gender: allowOtherFilters
              ? data.filter.gender === ""
                ? undefined
                : data.filter.gender
              : undefined,
            languageCode: allowOtherFilters ? data.filter.language : undefined,
            lifeEvent: allowOtherFilters ? data.filter.lifeEvent : undefined,
            specialism: allowOtherFilters ? data.filter.specialism : undefined,
          },
          isEmpty
        ),
        pmiOnly: allowOtherFilters ? data.filter.pmiOnly : undefined,
      },
      startTime: startTimeUtc,
      timeZone: data.timeZone,
      userInfo: {
        dateOfBirth:
          data.userInfo.dateOfBirth.yearOfBirth +
          "-" +
          data.userInfo.dateOfBirth.monthOfBirth +
          "-" +
          dayOfBirth,
        email: data.userInfo.email,
        emergencyContact: {
          name: data.clinicalInfo.emergencyContactName,
          phone: `${getCountryCallingCode(data.clinicalInfo.emergencyCountryCode)}${data.clinicalInfo.emergencyContactPhone}`,
        },
        firstName: data.userInfo.firstName,
        lastName: data.userInfo.lastName,
        location: `${data.clinicalInfo.locationTown}, ${data.clinicalInfo.locationPostCode}`,
        phoneNumber:
          getCountryCallingCode(data.userInfo.phoneCountryCode) +
          data.userInfo.phone,
        pronouns: data.userInfo.pronouns,
      },
      email: data.userInfo.email,
    }
  })
export type TherapyBookingForm = z.infer<typeof therapyBookingFormSchema>
