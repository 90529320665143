import { gql } from "@apollo/client"

export const queries = {
  getData: gql`
    query TherapyAdhdData {
      user {
        revokedAt
        therapyUsageCap
        companyTherapyCap
        numberSessionsUsedInCapPeriod
        company {
          name
          inActiveTrialPeriod
          subscriptionStatus
          trialStartDate
          budgetYearUsage {
            totalBillableUsage
          }
          capPeriod
          currentSessionPackUsage
          adhdCompanyPackageSetting {
            id
            state
            addOns {
              id
              addOnType
              properties {
                enabled
                companyContribution
              }
            }
          }
        }
        featuresAndSettings {
          oneOffs {
            value
          }
          userCanRequestTherapy {
            value
          }
          yearlyTherapyBudget {
            value
          }
          userTherapyCap {
            active
          }
          sessionPack {
            value
          }
          courses {
            value
          }
        }
        accessToTherapyFeatures {
          oneOffs {
            hasAccess
            reason
          }
        }
      }
      getCoPayRequestForUser {
        id
        status
      }
    }
  `,
}
