import { FunctionComponent, useState } from "react"
import { useQuery } from "@apollo/client"
import { Link, Navigate } from "react-router-dom"
import { H1, H3, P, H2, Alert, Button } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { groupBy } from "lodash"
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { toast } from "sonner"

import ImageCoPay from "common/assets/images/product/specialised-support/therapy-copay.png"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  AddOnType,
  PackageRequestStatus,
  TherapyAdhdDataQuery,
  TherapyAdhdDataQueryVariables,
  useCopayRequestUpdateMutation,
} from "types/graphql"
import { AdminTherapySpecialisedSupportCopayProvider } from "features/admin-therapy-specialised-support-edit/co-pay/components/AdminTherapySpecialisedSupportCopayProvider"
import { AnonymityInSpill } from "common/components/AnonymityInSpill"
import { Dialog } from "common/components/Dialog"
import { formatCurrency } from "common/helpers/formatNumber"

import { queries } from "../therapy-adhd.queries"

export const TherapyAdhdPageCopay: FunctionComponent = () => {
  const [cancelRequestModal, setCancelRequestModal] = useState(false)

  const [updateRequest] = useCopayRequestUpdateMutation({
    refetchQueries: ["TherapyAdhdData"],
  })

  const { data, called, loading } = useQuery<
    TherapyAdhdDataQuery,
    TherapyAdhdDataQueryVariables
  >(queries.getData)

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const user = data?.user
  const company = data?.user?.company

  const coPayRequests = groupBy(data?.getCoPayRequestForUser, "status")

  const hasNotRequestedCoPay =
    data?.getCoPayRequestForUser?.filter(request => {
      return request.status !== PackageRequestStatus.CANCELLED_BY_USER
    })?.length === 0

  const { adhdCompanyPackageSetting } = data?.user?.company ?? {}

  const coPaySettings = adhdCompanyPackageSetting?.addOns.find(
    addon => addon.addOnType === AddOnType.CO_PAY
  )

  if (!user || !company) {
    return <Navigate to="/" />
  }

  const featureList = [
    {
      text: "Click the button below to make a request for a contribution.",
    },
    {
      text: "Your company will handle your request within 7 days. If approved, Spill will get in touch to match you with an ADHD specialist and take your side of the payment.",
    },
    {
      text: "You will then have the assessment and get the results from your assessor within 14 days.",
    },
  ]

  return (
    <>
      <Helmet title="ADHD support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-5 max-w-screen-sm">
            <H1>Assessment Co-Pay</H1>
            <H3>How it works</H3>
            <div className="max-w-md flex flex-col gap-3">
              <P muted>
                Having an ADHD assessment could lead to a diagnosis. This in
                turn could give you a clearer understanding of symptoms you are
                experiencing and access to relevant medication or other
                treatment.
              </P>
              <P muted>
                Your company has offered to make a financial contribution to
                getting an ADHD assessment with Spill. Your assessment will be
                with a verified ADHD specialist, totally private, and will be
                faster than going via the NHS.
              </P>
            </div>
          </div>
          <div className="hidden max-w-xs lg:flex">
            <img
              src={ImageCoPay}
              alt="Someone has a Spill session using their laptop"
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-wrap justify-between gap-24">
          <div className="flex flex-col gap-8 max-w-md">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-3">
                <H2>Requesting this support</H2>
                <P muted>How it's set up for your company</P>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <H3>Processing a request</H3>
                  <div className="flex flex-col gap-3">
                    {featureList.map((feature, i) => {
                      return (
                        <div className="flex gap-2" key={i}>
                          <P muted>{i + 1}.</P>
                          <P muted>{feature.text}</P>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <Alert
                  variant="warning"
                  title="Only available for employees based in the UK"
                >
                  <P size="xs">
                    Spill currently only offers assessment and medication in the
                    UK as our provider doesn't have the jurisdiction to provide
                    medical treatment to clients in other countries.
                  </P>
                </Alert>
              </div>
            </div>
            <hr />
            <div className="flex flex-col gap-6">
              {hasNotRequestedCoPay ? (
                <>
                  <div className="flex flex-col gap-3">
                    <H3>Ready to get started</H3>
                    <P muted>
                      We'll take you through 2 quick questions to finalise the
                      request to your company admin.
                    </P>
                  </div>
                  <Button asChild>
                    <Link to="/therapy/specialised-support/adhd/co-pay/request">
                      Get started
                    </Link>
                  </Button>
                </>
              ) : (
                <>
                  {coPayRequests[PackageRequestStatus.APPROVED] ? (
                    <>
                      <div className="flex flex-col gap-3">
                        <H3>Ready to get started</H3>
                        <P muted>
                          Your request was approved. You will receive a message
                          from Spill within the next 2 days to take you though
                          the next steps. If you don't hear from us, speak to
                          your admin or get in touch with Spill.
                        </P>
                      </div>
                      <Button size="sm" variant="readonly" asChild>
                        <div className="flex gap-1 items-center">
                          <CheckIcon width={16} />
                          <span>Request approved</span>
                        </div>
                      </Button>
                    </>
                  ) : (
                    <>
                      {coPayRequests[PackageRequestStatus.DECLINED] ? (
                        <>
                          <div className="flex flex-col gap-3">
                            <H3>Ready to get started</H3>
                            <P muted>
                              Your request was declined. Speak to your admin
                              about your options.
                            </P>
                          </div>
                          <Button size="sm" variant="readonly" asChild>
                            <div className="flex gap-1 items-center">
                              <XMarkIcon width={16} />
                              <span>Request declined</span>
                            </div>
                          </Button>
                        </>
                      ) : (
                        <>
                          {coPayRequests[PackageRequestStatus.PENDING] ? (
                            <>
                              <div className="flex flex-col gap-3">
                                <H3>Ready to get started</H3>
                                <P muted>
                                  We'll take you through 2 quick questions to
                                  finalise the request to your company admin.
                                </P>
                              </div>
                              <div className="flex gap-2">
                                <Button size="sm" variant="readonly" asChild>
                                  <div className="flex gap-1 items-center">
                                    <CheckIcon width={16} />
                                    <span>Requested</span>
                                  </div>
                                </Button>
                                <Button
                                  size="sm"
                                  variant="destructive"
                                  disabled={false}
                                  onClick={() => setCancelRequestModal(true)}
                                >
                                  Cancel request
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex flex-col gap-3">
                                <H3>Ready to get started</H3>
                                <P muted>
                                  We'll take you through 2 quick questions to
                                  finalise the request to your company admin.
                                </P>
                              </div>
                              <Button asChild>
                                <Link to="/therapy/specialised-support/adhd/co-pay/request">
                                  Get started
                                </Link>
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <AnonymityInSpill description="Your name won't be shared unless you choose to." />
            </div>
          </div>

          <div className="flex flex-col gap-5 w-full max-w-sm">
            {coPaySettings?.properties.companyContribution != null && (
              <>
                <div className="flex flex-col gap-3">
                  <H2>Payment amounts</H2>
                  <P muted>How the cost is split</P>
                </div>
                <div className="flex justify-between">
                  <div className="grow flex flex-col gap-3 border-r border-grey-100">
                    <P weight="medium">Company pays</P>
                    <P muted>
                      {formatCurrency(
                        coPaySettings?.properties.companyContribution
                      )}
                    </P>
                  </div>
                  <div className="grow flex flex-col gap-3 px-12">
                    <P weight="medium">Left to pay</P>
                    <P muted>
                      {formatCurrency(
                        995 - coPaySettings?.properties.companyContribution
                      )}
                    </P>
                  </div>
                </div>
                <hr className="border-grey-100 my-3" />
              </>
            )}
            <AdminTherapySpecialisedSupportCopayProvider />
          </div>
        </div>
      </section>
      <Dialog
        canClose
        isOpen={cancelRequestModal}
        onClose={() => setCancelRequestModal(false)}
        title="Cancel request?"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Not now",
            onClick: () => setCancelRequestModal(false),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Cancel request",
            onClick: async () => {
              const request = coPayRequests[PackageRequestStatus.PENDING]
              const packageRequestId = request?.[0]?.id

              if (packageRequestId == null) {
                return toast.error("No request found to cancel")
              }

              await updateRequest({
                variables: {
                  packageRequestId,
                  noteFromAdmin: "",
                  status: PackageRequestStatus.CANCELLED_BY_USER,
                },
              })

              return setCancelRequestModal(false)
            },
          },
        ]}
      >
        <P>
          You will be able to request this again if you change your&nbsp;mind.
        </P>
      </Dialog>
    </>
  )
}
