import { gql } from "@apollo/client"

export const fragments = {
  queryFields: gql`
    fragment TeamEngagementQueryFields on Query {
      user {
        id
        company {
          id
          subscriptionPlan
          trialStartDate
          billingAdvice
          feedback {
            numberOfResponses
            aggregates {
              wouldRecommend
              metTheirGoals
              wouldSeeAgain
            }
            sharedFeedback {
              month
              feedbackText
              userName
              therapyType
            }
          }
          featuresAndSettings {
            pulse {
              active
            }
          }
          allUsage {
            askATherapistQuestions
            consultations
            courseSessions
            oneOffs
            distinctUserPulseCheckins
            pulseTriages
            month
            plusOnes
            pmiSessions
            specialisedSupportSessions
          }
          platforms {
            id
            externalId
            hasAnnouncedOnboarding
            platformType
          }
        }
      }
    }
  `,
  feedbackQueryFields: gql`
    fragment FeedbackQueryFields on Query {
      user {
        id
        company {
          id
          subscriptionPlan
          trialStartDate
          billingAdvice
          feedback {
            numberOfResponses
            aggregates {
              wouldRecommend
              metTheirGoals
              wouldSeeAgain
            }
            sharedFeedback {
              month
              feedbackText
              userName
              therapyType
            }
          }
        }
      }
    }
  `,
}

export const queries = {
  getCompany: gql`
    query EngagementGetCompany {
      ...TeamEngagementQueryFields
    }
    ${fragments.queryFields}
  `,
  getCompanyFeedback: gql`
    query EngagementGetCompanyFeedback {
      ...FeedbackQueryFields
    }
    ${fragments.feedbackQueryFields}
  `,
  getUsageGoalData: gql(`
    query getCompanyUsageGoalData {
     usageData: getCompanyUsage {
        quantity
        startDate
        endDate
        feature
      }
    }
  `),
}
