import { Alert, H1, H2, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { useQuery } from "@apollo/client"

import therapyCopay from "common/assets/images/product/specialised-support/therapy-copay.png"
import {
  InviteAdhdSettingsQuery,
  InviteAdhdSettingsQueryVariables,
  AddOnType,
} from "types/graphql"
import { BackButton } from "common/components/BackButton"

import { queries } from "../../invite-adhd/invite-adhd.queries"

import { AdminTherapySpecialisedSupportCopayForm } from "./components/AdminTherapySpecialisedSupportCopayForm"
import { AdminTherapySpecialisedSupportCopayProvider } from "./components/AdminTherapySpecialisedSupportCopayProvider"

export const AdminTherapySpecialisedSupportCopay: FunctionComponent = () => {
  const { data } = useQuery<
    InviteAdhdSettingsQuery,
    InviteAdhdSettingsQueryVariables
  >(queries.getSettings)

  const { adhdCompanyPackageSetting } = data?.user?.company ?? {}
  const coPaySettings = adhdCompanyPackageSetting?.addOns.find(
    addon => addon.addOnType === AddOnType.CO_PAY
  )

  const featureList = [
    {
      text: "Employees will submit a request form which asks them for context about their symptoms. Their name won't be shared unless they choose to.",
    },
    {
      text: "You will have 7 days to handle the request. If approved, Spill will get in touch to match the employee with an ADHD specialist and take their side of the payment.",
    },
    {
      text: "The employee will then have the assessment and get their results from their assessor within 14 days.",
    },
    {
      text: "The company's side of the payment will be added to your upcoming invoice.",
    },
  ]

  return (
    <div className="flex flex-col gap-12">
      <BackButton
        to={"/admin/specialised-support/" + adhdCompanyPackageSetting?.id}
      />
      <div className="flex flex-col-reverse lg:flex-row justify-between lg:gap-24 grow">
        <div className="flex flex-col gap-5 max-w-lg">
          <H1>Assessment Co-Pay</H1>
          <div className="flex flex-col gap-3">
            <H3>How it works</H3>
            <P muted>
              Having an ADHD assessment could lead to a diagnosis. This in turn
              could give employees a clearer understanding of symptoms they are
              experiencing and access to relevant medication or other treatment.
            </P>
            <P muted>
              You can offer to make a financial contribution to getting an ADHD
              assessment with Spill. The assessment will be with a verified ADHD
              specialist, totally private, and will be faster than going via the
              NHS.
            </P>
          </div>
        </div>
        <div>
          <img
            src={therapyCopay}
            alt="A person looking at their phone whilst sat on a sofa"
            className="rounded-lg aspect-square object-contain w-full lg:max-w-sm"
          />
        </div>
      </div>
      <hr />
      <div className="flex flex-wrap justify-between gap-24">
        <div className="flex flex-col gap-8 max-w-md">
          <div className="flex flex-col gap-3">
            <H2>Support settings</H2>
            <P muted>How it's set up for your company</P>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <H3>Processing a request</H3>
              <div className="flex flex-col gap-3">
                {featureList.map((feature, i) => {
                  return (
                    <div className="flex gap-2" key={i}>
                      <P muted>{i + 1}.</P>
                      <P muted>{feature.text}</P>
                    </div>
                  )
                })}
              </div>
            </div>
            <Alert
              variant="warning"
              title="Only available for employees based in the UK"
            >
              <P size="xs">
                Spill currently only offers assessment and medication in the UK
                as our provider doesn't have the jurisdiction to provide medical
                treatment to clients in other countries.
              </P>
            </Alert>
          </div>
        </div>
        <div className="flex flex-col gap-4 w-full max-w-sm">
          {coPaySettings?.properties.companyContribution != null && (
            <AdminTherapySpecialisedSupportCopayForm
              defaultValues={{
                enabled: coPaySettings?.properties.enabled,
                companyContribution:
                  coPaySettings?.properties.companyContribution,
              }}
            />
          )}
          <hr />
          <AdminTherapySpecialisedSupportCopayProvider />
        </div>
      </div>
    </div>
  )
}
