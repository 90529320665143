import { FunctionComponent, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { H1, P, H2, Alert, Button } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { Link, Navigate } from "react-router-dom"
import {
  CheckIcon,
  HeartIcon,
  CalendarDaysIcon,
  LightBulbIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"
import { toast } from "sonner"

import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import {
  PackageRequestStatus,
  PackageState,
  PackageType,
  TherapySpecialisedSupportIndexCancelMutation,
  TherapySpecialisedSupportIndexCancelMutationVariables,
  TherapySpecialisedSupportIndexQuery,
  TherapySpecialisedSupportIndexQueryVariables,
} from "types/graphql"
import { SpecialisedSupportItem } from "features/specialised-support/components/SpecialisedSupportItem"
import { Dialog } from "common/components/Dialog"
import { formatMonthsNumberToYearOrMonthString } from "common/helpers/formatMonth"
import { getPackageConfig } from "features/admin-therapy/packages/specialised-package-config"

import { queries } from "./therapy-specialised-support.queries"
import { mutations } from "./therapy-specialised-support.mutations"

type PackageDetails = {
  grantedAccess: boolean
  requiresRequest: boolean
  requestIsPending: boolean
  requestId?: string
  packageDetails: {
    id: string
    name: string
    description: string
    iconUrl: string
    numberSessionsGiven: number
    numberSessionsUsed: number
    packageType: PackageType
  }
}

export const TherapySpecialisedSupportPage: FunctionComponent = () => {
  const [cancelRequestModal, setCancelRequestModal] = useState<string | null>(
    null
  )

  const { data, called, loading } = useQuery<
    TherapySpecialisedSupportIndexQuery,
    TherapySpecialisedSupportIndexQueryVariables
  >(queries.getPackages)

  const [cancelRequest] = useMutation<
    TherapySpecialisedSupportIndexCancelMutation,
    TherapySpecialisedSupportIndexCancelMutationVariables
  >(mutations.cancelRequest, {
    refetchQueries: ["TherapySpecialisedSupportIndex"],
    onCompleted() {
      setCancelRequestModal(null)
      toast("Request has been cancelled")
    },
  })

  if (!called || loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const packageDetails: PackageDetails[] =
    data?.user?.therapyPackages.map(pkg => {
      return {
        grantedAccess: true,
        requiresRequest: false,
        requestIsPending: false,
        requestId: undefined,
        packageDetails: {
          id: pkg.companyPackageSetting?.id ?? "",
          name: pkg.companyPackageSetting?.name ?? "",
          description: pkg.companyPackageSetting?.userFacingDescription ?? "",
          iconUrl: pkg.companyPackageSetting?.iconUrl ?? "",
          numberSessionsGiven: pkg?.numberSessionsGiven ?? 0,
          numberSessionsUsed: pkg?.numberSessionsUsed ?? 0,
          packageType:
            pkg.companyPackageSetting?.packageType ?? PackageType.CUSTOM,
        },
      }
    }) ?? []

  packageDetails.push(
    ...(data?.user?.visibleUnclaimedTherapyPackages.map(pkg => {
      return {
        grantedAccess: false,
        requiresRequest: true,
        requestIsPending:
          pkg.mostRecentRequest?.status === PackageRequestStatus.PENDING,
        requestId: pkg.mostRecentRequest?.id,
        packageDetails: {
          id: pkg.id,
          name: pkg.name ?? "",
          description: pkg.userFacingDescription ?? "",
          iconUrl: pkg.iconUrl ?? "",
          numberSessionsGiven: pkg.numberSessions,
          numberSessionsUsed: 0,
          packageType: pkg.packageType,
        },
      }
    }) ?? [])
  )
  const requestablePackages = data?.user?.visibleUnclaimedTherapyPackages
  const therapyPackages = data?.user?.therapyPackages

  const hasAdhdSupport =
    data?.user?.company?.adhdCompanyPackageSetting?.state ===
    PackageState.ACTIVE

  if (!requestablePackages) {
    return <Navigate to="/" />
  }

  const packagesAvailable = packageDetails.length > 0

  return (
    <>
      <Helmet title="Specialised support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex flex-col gap-4 max-w-screen-sm">
          <H1>Specialist support</H1>
          <H2>How it works</H2>
          <P muted>
            Your company may have set up additional therapy policies that are
            designed to support you through particular life events or
            situations. You can see which specialist support packages your
            company has authorised on this page.
          </P>
          <P muted>
            The options below are policies already set in place by your company.
          </P>
        </div>
        {!packagesAvailable ? (
          <div className="max-w-screen-sm">
            <Alert title="There are currently no packages available" />
          </div>
        ) : (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
            {packageDetails
              .filter(
                p =>
                  p.packageDetails.packageType === PackageType.PARENTHOOD ||
                  p.packageDetails.packageType === PackageType.BEREAVEMENT
              )
              .map((pkg, index) => {
                const config = getPackageConfig(pkg.packageDetails.packageType)
                return (
                  <SpecialisedSupportItem
                    key={index}
                    title={pkg.packageDetails.name}
                    subtitle={
                      config.tileDescription ?? pkg.packageDetails.description
                    }
                    image={pkg.packageDetails.iconUrl}
                    list={[
                      {
                        Icon: config.uspLineItem.icon,
                        text: config.uspLineItem.text,
                      },
                      {
                        Icon: HeartIcon,
                        text: `${pkg.packageDetails.numberSessionsGiven} sessions on top of your regular allowance`,
                      },
                      {
                        Icon: CalendarDaysIcon,
                        text: "Support for 2 years",
                      },
                    ]}
                  >
                    <div className="flex gap-4">
                      <Button asChild size="sm">
                        <Link
                          to={`/therapy/specialised-support/${pkg.packageDetails.id}`}
                        >
                          {pkg.grantedAccess || pkg.requestIsPending
                            ? "View package"
                            : "Get started"}
                        </Link>
                      </Button>
                      {pkg.requestIsPending && (
                        <>
                          <Button size="sm" variant="readonly" asChild>
                            <Link
                              to={`/therapy/specialised-support/${pkg.packageDetails.id}`}
                            >
                              <div className="flex gap-1 items-center">
                                <CheckIcon width={16} />
                                <span>Package requested</span>
                              </div>
                            </Link>
                          </Button>
                        </>
                      )}
                    </div>
                  </SpecialisedSupportItem>
                )
              })}
            {hasAdhdSupport && (
              <SpecialisedSupportItem
                title="ADHD support"
                subtitle="For employees that experience symptoms of Attention Deficit Hyperactivity Disorder, whether they've had a diagnosis or not."
                list={[
                  {
                    Icon: LightBulbIcon,
                    text: "Sessions with an ADHD specialist",
                  },
                  {
                    Icon: HeartIcon,
                    text: "Use your regular credit allowance",
                  },
                  {
                    Icon: CalendarDaysIcon,
                    text: "Renews every 6 months",
                  },
                ]}
                image={imageAdhd}
              >
                <Button size="sm" asChild>
                  <Link to="/therapy/specialised-support/adhd">
                    Get started
                  </Link>
                </Button>
              </SpecialisedSupportItem>
            )}
            {requestablePackages
              .filter(pkg => pkg.packageType === PackageType.CUSTOM)
              .map((pkg, index) => {
                return (
                  <SpecialisedSupportItem
                    key={index}
                    title={pkg.name ?? "Custom package"}
                    subtitle={pkg.userFacingDescription ?? ""}
                    image={pkg.iconUrl ?? ""}
                    list={[
                      {
                        Icon: UserPlusIcon,
                        text: "Specialist tailored to your needs",
                      },
                      {
                        Icon: HeartIcon,
                        text: `${pkg.numberSessions} sessions on top of your regular allowance`,
                      },
                      {
                        Icon: CalendarDaysIcon,
                        text:
                          pkg.numberMonthsToUse != null
                            ? `Support for ${formatMonthsNumberToYearOrMonthString(pkg.numberMonthsToUse)}`
                            : "Support indefinitely",
                      },
                    ]}
                  >
                    <div className="flex gap-2 items-center">
                      {pkg.mostRecentRequest != null &&
                      pkg?.mostRecentRequest?.status ===
                        PackageRequestStatus.PENDING ? (
                        <>
                          <Button size="sm" variant="readonly" asChild>
                            <Link
                              to={"/therapy/specialised-support/" + pkg.id}
                              className="pl-2"
                            >
                              <div className="flex gap-1 items-center">
                                <CheckIcon width={16} />
                                <span>Package requested</span>
                              </div>
                            </Link>
                          </Button>
                          <Button
                            variant="destructive"
                            size="sm"
                            onClick={() => {
                              if (pkg.mostRecentRequest?.id != null) {
                                setCancelRequestModal(pkg.mostRecentRequest?.id)
                              }
                            }}
                          >
                            Cancel request
                          </Button>
                        </>
                      ) : (
                        <Button size="sm" variant="primary" asChild>
                          <Link to={"/therapy/specialised-support/" + pkg.id}>
                            {pkg.mostRecentRequest != null &&
                            pkg.mostRecentRequest.status ===
                              PackageRequestStatus.PENDING
                              ? "View package"
                              : "Get started"}
                          </Link>
                        </Button>
                      )}
                    </div>
                  </SpecialisedSupportItem>
                )
              })}
            {therapyPackages
              ?.filter(
                pkg =>
                  pkg.companyPackageSetting?.packageType === PackageType.CUSTOM
              )
              .map((pkg, index) => {
                return (
                  <SpecialisedSupportItem
                    key={index}
                    title={pkg.companyPackageSetting?.name ?? "Custom package"}
                    subtitle={
                      pkg.companyPackageSetting?.userFacingDescription ?? ""
                    }
                    image={pkg.companyPackageSetting?.iconUrl ?? ""}
                    list={[
                      {
                        Icon: HeartIcon,
                        text: `${pkg.numberSessionsGiven} sessions on top of your regular allowance`,
                      },
                    ]}
                  >
                    <Button asChild size="sm">
                      <Link
                        to={
                          "/therapy/specialised-support/" +
                          pkg.companyPackageSetting?.id
                        }
                      >
                        View package
                      </Link>
                    </Button>
                  </SpecialisedSupportItem>
                )
              })}
          </div>
        )}
      </section>
      <Dialog
        canClose
        isOpen={cancelRequestModal !== null}
        onClose={() => setCancelRequestModal(null)}
        title="Cancel request?"
        buttons={[
          {
            key: "cancel",
            variant: "secondary",
            children: "Not now",
            onClick: () => setCancelRequestModal(null),
          },
          {
            key: "confirm",
            variant: "destructive",
            children: "Cancel request",
            onClick: async () => {
              if (cancelRequestModal !== null) {
                await cancelRequest({
                  variables: {
                    id: cancelRequestModal,
                    status: PackageRequestStatus.CANCELLED_BY_USER,
                  },
                })
              }
              setCancelRequestModal(null)
            },
          },
        ]}
      >
        <P>
          You will be able to request this again if you change your&nbsp;mind.
        </P>
      </Dialog>
    </>
  )
}
