/**
 * Shows the usage of a company's therapy over the course of the previous 12 months
 * And displays it against the overall allowance for the year
 */

import { gql, useQuery } from "@apollo/client"
import { FunctionComponent, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { EmptyState, H3, P, Tooltip } from "@spillchat/puddles"
import { useNavigate } from "react-router-dom"
import { addMonths, format, startOfMonth } from "date-fns"
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"

import {
  UsageChartGetUsageQuery,
  UsageChartGetUsageQueryVariables,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

export const fragments = {
  queryFields: gql`
    fragment UsageChartQueryFields on Query {
      user {
        id
        company {
          id
          budgetYearUsage {
            totalBillableUsage
          }
          trialStartDate
          featuresAndSettings {
            yearlyTherapyBudget {
              value
            }
            sessionPack {
              active
              value
            }
            rollOverSessions {
              active
            }
          }
          budgetSetting
          currentSessionPackUsage
          monthlySessionPackCreditsAvailable
          maximumAdditionalRollOverSessions
          companySessionPack {
            upcomingSessionPackSize
          }
          allUsage {
            month
            therapyHours
          }
          remainingAllowance {
            month
            oneOffsCovered
            coursesCovered
          }
        }
      }
    }
  `,
}

export const queries = {
  getUsage: gql`
    query UsageChartGetUsage {
      ...UsageChartQueryFields
    }
    ${fragments.queryFields}
  `,
}

interface UsageChartProps {
  hidePositiveFeedback?: boolean
}

export const UsageChart: FunctionComponent<UsageChartProps> = props => {
  const navigate = useNavigate()
  const { data, loading } = useQuery<
    UsageChartGetUsageQuery,
    UsageChartGetUsageQueryVariables
  >(queries.getUsage)
  const sessionPack = data?.user?.company?.featuresAndSettings.sessionPack
  /**
   * Given we've got two different graphs, we have this state
   */
  const [chartData, setChartData] = useState<{
    percentage: number
    maximum: string | number
  }>({
    percentage: 0,
    maximum: "",
  })

  const calcPercentage = (sum: number, total: number) =>
    Math.round((sum / total) * 100)

  const totalBillableUsage =
    data?.user?.company?.budgetYearUsage?.totalBillableUsage ?? 0

  // Let's us know if a company has set up a yearly budget or not
  const hasCompanyBudget = Number(chartData.maximum) > 0
  const isOverBudget = totalBillableUsage > Number(chartData.maximum)
  const usage = isOverBudget ? chartData.maximum : Math.ceil(totalBillableUsage)

  useEffect(() => {
    const budgetBasedMaximum = data?.user?.company?.budgetSetting ?? 0
    const budgetBasedUsageAsPercentage = Math.round(
      (Math.ceil(totalBillableUsage) / budgetBasedMaximum) * 100
    )

    const sessionPackMaximum = sessionPack?.value ?? 0

    const sessionPackUsageAsPercentage = Math.round(
      (Math.ceil(data?.user?.company?.currentSessionPackUsage ?? 0) /
        sessionPackMaximum) *
        100
    )

    setChartData({
      percentage:
        sessionPack?.active === true
          ? sessionPackUsageAsPercentage
          : budgetBasedUsageAsPercentage,
      maximum:
        sessionPack?.active === true ? sessionPackMaximum : budgetBasedMaximum,
    })
  }, [data])

  const rollover = {
    sessionPack:
      data?.user?.company?.featuresAndSettings.sessionPack.value ?? 0,
    currentUsage: data?.user?.company?.currentSessionPackUsage ?? 0,
    totalBudget: data?.user?.company?.monthlySessionPackCreditsAvailable ?? 0,
    maxValue: data?.user?.company?.maximumAdditionalRollOverSessions ?? 0,
  }

  const upcomingSessionPackSize =
    data?.user?.company?.companySessionPack?.upcomingSessionPackSize

  if (loading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )

  const companyOnTrial = data?.user?.company?.trialStartDate != null
  const hasRollovers =
    data?.user?.company?.featuresAndSettings.rollOverSessions.active === true

  const usageView = (): "sessionPack" | "empty" | null => {
    if (data?.user?.company?.featuresAndSettings?.sessionPack.active === true) {
      return "sessionPack"
    }

    if (hasCompanyBudget === false) {
      return "empty"
    }

    return null
  }

  const currentUsageView = usageView()

  if (currentUsageView === "sessionPack") {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <H3>
            {rollover.currentUsage}/{rollover.totalBudget} session credits
          </H3>
          <P muted>Used this month</P>
        </div>
        {hasRollovers === true ? (
          <div className="flex flex-col w-full">
            <div className="flex w-full items-center gap-4">
              <div className="flex items-center">
                <span className="w-3 h-3 bg-yellow-400 rounded-sm mr-2"></span>
                <div className="flex items-center gap-1">
                  <P>{companyOnTrial ? "Trial usage" : "Used so far"}</P>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <InformationCircleIcon className="size-4" />
                      </Tooltip.Trigger>
                      <Tooltip.Content side="top">
                        Total number of sessions booked so far
                        {companyOnTrial ? "" : " this month"}.
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </div>
              <div className="flex items-center">
                <span className="w-3 h-3 bg-yellow-200 rounded-sm mr-2"></span>
                <div className="flex items-center gap-1">
                  <P>{companyOnTrial ? "Trial budget" : "Total budget"}</P>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <InformationCircleIcon className="size-4" />
                      </Tooltip.Trigger>
                      <Tooltip.Content side="top">
                        {companyOnTrial
                          ? "Total number of sessions in trial pack."
                          : "Total number of monthly sessions in current session pack."}
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </div>
              {sessionPack?.value != null &&
                rollover.sessionPack !== rollover.totalBudget && (
                  <div className="flex items-center">
                    <span className="w-px h-3 border-r border-dashed border-grey-600 rounded-sm mr-2"></span>
                    <div className="flex items-center gap-1">
                      <P>Monthly pack</P>
                    </div>
                  </div>
                )}
            </div>

            <div className="flex w-full h-12 bg-yellow-200 rounded-lg mt-4 relative">
              {sessionPack?.value != null &&
                rollover.sessionPack !== rollover.totalBudget && (
                  <motion.div
                    className="flex h-full rounded-l-lg absolute border-r border-dashed border-grey-600 z-10"
                    style={{
                      left: `${calcPercentage(rollover.sessionPack, rollover.totalBudget)}%`,
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ damping: 17, type: "spring", delay: 1 }}
                  >
                    {rollover.sessionPack > 0 && (
                      <div className="absolute flex justify-center -left-5 -bottom-7 w-10">
                        <P>{rollover.sessionPack}</P>
                      </div>
                    )}
                  </motion.div>
                )}
              <motion.div
                className="flex h-full bg-yellow-400 rounded-lg absolute"
                initial={{ width: 0 }}
                animate={{
                  width: `${calcPercentage(rollover.currentUsage, rollover.totalBudget) <= 100 ? calcPercentage(rollover.currentUsage, rollover.totalBudget) : 100}%`,
                }}
                transition={{ damping: 17, type: "spring", delay: 0.5 }}
              ></motion.div>
            </div>
            <div className="flex w-full justify-between mt-2">
              <P>0</P>
              <P>
                {rollover.currentUsage} of {rollover.totalBudget}
              </P>
            </div>

            {!companyOnTrial && (
              <div className="flex justify-between gap-9">
                {
                  <>
                    <div className="flex flex-col mt-4 gap-4">
                      <P muted size="xs">
                        Your monthly session pack resets on{" "}
                        {format(
                          startOfMonth(addMonths(new Date(), 1)),
                          "d MMMM yyyy"
                        )}
                        . On a {upcomingSessionPackSize ?? rollover.sessionPack}{" "}
                        session pack, you can roll over a maximum of{" "}
                        {rollover.maxValue} unused sessions every month.
                      </P>
                    </div>
                  </>
                }
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div className="flex w-full items-center ">
              <div className="flex items-center mr-4">
                <span className="w-3 h-3 bg-yellow-200 rounded-sm mr-2"></span>
                <div className="flex items-center gap-1">
                  <P>{companyOnTrial ? "Trial budget" : "Monthly budget"}</P>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <InformationCircleIcon className="size-4" />
                      </Tooltip.Trigger>
                      <Tooltip.Content side="top">
                        {companyOnTrial
                          ? "Total number of sessions in trial pack."
                          : "Total number of monthly sessions in current session pack."}
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </div>

              <div className="flex items-center">
                <span className="w-3 h-3 bg-yellow-400 rounded-sm mr-2"></span>
                <div className="flex items-center gap-1">
                  <P>{companyOnTrial ? "Trial usage" : "Monthly usage"}</P>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <InformationCircleIcon className="size-4" />
                      </Tooltip.Trigger>
                      <Tooltip.Content side="top">
                        Total number of sessions booked so far
                        {companyOnTrial ? "" : " this month"}.
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </div>
            </div>

            <div className="flex w-full h-12 bg-yellow-200 rounded-lg mt-4 relative">
              <motion.div
                className="flex h-full bg-yellow-400 rounded-lg absolute"
                initial={{ width: 0 }}
                animate={{ width: `${chartData.percentage}%` }}
                transition={{ damping: 17, type: "spring", delay: 0.5 }}
              ></motion.div>
            </div>
            <div className="flex w-full justify-end mt-2">
              <P>
                {data?.user?.company?.currentSessionPackUsage} /{" "}
                {data?.user?.company?.featuresAndSettings.sessionPack.value}
              </P>
            </div>

            {!companyOnTrial && (
              <div className="flex justify-between gap-8">
                {upcomingSessionPackSize != null && (
                  <P muted size="xs">
                    <>
                      Your next session pack is {upcomingSessionPackSize}{" "}
                      sessions.
                    </>
                    {hasRollovers && (
                      <>
                        &nbsp;You can roll over a maximum of {rollover.maxValue}{" "}
                        unused sessions every&nbsp;month.
                      </>
                    )}
                  </P>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  if (currentUsageView === "empty") {
    return (
      <div className="flex justify-center text-center">
        <div className="max-w-sm">
          <EmptyState
            title="Set your annual budget"
            description="To see your team's therapy usage, please set your annual company budget"
            action={{
              children: "Set annual budget",
              onClick: () => navigate("/admin/settings/plan-settings"),
            }}
            icon={<ExclamationCircleIcon width={32} />}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full items-center">
        <div className="flex items-center mr-4">
          <span className="w-3 h-3 bg-yellow-200 rounded-sm mr-2"></span>
          <P>Therapy budget</P>
        </div>

        <div className="flex items-center">
          <span className="w-3 h-3 bg-yellow-400 rounded-sm mr-2"></span>
          <P>Therapy used this year</P>
        </div>
      </div>

      <div className="flex w-full h-12 bg-yellow-200 rounded-lg mt-4 relative">
        <motion.div
          className="flex h-full bg-yellow-400 rounded-lg absolute z-0"
          initial={{ width: 0 }}
          animate={{ width: `${chartData.percentage}%` }}
          transition={{ damping: 17, type: "spring", delay: 0.5 }}
        ></motion.div>
      </div>
      <div className="flex w-full justify-end mt-2">
        <P>
          {usage} / {chartData.maximum}
        </P>
      </div>

      {
        <div className="flex justify-between gap-9">
          {props.hidePositiveFeedback === true ? null : (
            <>
              <div className="flex flex-col mt-4 gap-4">
                <P size="xs">
                  Your therapy budget is in line with your team’s therapy needs!
                </P>
              </div>
            </>
          )}
        </div>
      }
    </div>
  )
}
