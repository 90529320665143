/**
 * Allows us to link multiple accounts from a list of Slack users with email addresses.
 * We will need a list of users.
 */

import { FunctionComponent, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Button } from "@spillchat/puddles"

import {
  AccountLinkingCompanyQuery as QueryData,
  AccountLinkingCompanyQueryVariables as QueryVariables,
} from "types/graphql"

import { PlatformUserOrUser } from "./AccountLinking.types"
import {
  UserAccountLinkingModal,
  fragments as UserAccountLinkingModalFragments,
} from "./UserAccountLinkingModal"
import { AccountLinkingTable } from "./AccountLinkingTable"

const queries = {
  getCompanyUsers: gql`
    query AccountLinkingCompany($companyPlatformId: ID!) {
      company {
        platforms(filter: { ids: [$companyPlatformId] }) {
          platformUsers {
            ...UserAccountLinkingModalUsersForPlatformFields
          }
        }
        pendingUsers {
          id
          name
          email
          platform {
            id
          }
        }
        users {
          id
          role
          displayName
          platformUsers {
            name
            email
            platform {
              id
            }
          }
        }
      }
    }
    ${UserAccountLinkingModalFragments.usersForPlatform}
  `,
}

interface AccountLinkingProps {
  onLinkAll?: () => void
}

export const AccountLinking: FunctionComponent<AccountLinkingProps> = ({
  onLinkAll,
}: AccountLinkingProps) => {
  const { platformType } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // The current user being linked. We can use the id to determine if the modal is open.
  const [currentLinkingUser, setCurrentLinkingUser] =
    useState<PlatformUserOrUser | null>(null)
  // We're gonna track the users that have been linked via their id => login
  const [linkedUsers, setLinkedUsers] = useState<Record<string, string>>({})
  const [dontLinkUsers, setDontLinkUsers] = useState<string[]>([])

  const companyPlatformId = searchParams.get("companyPlatformId")

  const { data, loading } = useQuery<QueryData, QueryVariables>(
    queries.getCompanyUsers,
    {
      fetchPolicy: "cache-first",
      variables: { companyPlatformId: companyPlatformId! },
      skip: companyPlatformId == null,
    }
  )

  // Page accessed incorrectly, or loading
  if (
    companyPlatformId == null ||
    platformType == null ||
    loading ||
    data == null
  ) {
    return null
  }

  // No users to merge, navigate away
  if (data?.company?.users.length === 0) {
    navigate(
      `/admin/settings/access/install/${platformType}/confirmation?companyPlatformId=${companyPlatformId}`
    )
    return
  }

  const allOtherUsers = [
    ...(data?.company?.users.map(user => ({
      ...user,
      emails: [
        ...new Set(
          user.platformUsers.flatMap(pu => (pu.email != null ? [pu.email] : []))
        ),
      ],
      autoMerged: user.platformUsers.find(
        ({ platform }) => platform?.id == companyPlatformId
      )?.name,
    })) ?? []),
    ...(data?.company?.pendingUsers
      .filter(pu => pu.platform?.id != companyPlatformId)
      .map(pu => ({
        platformUserId: pu.id,
        displayName: pu.name,
        emails: pu.email != null ? [pu.email] : [],
        platform: pu.platform,
      })) ?? []),
  ]

  const tableData = allOtherUsers.map(user => ({
    ...user,
    manuallyLinked: data.company.platforms[0]?.platformUsers.find(
      pu => pu.id == linkedUsers["id" in user ? user.id : user.platformUserId]
    )?.name,
    dontLink: dontLinkUsers.includes(
      "id" in user ? user.id : user.platformUserId
    ),
  }))

  return (
    <>
      <AccountLinkingTable
        data={tableData}
        newPlatformType={platformType}
        onLinkAccount={user => setCurrentLinkingUser(user)}
        onDontLinkAccount={user =>
          setDontLinkUsers([
            ...dontLinkUsers,
            "id" in user ? user.id : user.platformUserId,
          ])
        }
      />

      <Button onClick={() => onLinkAll?.()}>Merge users</Button>

      {companyPlatformId !== null && currentLinkingUser !== null && (
        <UserAccountLinkingModal
          pendingUsers={
            data?.company.platforms[0]?.platformUsers.filter(
              pu =>
                pu.user?.id == null &&
                !Object.values(linkedUsers).includes(pu.id)
            ) ?? []
          }
          existingUser={currentLinkingUser}
          isVisible={currentLinkingUser !== null}
          onClose={() => setCurrentLinkingUser(null)}
          onSuccessfulLink={(userId, platformUserId) =>
            setLinkedUsers({ ...linkedUsers, [userId]: platformUserId })
          }
          onDontLink={id => setDontLinkUsers([...dontLinkUsers, id])}
          newPlatformType={platformType}
        />
      )}
    </>
  )
}
