import { gql } from "@apollo/client"

export const queries = {
  getPlatformUsers: gql(`
    query RestrictUsersPlatformUsers($companyPlatformId: ID!) {
      company {
        platforms(filter: { ids: [$companyPlatformId] }) {
          platformUsers {
            id
            name
            email
            state
          }
        }
      }
    }
  `),
}
