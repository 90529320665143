import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react"
import { useQuery } from "@apollo/client"
import { Navigate, Route, Routes } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import {
  AdminTherapyIndexGetUsageQuery,
  AdminTherapyIndexGetUsageQueryVariables,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { UserInvitesList } from "features/admin/components/PeopleList/UserInvitesList"
import { UserInvitesEmpty } from "features/admin/components/PeopleList/UserInvitesEmpty"
import { useAdminOnboarding } from "common/hooks/useAdminOnboarding"
import { queries } from "features/admin-therapy/admin-therapy.queries"
import { AdminTherapyNeeds } from "features/admin-therapy-needs/admin-therapy-needs.page"
import { AdminSettingsNavigation } from "features/admin-therapy/components/AdminSettingsNavigation"
import { Access } from "features/access/access.page"
import { AdminTherapySpecialisedSupportCopay } from "features/admin-therapy-specialised-support-edit/co-pay/admin-therapy-specialised-support-copay.page"
import { InviteAdhd } from "features/invite-adhd/invite-adhd.page"
import { InviteEmail } from "features/invite-email/invite-email.page"
import { InviteSlack } from "features/invite-slack/invite-slack.page"

import { Billing } from "../Billing"
import { InvitePeople } from "../InvitePeople"
import { PlatformSetup } from "../PlatformSetup"

import { AccountSettings } from "./plan-settings"

interface SettingsContextData {
  currentSessionCoverage: number
  setCurrentSessionCoverage: Dispatch<SetStateAction<number>>
  estimatedMonthlyCost: string
  setEstimatedMonthlyCost: Dispatch<SetStateAction<string>>
  individualUnitPrice: string
  setIndividualUnitPrice: Dispatch<SetStateAction<string>>
}

const SettingsContext = createContext<SettingsContextData>({
  currentSessionCoverage: 0,
  estimatedMonthlyCost: "",
  individualUnitPrice: "",
  setCurrentSessionCoverage: () => {},
  setEstimatedMonthlyCost: () => {},
  setIndividualUnitPrice: () => {},
})

export const useTherapyContext = (): SettingsContextData =>
  useContext(SettingsContext)

export const Settings: FunctionComponent = () => {
  const [currentSessionCoverage, setCurrentSessionCoverage] = useState(0)
  const [estimatedMonthlyCost, setEstimatedMonthlyCost] = useState("")
  const [individualUnitPrice, setIndividualUnitPrice] = useState("")

  const { data, loading } = useQuery<
    AdminTherapyIndexGetUsageQuery,
    AdminTherapyIndexGetUsageQueryVariables
  >(queries.getUsage)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan

  const hasEmailInvite =
    subscriptionPlan != null
      ? [SpillSubscriptionPlan.TEAM, SpillSubscriptionPlan.ESSENTIAL].includes(
          subscriptionPlan
        )
      : false

  const { helpers } = useAdminOnboarding({ subscriptionPlan })

  const canPreApproveTherapy =
    data?.user?.company?.featuresAndSettings.preApproveTherapy.value ?? false

  const hasInviteFlow =
    data?.user?.company?.featuresAndSettings?.preApproveTherapy?.value === true

  const hasAdhdSupport =
    data?.user?.company?.featuresAndSettings?.adhdSupport?.active === true

  const sessionPackFeature =
    data?.user?.company?.featuresAndSettings?.sessionPack.active ?? false
  const sessionPackValue =
    data?.user?.company?.featuresAndSettings?.sessionPack.value
  const hasZeroSessionPack =
    sessionPackFeature && sessionPackValue != null && sessionPackValue == 0

  const checkInviteAndIntegration = () => {
    if (subscriptionPlan == null) {
      return false
    }

    return (
      helpers?.hasInvitedUsers === true || helpers?.hasAddedIntegration === true
    )
  }

  const hasInvitedOrIntegrated = checkInviteAndIntegration()

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  const tabLinks = [
    { label: "Plan Settings", path: "plan-settings", visible: true },
    {
      label: "Support",
      path: "support",
      visible: canPreApproveTherapy === true,
    },
    { label: "Access", path: "access", visible: true },
    {
      label: "Budget",
      path: "needs",
      visible: hasZeroSessionPack === false,
    },
    { label: "Billing", path: "billing", visible: true },
  ]

  const TabContainer = ({
    children,
    condition,
  }: {
    children: ReactNode
    condition: boolean
  }) => {
    return (
      <>
        {condition ? (
          <>{children}</>
        ) : (
          <>
            <div className="flex justify-center items-center py-12">
              {subscriptionPlan != null && (
                <UserInvitesEmpty {...{ subscriptionPlan }} />
              )}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Helmet title="Settings | Spill" />
      <SettingsContext.Provider
        value={{
          currentSessionCoverage,
          setCurrentSessionCoverage,
          estimatedMonthlyCost,
          setEstimatedMonthlyCost,
          individualUnitPrice,
          setIndividualUnitPrice,
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={canPreApproveTherapy === true ? "support" : "plan-settings"}
              />
            }
          />

          <Route path="/access/invite/">
            {hasInviteFlow && <Route index element={<InvitePeople />} />}
            {hasEmailInvite && (
              <>
                <Route path="email" element={<InviteEmail />} />
                <Route path="slack" element={<InviteSlack />} />
              </>
            )}
            {hasAdhdSupport && (
              <>
                <Route path="adhd" element={<InviteAdhd />} />
                <Route
                  path="adhd/co-pay"
                  element={<AdminTherapySpecialisedSupportCopay />}
                />
              </>
            )}
          </Route>

          {subscriptionPlan !== SpillSubscriptionPlan.STARTER && (
            <Route path="access/install/*" element={<PlatformSetup />} />
          )}

          <Route
            path="/support/*"
            element={
              <>
                <Helmet title="Support | Spill" />
                <AdminSettingsNavigation {...{ tabLinks }} />
                <TabContainer condition={hasInvitedOrIntegrated}>
                  <div className="flex flex-col sm:flex-row gap-9 mt-8">
                    <div className="overflow-y-auto w-full">
                      <UserInvitesList />
                    </div>
                  </div>
                </TabContainer>
              </>
            }
          />

          <Route
            path="/needs/*"
            element={
              <>
                <Helmet title="Needs | Spill" />
                <AdminSettingsNavigation {...{ tabLinks }} />
                <AdminTherapyNeeds />
              </>
            }
          />
          <Route
            path="/plan-settings/*"
            element={
              <>
                <Helmet title="Settings | Spill" />
                <AdminSettingsNavigation {...{ tabLinks }} />
                <AccountSettings />
              </>
            }
          />
          <Route
            path="/access/*"
            element={
              <>
                <Helmet title="Access | Spill" />
                <AdminSettingsNavigation {...{ tabLinks }} />
                <Access />
              </>
            }
          />
          <Route
            path="/billing/*"
            element={
              <>
                <Helmet title="Billing | Spill" />
                <AdminSettingsNavigation {...{ tabLinks }} />
                <Billing />
              </>
            }
          />
        </Routes>
      </SettingsContext.Provider>
    </>
  )
}
