import { gql } from "@apollo/client"

export const PACKAGE_FIELDS = gql`
  fragment PackageFields on User {
    id
    featuresAndSettings {
      adhdSupport {
        value
      }
      customSupport {
        value
      }
    }
    therapyPackages {
      id
      packageType
      expirationDate
      numberSessionsUsed
      numberSessionsGiven
      companyPackageSetting {
        id
      }
    }
    company {
      adhdCompanyPackageSetting {
        id
        state
      }
    }
  }
`
