/**
 * A subroute we can find under the dashboard page.
 * Allows the admin to instigate the onboarding of users, and how many users
 * have accepted their invite etc.
 */

import { gql, useQuery } from "@apollo/client"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import {
  Alert,
  Button,
  H1,
  H2,
  H3,
  H4,
  P,
  Tabs,
  Tooltip,
} from "@spillchat/puddles"
import { compareAsc, format } from "date-fns"
import { FunctionComponent, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useLocation, useNavigate } from "react-router-dom"

import companyPublicReviewWidget from "common/assets/images/company-public-review-widget.svg"
import happyLaptop from "common/assets/images/product/happy-laptop.jpg"
import usingDesktop from "common/assets/images/product/using-desktop.jpg"
import { ChartBarStacked } from "common/components/ChartBarStacked"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { FeedbackTable } from "features/admin/components/FeedbackTable"
import {
  CompanyFeedbackAggregates,
  PlatformType,
  TeamEngagementGetCompanyQuery as QueryData,
  TeamEngagementGetCompanyQueryVariables as QueryVars,
  SpillSubscriptionPlan,
} from "types/graphql"

export const fragments = {
  queryFields: gql`
    fragment TeamEngagementQueryFields on Query {
      user {
        id
        company {
          id
          subscriptionPlan
          trialStartDate
          billingAdvice
          feedback {
            numberOfResponses
            aggregates {
              wouldRecommend
              metTheirGoals
              wouldSeeAgain
            }
            sharedFeedback {
              month
              feedbackText
              userName
              therapyType
            }
          }
          featuresAndSettings {
            pulse {
              active
            }
          }
          allUsage {
            askATherapistQuestions
            consultations
            courseSessions
            oneOffs
            distinctUserPulseCheckins
            pulseTriages
            month
            plusOnes
            pmiSessions
            specialisedSupportSessions
          }
          platforms {
            id
            externalId
            hasAnnouncedOnboarding
            platformType
          }
        }
      }
    }
  `,
}

export const queries = {
  getCompany: gql`
    query TeamEngagementGetCompany {
      ...TeamEngagementQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const TeamEngagement: FunctionComponent = () => {
  const { data, loading: companyDataLoading } = useQuery<QueryData, QueryVars>(
    queries.getCompany,
    { fetchPolicy: "cache-first" }
  )

  type TabValues = "usage" | "feedback"
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState<TabValues>("usage")

  useEffect(() => {
    const tab = location.pathname.split("/").pop()
    if (tab != null) {
      const newTab = ["usage", "feedback"].includes(tab ?? "") ? tab : "usage"
      setSelectedTab(newTab as TabValues)
    }
  }, [location.pathname])

  if (companyDataLoading) {
    return <LoadingSpinner sizeInPixels={50} />
  }

  const companyPlatform = data?.user?.company?.platforms?.find(
    ({ platformType }) =>
      [PlatformType.SLACK, PlatformType.TEAMS].includes(platformType)
  )

  const targetCompanyPlatform =
    companyPlatform ?? data?.user?.company?.platforms[0]

  const subscriptionPlan = data?.user?.company?.subscriptionPlan
  const isStarterPlan = subscriptionPlan === SpillSubscriptionPlan.STARTER
  const inviteFlowUrl = isStarterPlan
    ? "/admin/settings/access/invite"
    : "/admin/settings/access"
  const isPulseEnabled = data?.user?.company?.featuresAndSettings.pulse.active

  if (!targetCompanyPlatform) {
    return (
      <>
        <div className="flex justify-center items-center py-12">
          <div className="max-w-xl text-center">
            <div className="flex flex-col items-center justify-center gap-8">
              <div className="flex -space-x-8">
                <img
                  src={usingDesktop}
                  alt="A person using a desktop"
                  className="rounded-lg w-48 h-48 aspect-square object-cover"
                />
                <img
                  src={happyLaptop}
                  alt="A happy person using a laptop"
                  className="rounded-lg w-48 h-48 aspect-square object-cover mt-12"
                />
              </div>
              <div className="flex flex-col items-center gap-4">
                <H2>Give everyone access to&nbsp;support</H2>
                <P>
                  Give everyone on your team access to professional support.
                  Adding your team will allow them to book next-day sessions
                  with Spill therapists either by requesting sessions from the
                  company or paying&nbsp;privately.
                </P>
                <div className="flex items-center gap-2">
                  <Button asChild variant="primary">
                    <Link to={inviteFlowUrl}>Add teammates</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  // Don't default to 0, because we divide by this. The admin is definitely logged in, soooo?
  const usageData = data?.user?.company?.allUsage ?? []

  /**
   * Formats the usage data for use within our graph.
   * @param displayAll Whether to display all the data or just the last 12 months
   */

  const formattedUsageData = usageData
    .slice()
    .sort((a, b) => compareAsc(a.month, b.month))
    .map(data => ({
      ...data,
      therapySessions: data.courseSessions,
      courseKickOffs: data.consultations,
      singleSessions: data.oneOffs,
      plusOnes: data.plusOnes,
      date: new Date(data.month),
      month: format(new Date(data.month), "MMMM yyyy"),
      formattedDate: format(new Date(data.month), "MMM"),
    }))

  const filteredCheckInData = formattedUsageData.filter(data => {
    return data.pulseTriages || data.distinctUserPulseCheckins
  })

  // Renders the initial personal checkins graph
  const renderPersonalCheckins = () => {
    if (filteredCheckInData.length > 0) {
      return (
        <div className="flex flex-col items-center md:w-2/3 min-h-[320px]">
          <ChartBarStacked
            stacked={false}
            series={[
              {
                name: "Employees surveyed",
                color: "#0C3CAD",
                data: formattedUsageData
                  .map(data => {
                    return {
                      x: format(data.date ?? "", "MM/dd/yyyy"),
                      y: data.distinctUserPulseCheckins,
                    }
                  })
                  .filter(data => data.y !== 0),
              },
              {
                name: "Clinician outreach messages",
                color: "#0C1A66",
                data: formattedUsageData
                  .map(data => {
                    return {
                      x: format(data.date ?? "", "MM/dd/yyyy"),
                      y: data.pulseTriages,
                    }
                  })
                  .filter(data => data.y !== 0),
              },
            ]}
          />
        </div>
      )
    }
    return (
      <div className="flex flex-col items-center md:w-2/3 p-4 min-h-[320px]">
        <div className="flex justify-center h-full items-center text-center max-w-sm">
          <div className="flex flex-col justify-center items-center gap-4">
            <div className="flex flex-col gap-2">
              <H4>Set up a Team Check-in today</H4>
              <P>Help Spill spot and support struggling employees</P>
            </div>
            <Button>
              <Link to="/admin/dashboard/checkins/meetings">Get started</Link>
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const feedback = data?.user?.company?.feedback
  const aggregates = feedback?.aggregates
  const insufficientFeedback =
    feedback?.numberOfResponses !== undefined
      ? feedback?.numberOfResponses < 3
      : true
  const isDefined = (value: CompanyFeedbackAggregates["metTheirGoals"]) => {
    if (value === null || value === undefined) {
      return false
    }

    return true
  }

  const filteredTherapyData = formattedUsageData.filter(data => {
    return (
      data.courseKickOffs ||
      data.therapySessions ||
      data.singleSessions ||
      data.askATherapistQuestions
    )
  })

  const hasTrial = data?.user?.company?.trialStartDate !== null

  return (
    <>
      <Helmet title="Engagement | Spill" />
      <H1>Engagement Overview</H1>
      <Tabs.Root defaultValue={selectedTab} value={selectedTab}>
        <Tabs.List className="my-8">
          <Tabs.Trigger value="usage" onClick={() => navigate("usage")}>
            Usage
          </Tabs.Trigger>
          <Tabs.Trigger value="feedback" onClick={() => navigate("feedback")}>
            Feedback
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="usage">
          <H4 sectionHeader>Therapy usage</H4>
          <div className="flex flex-col md:flex-row w-full gap-2 my-4">
            <div className="flex flex-col items-center md:w-2/3 min-h-[320px]">
              {filteredTherapyData.length > 0 ? (
                <>
                  <ChartBarStacked
                    series={[
                      {
                        name: "Course consultation",
                        color: "#0C3CAD",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.courseKickOffs,
                          }
                        }),
                      },
                      {
                        name: "Course session",
                        color: "#0C1A66",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.therapySessions,
                          }
                        }),
                      },
                      {
                        name: "One-off session",
                        color: "#35D0BA",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.singleSessions,
                          }
                        }),
                      },
                      {
                        name: "Ask a Therapist",
                        color: "#08B89F",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.askATherapistQuestions,
                          }
                        }),
                      },
                      {
                        name: "Plus one session",
                        color: "#F8CF60",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.plusOnes,
                          }
                        }),
                      },

                      {
                        name: "PMI session",
                        color: "#EFB108",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.pmiSessions,
                          }
                        }),
                      },

                      {
                        name: "Specialised support session",
                        color: "#FB7466",
                        data: formattedUsageData.map(data => {
                          return {
                            x: format(data.date, "MM/dd/yyyy"),
                            y: data.specialisedSupportSessions,
                          }
                        }),
                      },
                    ]}
                  />
                </>
              ) : (
                <div className="flex justify-center h-full items-center text-center max-w-sm">
                  <P>
                    Here you&apos;ll be able to see how much therapy your team
                    is using with Spill
                  </P>
                </div>
              )}
            </div>
            <div className="flex flex-col md:w-1/3 gap-8 py-6">
              {data?.user?.company?.billingAdvice === true && (
                <div className="bg-yellow-200 rounded-md p-4 h-full flex flex-col justify-between gap-4">
                  <div className="flex flex-col gap-2">
                    <H4>Change in therapy needs</H4>
                    <P>
                      We&apos;ve noticed your team is using less therapy than
                      expected - you may be able to move to a better value plan
                    </P>
                  </div>
                  <Button>
                    <Link to="/admin/settings/needs">
                      See cheaper plan options
                    </Link>
                  </Button>
                </div>
              )}
            </div>
          </div>
          {isPulseEnabled === true && (
            <>
              <div className="flex flex-col gap-9">
                <H4 sectionHeader>Personal check-ins</H4>
              </div>
              <div className="flex flex-col md:flex-row w-full gap-2">
                {renderPersonalCheckins()}
              </div>
            </>
          )}
        </Tabs.Content>
        <Tabs.Content value="feedback">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-8">
              <div className="flex justify-between">
                <H4>Overall scores</H4>
                <P muted size="xs">
                  Total responses: {feedback?.numberOfResponses ?? "-"}{" "}
                  {insufficientFeedback && (
                    <>(minimum of 3 needed for aggregates)</>
                  )}
                </P>
              </div>
              <div className="grid gap-4 lg:grid-cols-3">
                <div className="flex flex-col">
                  <div className="flex gap-2 items-baseline">
                    <H1>{aggregates?.wouldRecommend?.toFixed(1) ?? "-"}</H1>
                    {isDefined(aggregates?.wouldRecommend) && (
                      <P size="xs" muted>
                        / 10.0
                      </P>
                    )}
                  </div>
                  <P>Would recommend Spill</P>
                </div>
                <div className="flex flex-col">
                  <div className="flex gap-2 items-baseline">
                    <H1>{aggregates?.metTheirGoals?.toFixed(1) ?? "-"}</H1>
                    {isDefined(aggregates?.metTheirGoals) && (
                      <P size="xs" muted>
                        / 10.0
                      </P>
                    )}
                  </div>
                  <P>Felt the session met their goals</P>
                </div>
                <div className="flex flex-col">
                  <H1>
                    {isDefined(aggregates?.wouldSeeAgain)
                      ? `${Number((aggregates?.wouldSeeAgain ?? 0) * 100).toFixed(1)}%`
                      : "-"}
                  </H1>
                  <P>Would see their counsellor again</P>
                </div>
              </div>
              <Alert title="What happens if a session doesn't meet somebody's&nbsp;goals?">
                <P size="xs">
                  Spill's clinical team reaches out to any employee who has a
                  session on Spill and rates it less than 8/10 to support them
                  in finding a counsellor who might better suit their needs.
                </P>
              </Alert>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <H4>Comments shared with you</H4>
                <div className="flex gap-1 items-center">
                  <P muted size="xs">
                    All feedback shared is opt-in{" "}
                  </P>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger>
                        <InformationCircleIcon className="size-4 text-grey-600" />
                      </Tooltip.Trigger>
                      <Tooltip.Content title="Explicit permission">
                        Spill gets explicit permission from employees to share
                        feedback with you.
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </div>
              <FeedbackTable data={feedback?.sharedFeedback ?? []} />
              {!hasTrial && (
                <div className="py-10 grid grid-cols-2 gap-10">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-3">
                      <H3>Register your interest</H3>
                      <P>
                        Get early access to our live embeddable widget for your
                        company careers page with thanks left by employees for
                        having access to Spill.
                      </P>
                    </div>
                    <Link
                      to="https://spillchat.typeform.com/to/h8tXTqev"
                      target="_blank"
                    >
                      <Button>Register my interest</Button>
                    </Link>
                  </div>
                  <div className="flex justify-center align-center">
                    <img
                      src={companyPublicReviewWidget}
                      alt="Spill's company public review widget"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </>
  )
}
