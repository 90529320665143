import { FunctionComponent, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { ModalFullScreenInner } from "@spillchat/puddles"
import { useMutation } from "@apollo/client"

import { useAnalytics } from "common/context/analyticsContext"
import {
  Pmi,
  PmiRequestFormCreatePmiRequestMutation,
  PmiRequestFormCreatePmiRequestMutationVariables,
} from "types/graphql"

import { PmiRequestFormDetails } from "./screens/PmiRequestFormDetails"
import { PmiRequestFormConfirmation } from "./screens/PmiRequestFormConfirmation"
import { PmiRequest } from "./PmiRequestForm.schema"
import { mutations } from "./PmiRequestForm.mutations"

interface PmiSetupFormProps {
  setOpen: (newIsOpen: boolean) => void
  pmiDetails: Pmi
}

export const PmiRequestForm: FunctionComponent<PmiSetupFormProps> = ({
  setOpen,
  pmiDetails,
}) => {
  const { track } = useAnalytics()

  const [createPmiRequest] = useMutation<
    PmiRequestFormCreatePmiRequestMutation,
    PmiRequestFormCreatePmiRequestMutationVariables
  >(mutations.createPmiRequest)

  const [formComplete, setFormComplete] = useState(false)

  const handleSubmit = async (values: PmiRequest) => {
    track("PMI request form submitted")
    await createPmiRequest({
      variables: {
        provider: pmiDetails.provider,
        accountNumber: values.accountNumber || undefined,
        numberOfSessions:
          typeof values.sessionsRequested === "number"
            ? values.sessionsRequested
            : undefined,
      },
    })
    setFormComplete(true)
  }

  useEffect(() => {
    document.documentElement.style.overflow = "hidden"
    return () => {
      document.documentElement.style.overflow = ""
    }
  }, [])

  return (
    <>
      <Helmet title="Request insurance follow on | Spill" />
      <ModalFullScreenInner
        title="Request insurance follow on"
        onClose={() => setOpen(false)}
      >
        <div className="flex justify-center pt-12 px-6 pb-6 overflow-auto grow">
          <div className="flex flex-col gap-4 max-w-lg mx-auto w-full">
            {formComplete ? (
              <PmiRequestFormConfirmation />
            ) : (
              <PmiRequestFormDetails
                accessInstructions={pmiDetails.userInstructions}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
