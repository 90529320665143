import {
  StarIcon,
  BriefcaseIcon,
  SunIcon,
  MoonIcon,
  FireIcon,
  FlagIcon,
  LifebuoyIcon,
  WalletIcon,
  UsersIcon,
  SwatchIcon,
  RocketLaunchIcon,
  BugAntIcon,
  SpeakerWaveIcon,
  ClockIcon,
  UserIcon,
  BookOpenIcon,
  LightBulbIcon,
  BeakerIcon,
  HomeIcon,
  BuildingOfficeIcon,
  FaceSmileIcon,
  ScaleIcon,
  AdjustmentsHorizontalIcon,
  ComputerDesktopIcon,
  UserPlusIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline"

import { HeroIcon } from "./heroIcons"

export type HeroIconName =
  | "Star"
  | "Swatch"
  | "Rocket"
  | "Briefcase"
  | "Bug"
  | "Speaker"
  | "Sun"
  | "Moon"
  | "Clock"
  | "User"
  | "Fire"
  | "Book"
  | "Light"
  | "Beaker"
  | "Home"
  | "Building"
  | "Smile"
  | "Scale"
  | "Flag"
  | "Lifebuoy"
  | "Wallet"
  | "Users"
  | "Adjustments"
  | "Computer"
  | "UserGroup"
  | "UserPlus"
  | "LightBulb"

type IconListItem = {
  icon: HeroIcon
  displayName: HeroIconName
}

type IconList = IconListItem[]

export const iconList: IconList = [
  { icon: StarIcon, displayName: "Star" },
  { icon: SwatchIcon, displayName: "Swatch" },
  { icon: RocketLaunchIcon, displayName: "Rocket" },
  { icon: BriefcaseIcon, displayName: "Briefcase" },
  { icon: BugAntIcon, displayName: "Bug" },
  { icon: SpeakerWaveIcon, displayName: "Speaker" },
  { icon: SunIcon, displayName: "Sun" },
  { icon: MoonIcon, displayName: "Moon" },
  { icon: ClockIcon, displayName: "Clock" },
  { icon: UserIcon, displayName: "User" },
  { icon: FireIcon, displayName: "Fire" },
  { icon: BookOpenIcon, displayName: "Book" },
  { icon: LightBulbIcon, displayName: "Light" },
  { icon: BeakerIcon, displayName: "Beaker" },
  { icon: HomeIcon, displayName: "Home" },
  { icon: BuildingOfficeIcon, displayName: "Building" },
  { icon: FaceSmileIcon, displayName: "Smile" },
  { icon: ScaleIcon, displayName: "Scale" },
  { icon: FlagIcon, displayName: "Flag" },
  { icon: LifebuoyIcon, displayName: "Lifebuoy" },
  { icon: WalletIcon, displayName: "Wallet" },
  { icon: UsersIcon, displayName: "Users" },
  { icon: AdjustmentsHorizontalIcon, displayName: "Adjustments" },
  { icon: ComputerDesktopIcon, displayName: "Computer" },
  { icon: UserGroupIcon, displayName: "UserGroup" },
  { icon: UserPlusIcon, displayName: "UserPlus" },
  { icon: LightBulbIcon, displayName: "LightBulb" },
]

export const getIconByDisplayName = (
  displayName: HeroIconName
): IconListItem | undefined => {
  return iconList.find(icon => icon.displayName === displayName)
}
