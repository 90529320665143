import { isValid, parse } from "date-fns"
import { z } from "zod"

export const formSchema = z.object({
  isAnonymous: z.boolean(),
  noteFromUser: z.string().max(250, {
    message: "Please limit your note to 250 characters",
  }),
  email: z.string().email().min(1, {
    message: "Please provide an email address",
  }),
  dateOfBirth: z
    .object({
      day: z.string(),
      month: z.string(),
      year: z.string(),
    })
    .refine(
      value => {
        if (
          value.day == undefined ||
          value.month == undefined ||
          value.year === undefined
        ) {
          return false
        }
        const day = value.day.padStart(2, "0")
        const parsedDate = parse(
          `${day}${value.month}${value.year}`,
          "ddMMyyyy",
          new Date()
        )
        return isValid(parsedDate)
      },
      {
        message: "Please enter a valid date of birth",
      }
    ),
  phoneNumber: z.string().min(1, {
    message: "Please provide a phone number",
  }),
  phoneCountryCode: z.string().min(1, {
    message: "Please provide a phone country code",
  }),
})

export type TherapyAdhdCopayRequestSchema = z.infer<typeof formSchema>
