import { gql } from "@apollo/client"

export const mutations = {
  cancelRequest: gql(`
    mutation TherapySpecialisedSupportPackageCancel(
      $id: ID!
      $status: PackageRequestStatus!
    ) {
      respondToPackageRequest(packageRequestId: $id, status: $status) {
        id
        status
      }
  }
  `),
}
