import { Button, Form, H2, P, SelectableCard } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyAdhdCopayRequestSchema } from "../therapy-adhd-copay-request.schema"

type TherapyAdhdCopayRequestAnonymityProps = {
  onNext: () => void
}

export const TherapyAdhdCopayRequestAnonymity: FunctionComponent<
  TherapyAdhdCopayRequestAnonymityProps
> = ({ onNext }: TherapyAdhdCopayRequestAnonymityProps) => {
  const context = Form.useFormContext<TherapyAdhdCopayRequestSchema>()
  const loading = context.formState.isSubmitting

  const validate = async () => {
    const valid = await context.trigger("isAnonymous")

    if (valid) {
      onNext()
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <H2>Would you like to send your request anonymously?</H2>
        <P muted>
          You can choose to include your name in your request, or send it
          anonymously.
        </P>
      </div>
      <div className="flex flex-col gap-2">
        <div>
          <Form.Field
            control={context.control}
            name="isAnonymous"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <div className="grid lg:grid-cols-2 gap-4">
                    {[
                      {
                        title: "Include my name",
                        subtitle: "Admin will know who you are",
                        value: false,
                      },
                      {
                        title: "Anonymously",
                        subtitle: "Admin won't know who you are",
                        value: true,
                      },
                    ].map(item => {
                      return (
                        <SelectableCard
                          key={item.title}
                          size="sm"
                          checked={field.value === item.value}
                          title={item.title}
                          subtitle={item.subtitle}
                          type="radio"
                          {...context.register("isAnonymous")}
                          value={item.value ? 1 : 0}
                          onClick={() => {
                            context.setValue("isAnonymous", item.value)
                          }}
                        />
                      )
                    })}
                  </div>
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
        </div>
      </div>
      <Button
        onClick={async () => await validate()}
        {...{ loading }}
        variant="primary"
      >
        Next
      </Button>
    </div>
  )
}
