import { z } from "zod"

export const formSchema = z
  .object({
    userFacingDescription: z.string().min(1, {
      message: "Please enter a description",
    }),
    numberSessions: z
      .number({ message: "Must select a session count" })
      .max(100, { message: "You cannot have more than of 100 sessions" }),
    minSessions: z.number().optional(),
    numberMonthsToUse: z.number().nullable().optional().default(null),
    forAllEmployees: z.boolean(),
    visibleToAllEmployees: z.boolean(),
    icon: z.string().min(1, {
      message: "Please choose an icon",
    }),
    name: z.string().min(1, {
      message: "Please choose a name",
    }),
  })
  .superRefine((data, ctx) => {
    const minSessions = data.minSessions ?? 2
    if (data.numberSessions < minSessions) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: minSessions,
        type: "number",
        inclusive: true,
        message: `Must have a minimum of ${minSessions} sessions`,
        path: ["numberSessions"],
      })
    }
  })

export type AdminTherapySpecialisedSupportEditForm = z.infer<typeof formSchema>
