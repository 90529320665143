import { gql } from "@apollo/client"
import { USER_LIST_FIELDS } from "graphql/fragments/user-list.fragment"

export const queries = {
  getUserList: gql`
    query AdminTherapySpecialisedSupportUserList {
      user {
        ...UserListFields
        featuresAndSettings {
          userTherapyCap {
            active
            value
          }
        }
      }
    }
    ${USER_LIST_FIELDS}
  `,
  getPackage: gql`
    query AdminTherapySpecialisedSupportPackage($id: ID!) {
      getCompanyPackageSettingById(companyPackageSettingId: $id) {
        id
        name
        userFacingDescription
        icon
        packageType
        numberSessions
        numberMonthsToUse
      }
    }
  `,
}
