import { useMutation } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, H3, InputStepper, Label, P } from "@spillchat/puddles"
import { FormFieldWrapper } from "components/form/FormFieldWrapper"
import { FunctionComponent } from "react"
import { z } from "zod"

import {
  CreateOrUpdateCoPayMutation,
  CreateOrUpdateCoPayMutationVariables,
} from "types/graphql"
import { mutations } from "features/admin-therapy-specialised-support-create/admin-specialised-support-create.mutations"
import { formatCurrency } from "common/helpers/formatNumber"

export const formSchema = z.object({
  enabled: z.boolean(),
  companyContribution: z
    .number({ message: "Must select an amount" })
    .min(0, { message: "Must be a minimum of £0" })
    .max(995, { message: "Cannot exceed total of £995" }),
})

export type AdminTherapySpecialisedSupportCopayFormSchema = z.infer<
  typeof formSchema
>

type AdminTherapySpecialisedSupportCopayFormProps = {
  defaultValues: AdminTherapySpecialisedSupportCopayFormSchema
}

export const AdminTherapySpecialisedSupportCopayForm: FunctionComponent<
  AdminTherapySpecialisedSupportCopayFormProps
> = (props: AdminTherapySpecialisedSupportCopayFormProps) => {
  const form = Form.useForm<AdminTherapySpecialisedSupportCopayFormSchema>({
    reValidateMode: "onChange",
    resolver: zodResolver(formSchema),
    values: { ...props.defaultValues },
  })

  const [updateCoPay] = useMutation<
    CreateOrUpdateCoPayMutation,
    CreateOrUpdateCoPayMutationVariables
  >(mutations.updateAdhdCoPayAddon, {
    refetchQueries: ["InviteAdhdSettings"],
  })

  const onSubmit = async (
    values: AdminTherapySpecialisedSupportCopayFormSchema
  ) => {
    if (values.companyContribution != null) {
      await updateCoPay({
        variables: {
          enabled: values.enabled,
          companyContribution: values.companyContribution,
        },
      })
    }
  }

  return (
    <Form.Root {...form}>
      <div className="flex flex-col gap-2">
        <H3>Payment amounts</H3>
        <FormFieldWrapper<AdminTherapySpecialisedSupportCopayFormSchema>
          keepHeadingSize={true}
          headingComponent={Label}
          label="Contribution per person"
          onSubmit={form.handleSubmit(onSubmit)}
          displayValue={formatCurrency(form.getValues("companyContribution"))}
          values={["companyContribution"]}
        >
          <div className="flex flex-col gap-2">
            <Form.Field
              control={form.control}
              name="companyContribution"
              render={({ field }) => (
                <Form.Item className="flex flex-col">
                  <Form.Control>
                    <InputStepper
                      step="50"
                      max={600}
                      min={0}
                      value={field.value.toString()}
                      onStepperChange={value => {
                        const newValue = parseInt(value === "" ? "0" : value)
                        form.setValue("companyContribution", newValue, {
                          shouldDirty: true,
                        })
                      }}
                    />
                  </Form.Control>
                  <Form.Message className="!mt-0" />
                  <P size="xs" muted>
                    An assessment on Spill is £900
                  </P>
                </Form.Item>
              )}
            />
          </div>
        </FormFieldWrapper>
      </div>
    </Form.Root>
  )
}
