import { FunctionComponent } from "react"
import { motion } from "framer-motion"
import { P, EmptyState } from "@spillchat/puddles"
import { useQuery } from "@apollo/client"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

import {
  GetCompanyUsageGoalDataQuery as UsageGoalQueryData,
  GetCompanyUsageGoalDataQueryVariables as UsageGoalQueryVars,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

import { queries } from "../admin-engagement.queries"

interface UsageGoalProps {
  peopleSupported: number
  annualGoal: number
  annualGoalPercentage: number
  endDate?: string
}

export const UsageGoal: FunctionComponent<UsageGoalProps> = props => {
  const { data: usageGoalData, loading: companyUsageGoalLoading } = useQuery<
    UsageGoalQueryData,
    UsageGoalQueryVars
  >(
    queries.getUsageGoalData,

    {
      fetchPolicy: "cache-first",
    }
  )

  if (companyUsageGoalLoading) {
    return (
      <>
        <LoadingSpinner sizeInPixels={50} />
      </>
    )
  }

  const allUsageData = usageGoalData?.usageData

  const percentage = (props.peopleSupported / props.annualGoal) * 100

  const isPercentageOverGoal = percentage > props.annualGoalPercentage

  if (allUsageData?.length == 0) {
    // will be rethought in a later task
    return (
      <div className="flex justify-center text-center">
        <div className="max-w-sm">
          <EmptyState
            title="Add users to your team"
            description="To see an overview of your usage, add users to your team."
            icon={<ExclamationCircleIcon width={32} />}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full flex-col">
        <div className="flex w-full justify-between">
          <div className="flex items-center">
            <P weight="medium">
              {percentage < 10
                ? percentage.toPrecision(1)
                : percentage > 50
                  ? "Over 50"
                  : percentage.toPrecision(2)}
              %
            </P>
          </div>

          <div className="flex items-center">
            <div className="mr-4 flex items-center">
              <span className="mr-2 h-3 w-3 rounded-sm bg-spill-teal-600"></span>
              <P muted size="xs">
                People supported
              </P>
            </div>

            <div className="flex items-center">
              <span className="mr-2 h-3 w-3 rounded-sm bg-spill-teal-100"></span>
              <P muted size="xs">
                Annual goal
              </P>
            </div>
          </div>
        </div>

        <div className="relative mt-4 flex h-12 w-full rounded-lg bg-spill-teal-100">
          <motion.div
            className="absolute z-0 flex h-full rounded-lg bg-spill-teal-600"
            initial={{ width: 0 }}
            animate={{
              width: `${!isPercentageOverGoal ? (percentage / props.annualGoalPercentage) * 100 : 100}%`,
            }}
            transition={{ damping: 17, type: "spring", delay: 0.5 }}
          ></motion.div>
        </div>
        <div className="relative mt-2 flex justify-between">
          <P muted size="xs">
            0%
          </P>
          {/* <P
          muted
          className={`absolute left-[calc(${percentage}% - 1rem)] -translate-x-1/2 transform`}
        >
          {percentage}%
        </P> */}
          <P muted>{props.annualGoalPercentage}%</P>
        </div>
      </div>
      <P weight="medium">
        {isPercentageOverGoal
          ? `You have hit your annual usage goal! 🎉`
          : `You have until ${props.endDate} to hit your annual goal 🎯`}{" "}
      </P>
      <P muted size="xs">
        Your company goal is based on the statistic that 1 in 4 people suffer
        from mental health problems every year.*
      </P>
    </div>
  )
}
