import { gql } from "@apollo/client"

export const queries = {
  getPackage: gql`
    query TherapySpecialisedSupportRequest($id: ID!) {
      getCompanyPackageSettingById(companyPackageSettingId: $id) {
        id
        name
        icon
        packageType
      }
    }
  `,
}
