/**
 * Displays a list of therapy outcomes for a company
 */

import { gql, useQuery } from "@apollo/client"
import { FunctionComponent, useLayoutEffect, useRef, useState } from "react"
import { format } from "date-fns"
import { P } from "@spillchat/puddles"
import {
  EnvelopeIcon,
  HeartIcon,
  PencilIcon,
  RocketLaunchIcon,
  UserIcon,
} from "@heroicons/react/24/outline"

import {
  TherapyOutcomesGetUsageEventsQuery,
  TherapyOutcomesGetUsageEventsQueryVariables,
  UsageEventType,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

export const fragments = {
  queryFields: gql`
    fragment TherapyOutcomesQueryFields on Query {
      user {
        id
        company {
          id
          usageEvents {
            feature
            usedAt
            userId
          }
        }
      }
    }
  `,
}

export const queries = {
  getUsageEvents: gql`
    query TherapyOutcomesGetUsageEvents {
      ...TherapyOutcomesQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const TherapyOutcomes: FunctionComponent = () => {
  const { data, loading } = useQuery<
    TherapyOutcomesGetUsageEventsQuery,
    TherapyOutcomesGetUsageEventsQueryVariables
  >(queries.getUsageEvents)
  const outcomesRef = useRef<HTMLUListElement>(null)
  const [outcomeTop, setOutcomeTop] = useState<number>(
    outcomesRef.current?.getBoundingClientRect().top ?? 0
  )

  useLayoutEffect(() => {
    const { top } = outcomesRef.current?.getBoundingClientRect() ?? {
      top: 0,
    }
    setOutcomeTop(top)
  }, [loading])

  const usageEvents = data?.user?.company?.usageEvents ?? []

  /**
   * These are the event types that we want to show the user/admin
   */
  const visibleEventTypes = [
    UsageEventType.THERAPY_MESSAGE,
    UsageEventType.ASK_A_THERAPIST,
    UsageEventType.ONE_OFF_THERAPY_SESSION,
    UsageEventType.FIRST_THERAPY_SESSION,
    UsageEventType.SERIES_THERAPY_SESSION,
    UsageEventType.ONE_OFF,
    UsageEventType.CONSULTATION,
    UsageEventType.COURSE,
    UsageEventType.SERIES_CONSULTATION_SESSION,
    UsageEventType.PLUS_ONE,
    UsageEventType.PMI_SESSION,
    // these are not visble but are included for completeness
    // UsageEventType.MANAGER_CLINIC,
    // UsageEventType.PULSE_TRIAGE,
    // UsageEventType.PULSE_CHECKIN,
  ]

  /**
   * We need to filter the usage events to only include ones
   * that we want the user/admin to see.
   */
  const filteredUsageEvents = usageEvents.filter(usageEvent => {
    return visibleEventTypes.includes(usageEvent.feature)
  })

  /***
   * Generates a human readable message for the outcome. We include all
   * the possible outcomes here, even though we only show a subset of them
   */
  const getOutcomeDetail = (feature: UsageEventType) => {
    switch (feature) {
      case UsageEventType.ONE_OFF:
      case UsageEventType.ONE_OFF_THERAPY_SESSION:
        return {
          action: "had a one–off session",
          icon: <UserIcon className="size-4" />,
        }
      case UsageEventType.PLUS_ONE:
        return {
          action: "had a Plus One session",
          icon: <UserIcon className="size-4" />,
        }
      case UsageEventType.PMI_SESSION:
        return {
          action: "had a PMI session",
          icon: <UserIcon className="size-4" />,
        }
      case UsageEventType.MANAGER_CLINIC:
        return { action: "used the manager clinic", icon: null }
      case UsageEventType.THERAPY_MESSAGE:
      case UsageEventType.ASK_A_THERAPIST:
        return {
          action: "asked a therapist a question and received a response",
          icon: <EnvelopeIcon className="size-4" />,
        }
      case UsageEventType.PULSE_TRIAGE:
        return {
          action: "was messaged by a therapist",
          icon: <PencilIcon className="size-4" />,
        }
      case UsageEventType.CONSULTATION:
      case UsageEventType.SERIES_CONSULTATION_SESSION:
      case UsageEventType.FIRST_THERAPY_SESSION:
        return {
          action: "started a course of therapy",
          icon: <RocketLaunchIcon className="size-4" />,
        }
      case UsageEventType.SERIES_THERAPY_SESSION:
      case UsageEventType.COURSE:
        return {
          action: "took a session as part of a course.",
          icon: <HeartIcon className="size-4" />,
        }
      case UsageEventType.PULSE_CHECKIN:
        return { action: "checked in with pulse", icon: null }
      default:
        return { action: "used a mystery feature 🪄", icon: null }
    }
  }

  if (loading)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )

  if (filteredUsageEvents.length === 0) {
    return (
      <div className="flex flex-col gap-4 w-full">
        <P>
          No therapy sessions have been booked yet. All data will be anonymous.
        </P>
      </div>
    )
  }

  return (
    <ul
      className="flex flex-col gap-4 w-full list-none relative overflow-y-auto py-4 px-2 border border-spill-grey-100 rounded-md"
      ref={outcomesRef}
      style={{
        height: `calc(100vh - ${outcomeTop + 66}px)`,
      }}
    >
      {filteredUsageEvents.map(usageEvent => {
        const { action, icon } = getOutcomeDetail(usageEvent.feature)

        return (
          <li
            className="flex gap-2 items-center relative pb-8 before:content-[''] after:absolute after:left-6 after:top-10 after:content-[''] after:border-l after:border-grey-200 after:ml-1 after:h-full last-of-type:after:hidden"
            key={usageEvent.usedAt}
          >
            <div className="w-10 h-10 bg-spill-blue-100 rounded-full flex justify-center items-center relative z-10">
              {icon}
            </div>
            <div className="flex flex-col text-sm">
              <P>Someone {`${action}`}</P>
              <P muted size="xs">
                {format(new Date(usageEvent.usedAt), "MMM yyyy")}
              </P>
            </div>
          </li>
        )
      })}
    </ul>
  )
}
