import { Input, H2, Form } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { toast } from "sonner"
import { useMutation } from "@apollo/client"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormFieldWrapper } from "components/form/FormFieldWrapper"

import {
  LoginMethodsGetLoginMethodsFragment,
  LoginMethodsUpdatePersonalEmailMutation,
  LoginMethodsUpdatePersonalEmailMutationVariables,
  PlatformType,
} from "types/graphql"

import { mutations } from "./LoginMethods.mutations"
import { loginMethodsSchema, LoginMethodsValues } from "./LoginMethods.schema"

type LoginMethodsProps = {
  loginMethods?: LoginMethodsGetLoginMethodsFragment | null
}

type PlatformUser = {
  email?: string | null
  createdAt: string
  isPersonal?: boolean | null
  platform?: {
    platformType: PlatformType
  } | null
}

export const LoginMethods: FunctionComponent<LoginMethodsProps> = ({
  loginMethods,
}) => {
  const isAnonymous = loginMethods?.company?.isAnonymous === true

  const companyEmails = new Set<string>()
  const allNonPersonalEmails = new Set<string>()
  const personalEmailUsers = new Set<PlatformUser>()

  if (loginMethods != null) {
    for (const platformUser of loginMethods.platformUsers) {
      const email = platformUser.email?.toLowerCase()

      // If no email then we can't use
      if (email == undefined) continue

      // If personal email then we don't consider it a company email
      if (platformUser.isPersonal === true) {
        personalEmailUsers.add(platformUser)
      }
      // If it is used in an integration we know it is a company email
      else if (
        platformUser.platform?.platformType != null &&
        [PlatformType.CUSTOM, PlatformType.SLACK, PlatformType.TEAMS].includes(
          platformUser.platform.platformType
        )
      ) {
        companyEmails.add(email)
      }
      // If it is an email platform, but not personal we cannot assume it is a company email as they may have been signed up with a personal email.
      // So we add to the final set as a back-up if there are no integration emails
      else {
        allNonPersonalEmails.add(email)
      }
    }

    // this is insufficient, but the backend will handle it
    if (companyEmails.size === 0 && allNonPersonalEmails.size > 0) {
      companyEmails.add([...allNonPersonalEmails][0]!)
    }
  }

  // Finds the most recently added personal email
  const personalEmail =
    personalEmailUsers.size > 0
      ? ([...personalEmailUsers].reduce((a, b) => {
          return new Date(a.createdAt) > new Date(b.createdAt) ? a : b
        }).email ?? "")
      : ""

  // Pick any of the company emails to display
  const companyEmail = [...companyEmails][0] ?? ""

  const forms = {
    personalEmail: useForm<LoginMethodsValues>({
      resolver: zodResolver(loginMethodsSchema),
      values: {
        personalEmail,
      },
    }),
  }

  const [updateLoginMethods] = useMutation<
    LoginMethodsUpdatePersonalEmailMutation,
    LoginMethodsUpdatePersonalEmailMutationVariables
  >(mutations.updatePersonalEmail, {
    refetchQueries: ["ProfilePageGetUser"],
    onCompleted: () => toast.success("Personal email updated"),
    onError: () => {
      toast.error("It looks like something went wrong, please try again.")
    },
  })

  return (
    <div className="flex flex-col gap-12">
      <H2>Login details</H2>
      <div className="flex flex-col gap-5">
        <FormFieldWrapper label="Company email" displayValue={companyEmail}>
          <Form.Label>Company email</Form.Label>
        </FormFieldWrapper>
        {!isAnonymous && (
          <Form.Root {...forms.personalEmail}>
            <FormFieldWrapper<LoginMethodsValues>
              onSubmit={forms.personalEmail.handleSubmit(async values => {
                await updateLoginMethods({
                  variables: {
                    email: values.personalEmail,
                  },
                })
              })}
              description="You can use this as an alternative way to log in"
              label="Personal email"
              displayValue={forms.personalEmail.getValues("personalEmail")}
              values={["personalEmail"]}
            >
              <Form.Field
                control={forms.personalEmail.control}
                name="personalEmail"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label className="text-mono-black">
                      Personal email
                    </Form.Label>
                    <Form.Control>
                      <Input {...field} />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
            </FormFieldWrapper>
          </Form.Root>
        )}
      </div>
    </div>
  )
}
