import {
  CalendarDateRangeIcon,
  HeartIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline"
import { Button, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

export const SpecialisedSupportItemCreate: FunctionComponent = () => {
  const list = [
    {
      Icon: UserPlusIcon,
      text: "Specialists tailored to your needs",
    },
    {
      Icon: HeartIcon,
      text: "Set the number of sessions",
    },
    {
      Icon: CalendarDateRangeIcon,
      text: "Set the duration and timing",
    },
  ]

  return (
    <div className="flex lg:flex-row flex-col w-full relative p-8 lg:p-10 border border-spill-grey-100 rounded-md gap-8 lg:gap-16">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <H3>Create new</H3>
          <P muted>
            Build your own custom package for a situation we haven’t
            covered&nbsp;yet.
          </P>
        </div>

        <Button size="sm" variant="primary" asChild>
          <Link to="/admin/specialised-support/guide">Get started</Link>
        </Button>
      </div>
      <div className="flex flex-col gap-3 w-full max-w-sm">
        {list.map(item => {
          return (
            <div className="flex items-center gap-4" key={item.text}>
              <item.Icon className="text-spill-blue-800 size-6" width={24} />
              <P muted>{item.text}</P>
            </div>
          )
        })}
      </div>
    </div>
  )
}
