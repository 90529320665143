import { Button, H1, H2, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import { PackageType } from "types/graphql"
import { BackButton } from "common/components/BackButton"

import { getContentFromPackageType } from "./admin-therapy-specialised-support-guide-copy"

type AdminTherapySpecialisedSupportGuideProps = {
  supportPackageType: PackageType
}

export const AdminTherapySpecialisedSupportGuide: FunctionComponent<
  AdminTherapySpecialisedSupportGuideProps
> = (props: AdminTherapySpecialisedSupportGuideProps) => {
  const content = getContentFromPackageType(props.supportPackageType)

  return (
    <div className="flex flex-col gap-12">
      <BackButton to="/admin/specialised-support" />
      <div className="flex flex-col-reverse lg:flex-row justify-between gap-12 lg:gap-24 grow">
        <div className="flex flex-col gap-5 max-w-lg">
          <H1>{content.title}</H1>
          <div className="flex flex-col gap-3">
            <H3>How it works</H3>
            {content.description}
          </div>
        </div>
        <div>
          <img
            src={content.image}
            alt={content.title}
            className="rounded-lg aspect-square min-w-sm max-w-xs sm:max-w-sm w-full"
          />
        </div>
      </div>
      <hr className="border-t border-grey-200" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <H2>Set up your support</H2>
          <P muted>Get started with enabling this support for your company</P>
        </div>
        <div className="grid lg:grid-cols-2 gap-16 items-start">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <H3>What's included</H3>
              <div className="flex flex-col gap-3">
                {content.featureList.map(feature => {
                  return (
                    <div className="flex items-center gap-4" key={feature.text}>
                      <div className="text-spill-blue-800 size-6">
                        {feature.icon}
                      </div>
                      <P muted>{feature.text}</P>
                    </div>
                  )
                })}
              </div>
            </div>
            <Button variant="primary" asChild>
              <Link to={content.link}>Get started</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
