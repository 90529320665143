import { FunctionComponent, useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { Helmet } from "react-helmet-async"
import { Button, H3, P } from "@spillchat/puddles"
import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client"
import { toast } from "sonner"
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline"

import { MultiStepForm } from "common/components/MultiStepForm/MultiStepForm"
import { useAnalytics } from "common/context/analyticsContext"
import { ExternalStepIndexAndModifiers } from "common/components/MultiStepForm/ExternalStepIndexAndModifiers"
import {
  PmiSettingsGetPmiSettingValueAndDetailsQuery,
  PmiSetupFormTurnOnPmiSettingAndAddDetailsMutation,
  PmiSetupFormTurnOnPmiSettingAndAddDetailsMutationVariables,
} from "types/graphql"

import { queries } from "../PmiSettings/PmiSettings.queries"

import { PmiSetup, pmiSetupSchema } from "./PmiSetupForm.schema"
import { PmiSetupProvider } from "./steps/PmiSetupProvider"
import { PmiSetupInstructions } from "./steps/PmiSetupInstructions"
import { PmiProviderType } from "./PmiSetupForm.types"
import { mutations } from "./PmiSetupForm.mutations"

interface PmiSetupFormProps {
  setOpen: (newIsOpen: boolean) => void
  refetch: () => Promise<
    ApolloQueryResult<PmiSettingsGetPmiSettingValueAndDetailsQuery>
  >
}

export const PmiSetupForm: FunctionComponent<PmiSetupFormProps> = ({
  setOpen,
  refetch,
}) => {
  const { track } = useAnalytics()

  const [stepIndexAndModifiers, setStepIndexAndModifiers] =
    useState<ExternalStepIndexAndModifiers>()

  const defaultValues: PmiSetup = {
    provider: "" as PmiProviderType,
    accessInstructions: "",
  }

  const { data } = useQuery<PmiSettingsGetPmiSettingValueAndDetailsQuery>(
    queries.getPmiSettingValueAndDetails
  )

  const [turnOnPmiSettingAndAddDetails] = useMutation<
    PmiSetupFormTurnOnPmiSettingAndAddDetailsMutation,
    PmiSetupFormTurnOnPmiSettingAndAddDetailsMutationVariables
  >(mutations.turnOnPmiSettingAndAddDetails, {
    onCompleted() {
      toast.success("Your PMI pathway has been created.")
    },
    onError() {
      toast.error("Something went wrong, please try again or get in touch.")
    },
  })

  const providerStepAdvanceAction = async (
    form: UseFormReturn<PmiSetup>,
    incrementStep: () => void
  ) => {
    const valid = await form.trigger(["provider"])

    if (valid) {
      track("Completed step of PMI setup", { step: "Provider" })
      incrementStep()
    }
  }

  const setupInstructionsStepAdvanceAction = async (
    form: UseFormReturn<PmiSetup>
  ) => {
    const valid = await form.trigger(["accessInstructions"])

    if (valid) {
      await turnOnPmiSettingAndAddDetails({
        variables: {
          provider: form.getValues("provider"),
          userInstructions: form.getValues("accessInstructions"),
          userTherapyCap:
            data?.user?.company?.featuresAndSettings.userTherapyCap.value,
        },
      })
      setOpen(false)
      await refetch()
    }
  }

  useEffect(() => {
    document.documentElement.style.overflow = "hidden"
    return () => {
      document.documentElement.style.overflow = ""
    }
  }, [])

  return (
    <>
      <Helmet title="Add PMI pathway" />
      <div className="fixed top-0 left-0 h-screen w-screen bg-mono-white z-50 flex flex-col overflow-hidden">
        <div className="bg-mono-white border-b w-full">
          <div className="flex justify-between items-center max-w-screen-xl px-6 py-4 mx-auto">
            <div className="flex items-center w-16">
              {stepIndexAndModifiers &&
                stepIndexAndModifiers.currentStepIndex > 0 && (
                  <button
                    type="button"
                    onClick={stepIndexAndModifiers.decrementStep}
                  >
                    <div className="flex gap-2 items-center p-0">
                      <ChevronLeftIcon className="size-4" />
                      <P weight="medium">Back</P>
                    </div>
                  </button>
                )}
            </div>
            <div className="text-center">
              <H3>Add PMI pathway</H3>
            </div>
            <div className="flex justify-end items-center w-16">
              <Button type="button" variant="tertiary" size="sm">
                <XMarkIcon className="size-4" onClick={() => setOpen(false)} />
              </Button>
            </div>
          </div>
        </div>
        <div className="flex justify-center pt-12 px-6 pb-6 overflow-auto grow">
          <div className="max-w-lg mx-auto w-full">
            <MultiStepForm
              setExternalStepIndexAndModifiers={setStepIndexAndModifiers}
              schema={pmiSetupSchema}
              defaultValues={defaultValues}
              stepsAndNavigationActions={[
                {
                  step: PmiSetupProvider,
                  advanceAction: providerStepAdvanceAction,
                },
                {
                  step: PmiSetupInstructions,
                  advanceAction: setupInstructionsStepAdvanceAction,
                },
              ]}
              stopSubmitPropagation={true}
            />
          </div>
        </div>
      </div>
    </>
  )
}
