/**
 * Renders a selection of features that the admin can enable and disable.
 */

import { H4, P, SelectableCard, Tooltip } from "@spillchat/puddles"
import { FunctionComponent, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import { SpillSubscriptionPlan } from "types/graphql"

import { BillingFormValues } from "."

interface FeatureAvailabilityProps {
  oneOffs?:
    | {
        active?: boolean | undefined
        value?: boolean | null | undefined
      }
    | null
    | undefined
  courses?:
    | {
        active?: boolean | undefined
        value?: boolean | null | undefined
      }
    | null
    | undefined
  askATherapist?:
    | {
        active?: boolean | undefined
        value?: boolean | null | undefined
      }
    | null
    | undefined
  adviceLibrary?:
    | {
        active?: boolean | undefined
        value?: boolean | null | undefined
      }
    | null
    | undefined
  subscriptionPlan?: SpillSubscriptionPlan | null
}

export const FeatureAvailability: FunctionComponent<
  FeatureAvailabilityProps
> = props => {
  const { setValue, register, watch } = useFormContext<BillingFormValues>()
  const watchedCourses = watch("courses")
  const watchedOneOffs = watch("oneOffs")
  const watchedAskATherapist = watch("askATherapist")
  const watchedAdviceLibrary = watch("adviceLibrary")

  const watchedUserTherapyCap = watch("userTherapyCap")

  useEffect(() => {
    setValue(
      "oneOffs",
      props.oneOffs?.value != null ? props.oneOffs.value : true
    )
    setValue(
      "courses",
      props.courses?.value != null ? props.courses.value : true
    )
    setValue(
      "askATherapist",
      props.askATherapist?.value != null ? props.askATherapist.value : true
    )
    setValue(
      "adviceLibrary",
      props.adviceLibrary?.value != null ? props.adviceLibrary.value : true
    )
  }, [])

  const requiresOneOffUpgrade = props.oneOffs?.active === false
  const requiresCourseUpgrade = props.courses?.active === false
  const requiresAATUpgrade = props.askATherapist?.active === false
  const requiresAdviceLibraryUpgrade = props.adviceLibrary?.active === false
  const companyOnEssentialPlan =
    props.subscriptionPlan === SpillSubscriptionPlan.ESSENTIAL

  return (
    <div className="flex flex-col gap-4">
      <H4 sectionHeader>Therapy features</H4>
      <P>Choose which of our therapy features your employees can use.</P>
      <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
        <div className="col-span-2">
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger className="text-start w-full" type="button">
                <SelectableCard
                  size="lg"
                  title="Access to one–offs"
                  value="one-offs"
                  checked={watchedOneOffs}
                  onClick={() => {
                    setValue("oneOffs", !watchedOneOffs)
                  }}
                  subtitle={
                    requiresOneOffUpgrade
                      ? "Upgrade to access"
                      : "Standalone therapy sessions"
                  }
                  type="checkbox"
                  disabled={
                    requiresOneOffUpgrade ||
                    (watchedCourses === false && watchedOneOffs === true)
                  }
                  {...register("oneOffs")}
                />
              </Tooltip.Trigger>
              {watchedCourses === false && requiresOneOffUpgrade === false && (
                <Tooltip.Content>
                  You must have either one-offs or courses of therapy enabled.
                </Tooltip.Content>
              )}
              {requiresOneOffUpgrade === true && (
                <Tooltip.Content>Only available on Team Plan</Tooltip.Content>
              )}
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
        <div className="col-span-2">
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger className="text-start w-full" type="button">
                <SelectableCard
                  size="lg"
                  title="Access to courses"
                  value="courses"
                  checked={watchedCourses}
                  onClick={() => {
                    setValue("courses", !watchedCourses)
                  }}
                  subtitle="Multiple sessions with same therapist"
                  type="checkbox"
                  disabled={
                    requiresCourseUpgrade ||
                    watchedUserTherapyCap === "2" ||
                    (watchedOneOffs === false && watchedCourses === true)
                  }
                  {...register("courses")}
                />
              </Tooltip.Trigger>
              {watchedOneOffs === false && (
                <Tooltip.Content>
                  You must have either one-offs or courses of therapy enabled.
                </Tooltip.Content>
              )}
              {watchedUserTherapyCap === "2" && (
                <Tooltip.Content>
                  Courses are only available if the session cap is set to a
                  minimum of 4.
                </Tooltip.Content>
              )}
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>

        <div className="col-span-2">
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger className="text-start w-full" type="button">
                <SelectableCard
                  size="lg"
                  title="Advice Library"
                  value="advice-library"
                  checked={watchedAdviceLibrary}
                  onClick={() => {
                    setValue("adviceLibrary", !watchedAdviceLibrary)
                  }}
                  subtitle="Searchable advice from our therapists"
                  type="checkbox"
                  disabled={requiresAdviceLibraryUpgrade}
                  {...register("adviceLibrary")}
                />
              </Tooltip.Trigger>

              {requiresAdviceLibraryUpgrade && (
                <Tooltip.Content>Only available on Team Plan</Tooltip.Content>
              )}
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>

        <div className="col-span-2">
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger className="text-start w-full" type="button">
                <SelectableCard
                  size="lg"
                  title="Ask a Therapist"
                  value="aat"
                  checked={watchedAskATherapist}
                  onClick={() => {
                    setValue("askATherapist", !watchedAskATherapist)
                  }}
                  subtitle={
                    companyOnEssentialPlan
                      ? "Not available on this plan"
                      : "Personalised advice from our therapists"
                  }
                  type="checkbox"
                  disabled={requiresAATUpgrade || companyOnEssentialPlan}
                  {...register("askATherapist")}
                />
              </Tooltip.Trigger>

              {!companyOnEssentialPlan && requiresAATUpgrade && (
                <Tooltip.Content>Only available on Team Plan</Tooltip.Content>
              )}
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      </div>
    </div>
  )
}
