import { Button, H3, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import SpillLogo from "common/assets/logo/logo.svg?react"

export const AdminTherapySpecialisedSupportCopayProvider: FunctionComponent =
  () => {
    return (
      <div className="flex flex-col gap-4">
        <H3>Assessment provider</H3>
        <div className="flex gap-3 items-center">
          <div className="w-12 h-12 flex items-center justify-center border border-grey-200 rounded">
            <SpillLogo className="h-8 w-8 fill-spill-teal-400" />
          </div>
          <div className="flex flex-col gap-1">
            <P weight="medium">Spill</P>
            <P muted>
              Powered by{" "}
              <Button variant="tertiary">
                <Link to="https://diversediagnostics.co.uk/" target="_blank">
                  Diverse Diagnostics
                </Link>
              </Button>
            </P>
          </div>
        </div>
      </div>
    )
  }
