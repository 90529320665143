import { H1, TextArea, P, Button } from "@spillchat/puddles"
import { useFormContext } from "react-hook-form"
import { EyeSlashIcon } from "@heroicons/react/24/outline"

import { FeedbackFormFields } from "../feedback.types"

import { FeedbackFormOpinionScale } from "./FeedbackFormOpinionScale"

export const FeedbackFormSession = (): JSX.Element => {
  const formMethods = useFormContext<FeedbackFormFields>()
  formMethods.watch()

  const updateSelected = (
    nameOfScale: string,
    newSelectedValue: number | string
  ): void => {
    formMethods.setValue(
      nameOfScale as keyof FeedbackFormFields,
      newSelectedValue
    )
  }

  const canSubmit = [
    formMethods.getValues("recommendSpill"),
    formMethods.getValues("metTheirGoals"),
    formMethods.getValues("wouldSeeAgain"),
  ].every(value => value !== undefined)

  const hasContext =
    formMethods.getValues("gotWhatTheyNeededContext")?.trim() !== ""
  formMethods.watch("gotWhatTheyNeededContext")

  return (
    <>
      <H1>So, how was it?</H1>
      <FeedbackFormOpinionScale
        id="recommendSpill"
        required={true}
        question="Would you recommend Spill based on this session?"
        values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        labels={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
        selectedValue={formMethods.getValues("recommendSpill")}
        callback={updateSelected}
      />
      <FeedbackFormOpinionScale
        id="metTheirGoals"
        required={true}
        question="Did the session meet your goals?"
        values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        labels={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
        selectedValue={formMethods.getValues("metTheirGoals")}
        callback={updateSelected}
      />
      <FeedbackFormOpinionScale
        id="wouldSeeAgain"
        required={true}
        question="Would you see this counsellor again?"
        values={[1, 0]}
        labels={["Yes", "No"]}
        minWidth="w-24"
        selectedValue={formMethods.getValues("wouldSeeAgain")}
        callback={updateSelected}
      />

      <TextArea
        id="gotWhatTheyNeededContext"
        className="h-24 w-full max-w-full border-0"
        label={{
          children: (
            <P weight="medium">
              Did you get what you needed from this interaction with Spill?
            </P>
          ),
        }}
        onChange={e =>
          updateSelected("gotWhatTheyNeededContext", e.target.value)
        }
      />

      <div className="flex space-x-2">
        <EyeSlashIcon className="size-10 text-teal-400" />
        <P muted>
          Your answers will only be shared with your counsellor and Spill's
          clinical team, and in aggregate with your company. Only Spill will
          know who left them, unless you opt in to sharing your name on the
          next&nbsp;screen.
        </P>
      </div>

      <div>
        <Button
          type="submit"
          variant="primary"
          disabled={!canSubmit || !hasContext}
        >
          Submit
        </Button>
      </div>
    </>
  )
}
