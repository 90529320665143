import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  P,
  ModalFullScreenInner,
  Breadcrumbs,
  Button,
  Tabs,
} from "@spillchat/puddles"
import { FunctionComponent, ReactNode, useState } from "react"
import { Helmet } from "react-helmet-async"
import { EyeSlashIcon } from "@heroicons/react/24/outline"
import { Link, useNavigate } from "react-router-dom"
import { getCountryForTimezone } from "countries-and-timezones"
import { toast } from "sonner"

import { useGoBack } from "common/hooks/useGoBack"
import {
  useTherapyAdhdCopayRequestCoPayMutation,
  useTherapyAdhdCopayRequestUserQuery,
} from "types/graphql"
import { extractPhoneNumberAndCountryCode } from "common/helpers/phone-country-codes"
import { useApp } from "common/context/appContext"

import {
  formSchema,
  TherapyAdhdCopayRequestSchema,
} from "./therapy-adhd-copay-request.schema"
import { TherapyAdhdCopayRequestConfirmation } from "./components/therapy-adhd-copay-request-confirmation"
import { TherapyAdhdCopayRequestAnonymity } from "./components/therapy-adhd-copay-request-anonymity"
import { TherapyAdhdCopayRequestDetails } from "./components/therapy-adhd-copay-request-details"
import { TherapyAdhdCopayRequestNote } from "./components/therapy-adhd-copay-request-note"

export const TherapyAdhdCopayRequest: FunctionComponent = () => {
  const { appTimeZone } = useApp()
  const navigate = useNavigate()
  const goBack = useGoBack({ isAdmin: true })
  const [step, setStep] = useState(0)

  const { data: user } = useTherapyAdhdCopayRequestUserQuery()
  const [requestCoPay] = useTherapyAdhdCopayRequestCoPayMutation()

  const parsedPhoneNumber =
    user?.user?.therapyProfile?.phoneNumber != null
      ? extractPhoneNumberAndCountryCode(
          user?.user?.therapyProfile?.phoneNumber ?? "",
          appTimeZone ? getCountryForTimezone(appTimeZone)?.id : undefined
        )
      : ""

  const extractDateOfBirth = () => {
    const dateOfBirth = user?.user?.therapyProfile?.dateOfBirth

    if (dateOfBirth == null) {
      return {
        day: "",
        month: "",
        year: "",
      }
    }

    const [year, month, day] = dateOfBirth.split("-")

    return {
      day: day ?? "",
      month: month ?? "",
      year: year ?? "",
    }
  }

  const dateOfBirth = extractDateOfBirth()

  const form = Form.useForm<TherapyAdhdCopayRequestSchema>({
    reValidateMode: "onChange",
    resolver: zodResolver(formSchema),
    values: {
      isAnonymous: false,
      noteFromUser: "",
      email: user?.user?.therapyProfile?.email ?? "",
      phoneNumber: parsedPhoneNumber != "" ? parsedPhoneNumber.phoneNumber : "",
      dateOfBirth,
      phoneCountryCode:
        parsedPhoneNumber != ""
          ? parsedPhoneNumber.countryInfo.phonePrefix
          : "",
    },
  })

  const handleSubmit = async (variables: TherapyAdhdCopayRequestSchema) => {
    const companyPackageSettingId =
      user?.user?.company?.adhdCompanyPackageSetting?.id

    if (companyPackageSettingId == null) {
      return toast.error("You do not have access to this package")
    }

    const dateOfBirth = new Date()
    dateOfBirth.setDate(+variables.dateOfBirth.day)
    dateOfBirth.setMonth(+variables.dateOfBirth.month)
    dateOfBirth.setFullYear(+variables.dateOfBirth.year)

    const { data } = await requestCoPay({
      variables: {
        ...variables,
        companyPackageSettingId,
        dateOfBirth: dateOfBirth.toISOString(),
      },
    })

    if (data?.createCoPayRequest.id != null) {
      toast.success("Request submitted")
      return navigate("/therapy/specialised-support/adhd/co-pay")
    }

    return toast.success("Request failed")
  }

  const tabs: {
    label: string
    isActive: boolean
    step: number
    component?: ReactNode
  }[] = [
    {
      label: "Request",
      isActive: step >= 0,
      step: 0,
      component: <TherapyAdhdCopayRequestNote onNext={() => setStep(1)} />,
    },
    {
      label: "Anonymity",
      isActive: step >= 1,
      step: 1,
      component: <TherapyAdhdCopayRequestAnonymity onNext={() => setStep(2)} />,
    },
    {
      label: "Contact details",
      isActive: step >= 2,
      step: 2,
      component: <TherapyAdhdCopayRequestDetails />,
    },
    {
      label: "Confirmation",
      isActive: step >= 3,
      step: 3,
      component: <TherapyAdhdCopayRequestConfirmation />,
    },
  ]

  return (
    <>
      <Helmet title="Set up ADHD support | Spill" />
      <ModalFullScreenInner
        title="Set up ADHD support"
        onClose={() => goBack()}
      >
        <div className="max-w-xl mx-auto flex flex-col gap-8">
          <Tabs.Root
            defaultValue={step.toString()}
            value={step.toString()}
            className="flex flex-col gap-8"
          >
            <Form.Root {...form}>
              <Breadcrumbs
                onStepChange={setStep}
                currentStep={step}
                tabs={tabs}
              ></Breadcrumbs>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                {tabs.map(tab => {
                  return (
                    <Tabs.Content key={tab.step} value={tab.step.toString()}>
                      {tab.component != null && tab.component}
                    </Tabs.Content>
                  )
                })}
              </form>
            </Form.Root>
          </Tabs.Root>
          <div className="flex gap-4 items-center">
            <EyeSlashIcon className="text-blue-800" width={24} />
            <P muted>
              Your name won't be shared unless you choose to.{" "}
              <Button variant="tertiary" asChild>
                <Link to="">Anonymity on Spill</Link>
              </Button>
            </P>
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
