import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Button,
  cn,
  Form,
  H2,
  H3,
  Input,
  InputStepper,
  Label,
  P,
  Select,
  TextArea,
  ModalFullScreenInner,
  Breadcrumbs,
  Tooltip,
  SwitchBlock,
  Tabs,
} from "@spillchat/puddles"
import { FunctionComponent, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { EyeIcon, GlobeEuropeAfricaIcon } from "@heroicons/react/24/outline"
import { useMutation } from "@apollo/client"

import {
  CreateSupportPackageMutation,
  CreateSupportPackageMutationVariables,
  PackageType,
} from "types/graphql"
import { iconList } from "common/helpers/iconPalette"
import AdminTherapyProcessingAlert from "features/admin-therapy/components/AdminTherapyProcessingAlert"
import { AdminTherapyToggleButtons } from "features/admin-therapy/components/AdminTherapyToggleButtons"
import { useGoBack } from "common/hooks/useGoBack"
import {
  getPackageConfig,
  getPackageDefaults,
} from "features/admin-therapy/packages/specialised-package-config"

import { formSchema } from "./admin-specialised-support-create.schema"
import { mutations } from "./admin-specialised-support-create.mutations"

import type { CreatePackageForm } from "./admin-specialised-support-create.schema"

type CreateSupportPackageProps = {
  supportPackageType: PackageType
}

export const CreateSupportPackage: FunctionComponent<
  CreateSupportPackageProps
> = ({ supportPackageType: supportPackageType }: CreateSupportPackageProps) => {
  const goBack = useGoBack({ isAdmin: true })
  const [step, setStep] = useState(0)

  const config = getPackageConfig(supportPackageType)

  const form = Form.useForm<CreatePackageForm>({
    reValidateMode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...getPackageDefaults(supportPackageType),
      minSessions: config.minSessions,
    },
  })

  const [createSupportPackage, { loading }] = useMutation<
    CreateSupportPackageMutation,
    CreateSupportPackageMutationVariables
  >(mutations.createSupportPackage, {
    refetchQueries: ["AdminTherapySpecialisedSupport"],
  })

  const handleIntro = () => {
    form.clearErrors()
    setStep(getIndex("Introduction") + 1)
  }

  const handlePolicy = async () => {
    const valid = await form.trigger(["numberSessions", "numberMonthsToUse"], {
      shouldFocus: true,
    })

    if (valid) {
      form.clearErrors()
      setStep(getIndex("Policy settings") + 1)
    }
  }

  const handleVisibility = async () => {
    const valid = await form.trigger(
      ["visibleToAllEmployees", "forAllEmployees"],
      {
        shouldFocus: true,
      }
    )

    if (valid) {
      form.clearErrors()
      setStep(getIndex("Policy visibility") + 1)
    }
  }

  const handleReason = async () => {
    const valid = await form.trigger(["adminReason"], {
      shouldFocus: true,
    })

    if (valid) {
      form.clearErrors()
      setStep(getIndex("Configure") + 1)
    }
  }

  const handleSubmit = async (values: CreatePackageForm) => {
    await createSupportPackage({
      variables: {
        ...values,
        iconUrl: `https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/${values.iconUrl}`,
      },
    })

    return setStep(
      getIndex(config.requireApproval === true ? "Access" : "Confirmation")
    )
  }

  const isLoading = loading

  const introTab = config.introduction !== undefined ? ["Introduction"] : []
  const standardTabs = ["Policy settings", "Policy visibility"]
  const customiseTab =
    config.customisable === true ? ["Configure", "Personalise"] : []
  const accessTab =
    config.requireApproval === true ? ["Access"] : ["Confirmation"]

  const tabs = [
    ...introTab,
    ...standardTabs,
    ...customiseTab,
    ...accessTab,
  ].map((tab, index) => ({
    label: tab,
    isActive: step >= index,
    step: index,
  }))

  const policyIsPublic = form.watch("visibleToAllEmployees")

  const submitButton = (
    <Button type="submit" variant="primary" loading={isLoading}>
      Create package
    </Button>
  )

  const getIndex = (label: string) => {
    return tabs.findIndex(tab => tab.label === label)
  }

  useEffect(() => {
    if (!policyIsPublic) {
      form.setValue("forAllEmployees", false)
    }
  }, [policyIsPublic])

  return (
    <>
      <Helmet title={`Set up ${config.title} | Spill`} />
      <ModalFullScreenInner
        title={`Set up ${config.title}`}
        showBackButton={step !== 0 && step !== tabs.length - 1}
        onBack={() => setStep(step - 1)}
        onClose={() => goBack()}
      >
        <div className="max-w-screen-sm mx-auto w-full pb-6 lg:pb-12 px-3 lg:px-0">
          <Tabs.Root
            defaultValue={step.toString()}
            value={step.toString()}
            className="flex flex-col gap-6 lg:gap-12"
          >
            <Form.Root {...form}>
              <Breadcrumbs
                onStepChange={setStep}
                currentStep={step}
                tabs={tabs}
                completed={step === tabs.length - 1}
              ></Breadcrumbs>
              <form onSubmit={form.handleSubmit(handleSubmit)}>
                <Tabs.Content value={`${getIndex("Introduction")}`}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>Before you start</H2>
                      <P size="xs" muted>
                        Some things to note before you configure this support
                      </P>
                    </div>
                    {config.introduction !== undefined && (
                      <>{config.introduction.content}</>
                    )}
                    <Button onClick={handleIntro} variant="primary">
                      Next
                    </Button>
                  </div>
                </Tabs.Content>
                <Tabs.Content value={`${getIndex("Policy settings")}`}>
                  <div className="flex flex-col gap-12">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2">
                        <H2>Policy settings</H2>
                        <P muted>The amount of sessions and support duration</P>
                      </div>
                      <Alert
                        title="Specialist support credits are added to regular Spill session credits"
                        variant="warning"
                      >
                        <P size="xs" muted>
                          If an employee runs out of custom credits and needs
                          more support, they can use their regular Spill credits
                          to book with the same specialist.
                        </P>
                      </Alert>
                      <div className="flex flex-col gap-4">
                        <H3>Number of sessions</H3>
                        <div className="flex flex-col gap-2">
                          <Label htmlFor="numberSessions">
                            Sessions per person
                          </Label>
                          <Form.Field
                            control={form.control}
                            name="numberSessions"
                            render={({ field }) => (
                              <Form.Item className="flex flex-col">
                                <Form.Control>
                                  <InputStepper
                                    value={field.value.toString()}
                                    min={0}
                                    onStepperChange={async value => {
                                      form.clearErrors("numberSessions")
                                      form.setValue(
                                        "numberSessions",
                                        parseInt(value),
                                        { shouldDirty: true }
                                      )
                                      await form.trigger("numberSessions")
                                    }}
                                    {...form.register("numberSessions", {
                                      setValueAs: (value: string) => {
                                        if (isNaN(parseInt(value))) {
                                          return ""
                                        } else {
                                          return parseInt(value)
                                        }
                                      },
                                    })}
                                  />
                                </Form.Control>
                                <Form.Message />
                                <P size="xs" muted>
                                  {config.sessionsPerPersonText}
                                </P>
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                      <hr className="border-t border-grey-100" />
                      <div className="flex flex-col gap-4">
                        <H3>Support duration</H3>
                        <div className="flex flex-col gap-2">
                          <Label htmlFor="numberMonthsToUse">
                            Employees can access support for
                          </Label>
                          <Form.Field
                            control={form.control}
                            name="numberMonthsToUse"
                            render={({ field }) => (
                              <Form.Item className="flex flex-col">
                                <Form.Control>
                                  <div className="flex flex-col gap-8">
                                    <AdminTherapyToggleButtons
                                      onButtonClick={value => {
                                        form.clearErrors("numberMonthsToUse")
                                        form.setValue(
                                          "numberMonthsToUse",
                                          value
                                        )
                                      }}
                                      onStepperChange={value => {
                                        form.clearErrors("numberMonthsToUse")
                                        form.setValue(
                                          "numberMonthsToUse",
                                          value
                                        )
                                      }}
                                      value={field.value}
                                    />
                                    <Form.Message className="!mt-0" />
                                  </div>
                                </Form.Control>
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={async () => await handlePolicy()}
                      variant="primary"
                    >
                      Next
                    </Button>
                  </div>
                </Tabs.Content>
                <Tabs.Content value={`${getIndex("Policy visibility")}`}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <H2>Policy visibility</H2>
                      <P muted>
                        Choose whether all employees can see or automatically
                        use the policy. Leave these toggled off if you want to
                        add individual employees yourself.
                      </P>
                    </div>
                    <div className="flex flex-col gap-12">
                      <div className="flex flex-col gap-5">
                        <div className="flex flex-col gap-2">
                          <Form.Field
                            control={form.control}
                            name="visibleToAllEmployees"
                            render={() => (
                              <Form.Item>
                                <Form.Control>
                                  <SwitchBlock
                                    icon={EyeIcon}
                                    label="Make the policy public"
                                    description="Everyone at the company can see the policy and request access"
                                    defaultChecked={form.getValues(
                                      "visibleToAllEmployees"
                                    )}
                                    onCheckedChange={value => {
                                      form.setValue(
                                        "visibleToAllEmployees",
                                        value
                                      )
                                    }}
                                  />
                                </Form.Control>
                                <Form.Message />
                              </Form.Item>
                            )}
                          />
                        </div>
                        <hr className="border-t border-grey-100" />
                        <div className="flex flex-col gap-2">
                          <Form.Field
                            control={form.control}
                            name="forAllEmployees"
                            render={() => (
                              <Form.Item>
                                <Form.Control>
                                  <Tooltip.Provider>
                                    <Tooltip.Root>
                                      <Tooltip.Trigger className="w-full">
                                        <SwitchBlock
                                          icon={GlobeEuropeAfricaIcon}
                                          label="Auto-enrol all employees"
                                          description="Everyone at the company can use the
                                        policy without requesting access"
                                          disabled={
                                            !form.watch("visibleToAllEmployees")
                                          }
                                          defaultChecked={form.getValues(
                                            "forAllEmployees"
                                          )}
                                          checked={form.getValues(
                                            "forAllEmployees"
                                          )}
                                          onCheckedChange={value => {
                                            form.setValue(
                                              "forAllEmployees",
                                              value
                                            )
                                          }}
                                        />
                                      </Tooltip.Trigger>
                                      <Tooltip.Content>
                                        This setting is only available when the
                                        policy is public
                                      </Tooltip.Content>
                                    </Tooltip.Root>
                                  </Tooltip.Provider>
                                </Form.Control>
                                <Form.Message />
                              </Form.Item>
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex items-center gap-4">
                        {config.customisable === true ? (
                          <Button
                            onClick={async () => await handleVisibility()}
                            variant="primary"
                            loading={isLoading}
                          >
                            Next
                          </Button>
                        ) : (
                          submitButton
                        )}
                      </div>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value={`${getIndex("Configure")}`}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <H2>Reason for your additional support</H2>
                      <P muted>
                        Please give us some context about the situation. This
                        helps us to match you to the right specialist
                        counsellors.
                      </P>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <Label>Your reason</Label>
                        <Form.Field
                          control={form.control}
                          name="adminReason"
                          render={({ field }) => (
                            <Form.Item>
                              <Form.Control>
                                <TextArea
                                  placeholder="Write text here..."
                                  className="h-48"
                                  {...field}
                                />
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                      <div className="flex items-center gap-4">
                        <Button
                          onClick={async () => await handleReason()}
                          variant="primary"
                          loading={isLoading}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value={`${getIndex("Personalise")}`}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <H2>Finally, give your package an identity</H2>
                      <P muted>
                        This is to help you and your team remember what your
                        package includes.
                      </P>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <H3>Package name</H3>
                        <P muted>Call it whatever you like</P>
                      </div>
                      <Form.Field
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <Form.Item>
                            <Label>Name</Label>
                            <Form.Control>
                              <Input placeholder="Type something" {...field} />
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </div>
                    <hr className="border-t border-grey-100" />
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <H3>Package thumbnail</H3>
                        <P muted>
                          Choose an image thumbnail for your support package
                        </P>
                      </div>
                      <Form.Field
                        control={form.control}
                        name="iconUrl"
                        render={({ field }) => {
                          const thumbnails = [
                            "generic-red.svg",
                            "generic-yellow.svg",
                            "generic-blue.svg",
                            "generic-green.svg",
                          ]
                          return (
                            <div className="grid grid-cols-4 gap-4 max-w-lg">
                              {thumbnails.map(thumbnail => {
                                const iconUrl = `https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/${thumbnail}`
                                return (
                                  <button
                                    type="button"
                                    key={thumbnail}
                                    onKeyDown={e => {
                                      if (e.key === "Enter") {
                                        form.clearErrors("iconUrl")
                                        form.setValue("iconUrl", thumbnail)
                                      }
                                    }}
                                    onClick={e => {
                                      e.preventDefault()
                                      form.clearErrors("iconUrl")
                                      form.setValue("iconUrl", thumbnail)
                                    }}
                                  >
                                    <img
                                      src={iconUrl}
                                      alt={`${thumbnail} placeholder`}
                                      width="128"
                                      className={cn(
                                        "ring-2 ring-transparent hover:opacity-80 transition rounded-lg",
                                        {
                                          "ring-blue-800":
                                            field.value.includes(thumbnail),
                                        }
                                      )}
                                      height="128"
                                    />
                                  </button>
                                )
                              })}
                            </div>
                          )
                        }}
                      />
                    </div>
                    <hr className="border-t border-grey-100" />
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <H3>Package icon</H3>
                        <P muted>
                          Choose an icon for your support package in the
                          navigation bar
                        </P>
                      </div>
                      <Form.Field
                        control={form.control}
                        name="icon"
                        render={field => (
                          <Form.Item>
                            <Label>Icon</Label>
                            <Form.Control>
                              <Select.Root
                                {...field}
                                onValueChange={value => {
                                  form.setValue("icon", value, {
                                    shouldValidate: true,
                                  })
                                }}
                              >
                                <Select.Trigger>
                                  <Select.Value placeholder="Select an icon" />
                                </Select.Trigger>
                                <Select.Content side="top">
                                  {iconList.map(icon => (
                                    <Select.Item
                                      key={icon.displayName}
                                      value={`${icon.displayName}`}
                                    >
                                      <div className="flex items-center gap-2">
                                        <icon.icon className="size-4" />{" "}
                                        <P>{icon.displayName}</P>
                                      </div>
                                    </Select.Item>
                                  ))}
                                </Select.Content>
                              </Select.Root>
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </div>
                    <hr className="border-t border-grey-100" />
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        <H3>Package description</H3>
                        <P muted>
                          Tell your team what this package is for, how to use it
                        </P>
                      </div>
                      <Form.Field
                        control={form.control}
                        name="userFacingDescription"
                        render={({ field }) => (
                          <Form.Item>
                            <Label>Description</Label>
                            <Form.Control>
                              <TextArea
                                placeholder="Type something"
                                className="h-24"
                                {...field}
                              />
                            </Form.Control>
                            <Form.Message />
                          </Form.Item>
                        )}
                      />
                    </div>
                    <div className="flex items-center gap-4">
                      <Button
                        type="submit"
                        variant="primary"
                        loading={isLoading}
                      >
                        Create package
                      </Button>
                    </div>
                  </div>
                </Tabs.Content>
                <Tabs.Content value={`${getIndex("Access")}`}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>
                        Your {config.title.toLowerCase()} package is being
                        processed
                      </H2>
                      <P size="xs" muted>
                        We've saved the details of your{" "}
                        {config.title.toLowerCase()} package. You'll be able to
                        see this in the Specialised Support tab on your Therapy
                        page.
                      </P>
                    </div>
                    <AdminTherapyProcessingAlert />
                    <Button asChild>
                      <Link to="/admin/specialised-support">Finish</Link>
                    </Button>
                  </div>
                </Tabs.Content>
                <Tabs.Content value={`${getIndex("Confirmation")}`}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <H2>{config.title} has been set up</H2>
                      <P muted>
                        We’ve added {config.title} for your team. You’ll now be
                        able to edit the settings for this support, as well as
                        add individual people.
                      </P>
                    </div>
                    <Button asChild>
                      <Link to="/admin/specialised-support">Finish</Link>
                    </Button>
                  </div>
                </Tabs.Content>
              </form>
            </Form.Root>
          </Tabs.Root>
        </div>
      </ModalFullScreenInner>
    </>
  )
}
