import { H2, LoadingSpinner, ModalFullScreen, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { gql, useQuery } from "@apollo/client"
import { toast } from "sonner"

import { ChannelChooser } from "common/components/IntegrationComponents/ChannelChooser"
import {
  EditChannelsGetCompanyQuery,
  EditChannelsGetCompanyQueryVariables,
} from "types/graphql"

import { PlatformTypeInfo } from "./IntegrationsAccess"

const queries = {
  getCompany: gql`
    query EditChannelsGetCompany($companyPlatformId: ID!) {
      company {
        id
        platforms(filter: { ids: [$companyPlatformId] }) {
          id
          name
          externalId
          platformType
          avatarUrl
          domain
          allowAccessAll
          externalGroups {
            id
            name
            hasSyncGroup
          }
        }
      }
    }
  `,
}

interface EditChannelsProps {
  editChannels: boolean
  setEditChannels: (edit: boolean) => void
  platformTypeInfo: PlatformTypeInfo
  companyPlatformId: string
  refetch: () => void
}

export const EditChannels: FunctionComponent<EditChannelsProps> = ({
  editChannels,
  setEditChannels,
  platformTypeInfo,
  companyPlatformId,
  refetch,
}) => {
  const { data } = useQuery<
    EditChannelsGetCompanyQuery,
    EditChannelsGetCompanyQueryVariables
  >(queries.getCompany, {
    fetchPolicy: "cache-first",
    skip: companyPlatformId == null,
    variables: { companyPlatformId },
  })

  const platformData = data?.company?.platforms[0]

  const afterConfirmation = async (success: boolean, errorMessage: string) => {
    if (success) {
      toast.success("Successfully updated.")
      setEditChannels(false)
    } else {
      toast.error(errorMessage)
    }
    refetch()
    return await Promise.resolve()
  }

  return (
    <ModalFullScreen
      title={`Edit ${platformTypeInfo.name} channels`}
      isOpen={editChannels}
      onClose={() => setEditChannels(false)}
    >
      <div className="max-w-screen-sm mx-auto w-full px-3 lg:px-0 flex flex-col gap-y-12 pb-12">
        <div className="flex flex-col gap-4">
          <H2>Add Spill to specific channels</H2>
          <P muted>
            Choose from the list of public channels below. Spill will create an
            account for all the users in this channel and automatically send
            them a DM to log in.
          </P>
          <P muted>
            You can uninstall the Slack integration from your access settings.
          </P>
        </div>
        <div className="flex items-center justify-center">
          {platformData ? (
            <ChannelChooser
              platformData={platformData}
              platformTypeInfo={platformTypeInfo}
              afterConfirmation={afterConfirmation}
            />
          ) : (
            <LoadingSpinner sizeInPixels={50} />
          )}
        </div>
      </div>
    </ModalFullScreen>
  )
}
