import { P, Button, DataTable } from "@spillchat/puddles"
import { ColumnDef } from "@tanstack/react-table"
import { FunctionComponent } from "react"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"

import { PlatformUserOrUser } from "./AccountLinking.types"

type AccountLinkingTableRow = {
  emails: string[]
  manuallyLinked?: string
  dontLink: boolean
  autoMerged?: string
} & PlatformUserOrUser

type AccountLinkingTableProps = {
  newPlatformType: string
  data: AccountLinkingTableRow[]
  onLinkAccount: (row: AccountLinkingTableRow) => void
  onDontLinkAccount: (row: AccountLinkingTableRow) => void
}

export const AccountLinkingTable: FunctionComponent<
  AccountLinkingTableProps
> = ({
  newPlatformType,
  onLinkAccount,
  onDontLinkAccount,
  data,
}: AccountLinkingTableProps) => {
  const columns: ColumnDef<AccountLinkingTableRow>[] = [
    {
      header: "Done",
      cell: ({ row }) => {
        const isDone =
          row.original.autoMerged != null ||
          row.original.dontLink ||
          row.original.manuallyLinked != null

        return (
          <div className="flex">
            {isDone ? (
              <CheckCircleIcon className="w-4 text-teal-400" />
            ) : (
              <ExclamationCircleIcon className="w-4 text-yellow-400" />
            )}
          </div>
        )
      },
    },
    {
      header: "Person",
      cell: ({ row }) => {
        return (
          <P muted={row.original.autoMerged != null}>
            {row.original.displayName}
          </P>
        )
      },
    },
    {
      header: "Email",
      cell: ({ row }) => {
        return row.original.emails.map(email => (
          <P key={email} muted={row.original.autoMerged != null}>
            {email}
          </P>
        ))
      },
    },
    {
      header: `${newPlatformType[0]!.toUpperCase() + newPlatformType.slice(1).toLowerCase()} login`,
      cell: ({ row }) => {
        if (row.original.manuallyLinked != null) {
          return row.original.manuallyLinked
        }
        if (row.original.dontLink) {
          return (
            <div className="flex flex-row gap-x-4 px-4">
              <div className="p-2">Not on {newPlatformType}</div>
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => onLinkAccount(row.original)}
              >
                User is on {newPlatformType}
              </Button>
            </div>
          )
        }
        if (row.original.autoMerged != null) {
          return <P muted>{row.original.autoMerged} (automatically merged)</P>
        }
        return (
          <div className="flex flex-row gap-x-4 px-4">
            <Button size="sm" onClick={() => onLinkAccount(row.original)}>
              Link account
            </Button>
            <Button
              size="sm"
              variant="tertiary"
              onClick={() => onDontLinkAccount(row.original)}
            >
              Not on {newPlatformType}
            </Button>
          </div>
        )
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={true} />
}
