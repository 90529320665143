import { Button, Form, H2, H3, P, TextArea } from "@spillchat/puddles"
import { FunctionComponent } from "react"

import { TherapyAdhdCopayRequestSchema } from "../therapy-adhd-copay-request.schema"

type TherapyAdhdCopayRequestNoteProps = {
  onNext: () => void
}

export const TherapyAdhdCopayRequestNote: FunctionComponent<
  TherapyAdhdCopayRequestNoteProps
> = ({ onNext }: TherapyAdhdCopayRequestNoteProps) => {
  const context = Form.useFormContext<TherapyAdhdCopayRequestSchema>()
  const loading = context.formState.isSubmitting

  const validate = async () => {
    const valid = await context.trigger("noteFromUser")

    if (valid) {
      onNext()
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <H2>Give a little context on how an ADHD assessment would help</H2>
        <P muted>
          This will help your admin prioritise your request and can be submitted
          anonymously. It's optional, so you don't have to answer if you'd
          prefer not to.
        </P>
      </div>
      <div className="flex flex-col gap-3">
        <H3>Why would this be helpful for you?</H3>
        <div className="flex flex-col gap-4">
          <Form.Field
            control={context.control}
            name="noteFromUser"
            render={({ field }) => (
              <Form.Item>
                <Form.Control>
                  <TextArea
                    placeholder="Type something"
                    className="h-48"
                    {...field}
                    label={{
                      children: "Your note",
                    }}
                  />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
        </div>
      </div>
      <Button
        onClick={async () => await validate()}
        {...{ loading }}
        variant="primary"
      >
        Next
      </Button>
    </div>
  )
}
