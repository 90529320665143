import {
  ComponentProps,
  Fragment,
  FunctionComponent,
  PropsWithChildren,
} from "react"
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { H3, Button, cn } from "@spillchat/puddles"

export type DialogMaxWidth =
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"

interface DialogProps extends PropsWithChildren {
  /**
   * Buttons to render in the Dialog box. They can have
   * different styles based on the type of button and
   * the action they perform.
   */
  buttons?: ComponentProps<typeof Button>[]
  /**
   * Shows close icon button in top right corner
   * and allows closing by clicking backdrop.
   */
  canClose?: boolean
  /**
   * Determines if the modal is open or not.
   */
  isOpen: boolean
  /**
   * Set the max width of the dialog box. Defaults to md.
   * Ideally we would import the tailwind config an use the keys from there
   * But let's do that once we're on Tailwind 3.3+ (has better TS support)
   */
  maxWidth?: DialogMaxWidth
  /**
   * The action to perform when the dialog is closed.
   */
  onClose?: () => void
  /**
   * Title of the Dialog box
   */
  title?: string

  /*
    Additional classes to be applied - useful for creating padding
  */
  className?: string
}

export const Dialog: FunctionComponent<DialogProps> = ({
  canClose = false,
  maxWidth = "md",
  className,
  ...props
}) => (
  <Transition appear show={props.isOpen} as={Fragment}>
    <HeadlessDialog
      as="div"
      className="relative z-50"
      onClose={() => canClose && props.onClose?.()}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-mono-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <HeadlessDialog.Panel
              className={cn(
                `bg-mono-white max-w-${maxWidth} overflow-hidden p-4 relative rounded-2xl shadow-xl space-y-4 transition-all w-full`,
                className
              )}
            >
              <header>
                {props.title != null && (
                  <HeadlessDialog.Title as="div">
                    <H3>{props.title}</H3>
                  </HeadlessDialog.Title>
                )}

                {canClose && (
                  <div className="absolute p-3 right-0 top-0">
                    <Button
                      variant="tertiary"
                      onClick={() => props.onClose?.()}
                    >
                      <XMarkIcon className="size-6" />
                    </Button>
                  </div>
                )}
              </header>

              {props.children}

              {props.buttons && (
                <div className="flex gap-2 justify-end">
                  {props.buttons.map((buttonProps, index) => (
                    <Button key={index} {...buttonProps} />
                  ))}
                </div>
              )}
            </HeadlessDialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </HeadlessDialog>
  </Transition>
)
