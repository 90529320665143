import { InputStepper, Label, ToggleButton } from "@spillchat/puddles"
import { FunctionComponent, useEffect, useState } from "react"

type DurationState = "preset" | "custom"

type AdminTherapyToggleButtonsProps = {
  onButtonClick: (value: number | null) => void
  onStepperChange: (value: number) => void
  value: number | null
}

export const AdminTherapyToggleButtons: FunctionComponent<
  AdminTherapyToggleButtonsProps
> = ({
  onButtonClick,
  onStepperChange,
  value,
}: AdminTherapyToggleButtonsProps) => {
  const [durationState, setDurationState] = useState<DurationState>("preset")

  const durationPresets = [
    {
      label: "1 month",
      value: 1,
    },
    {
      label: "3 months",
      value: 3,
    },
    {
      label: "6 months",
      value: 6,
    },
    {
      label: "2 years",
      value: 24,
    },
  ]

  useEffect(() => {
    const isPreset = durationPresets.find(preset => preset.value === value)

    if (isPreset == null && value !== null) {
      setDurationState("custom")
    }
  }, [value])

  return (
    <>
      <div className="flex gap-2 flex-wrap items-center">
        {durationPresets.map(preset => {
          return (
            <ToggleButton
              key={preset.value}
              toggled={value === preset.value && durationState === "preset"}
              onClick={e => {
                e.preventDefault()
                setDurationState("preset")
                onButtonClick(preset.value)
              }}
            >
              {preset.label}
            </ToggleButton>
          )
        })}

        <ToggleButton
          toggled={durationState === "custom"}
          onClick={e => {
            e.preventDefault()
            setDurationState("custom")
            if (value == null) {
              onStepperChange(3)
            }
          }}
        >
          Custom
        </ToggleButton>

        <ToggleButton
          toggled={value === null && durationState === "preset"}
          onClick={e => {
            e.preventDefault()
            setDurationState("preset")
            onButtonClick(null)
          }}
        >
          No expiry
        </ToggleButton>
      </div>
      {durationState === "custom" && (
        <div className="flex flex-col gap-2">
          <Label>Custom duration (in months)</Label>
          <InputStepper
            min={1}
            value={value?.toString() ?? undefined}
            onStepperChange={value => {
              onStepperChange(+value)
            }}
          />
        </div>
      )}
    </>
  )
}
