import { useQuery } from "@apollo/client"
import { ReactElement } from "react"
import { P, H2, Separator } from "@spillchat/puddles"

import {
  TeamEngagementGetCompanyQuery as QueryData,
  TeamEngagementGetCompanyQueryVariables as QueryVars,
  SpillSubscriptionPlan,
} from "types/graphql"
import { LoadingSpinner } from "common/components/LoadingSpinner"

import { UsageGoal } from "./components/UsageGoal"
import { queries } from "./admin-engagement.queries"

export function UsagePage(): ReactElement {
  const { data, loading: companyDataLoading } = useQuery<QueryData, QueryVars>(
    queries.getCompany,
    { fetchPolicy: "cache-first" }
  )

  const plan = data?.user?.company?.subscriptionPlan
  const starterPlan = plan === SpillSubscriptionPlan.STARTER

  if (companyDataLoading) {
    return (
      <>
        <LoadingSpinner sizeInPixels={50} />
      </>
    )
  }

  return (
    <div className="flex max-w-screen-lg flex-col items-start gap-8">
      {!starterPlan && (
        <>
          <div className="flex flex-col items-start gap-8 w-full">
            <div className="flex flex-col items-start gap-3">
              <H2>Usage Goal</H2>
              <P muted>Track your annual usage against your goal</P>
            </div>

            <div className="flex max-w-screen-sm">
              <UsageGoal
                annualGoalPercentage={25}
                annualGoal={100}
                peopleSupported={1}
              />
            </div>
          </div>

          <Separator className="bg-spill-grey-200" />
        </>
      )}
      <div className="flex flex-col items-start gap-8 self-stretch">
        <div className="flex justify-between items-end self-stretch">
          <div className="flex flex-col items-start gap-3">
            <H2>Usage Chart</H2>
            <P muted>Track the types of sessions being used on Spill</P>
          </div>
        </div>
      </div>
      <Separator className="bg-spill-grey-200" />
      <div className="flex flex-col items-start gap-8 w-full">
        <div className="flex flex-col items-start gap-3">
          <H2>Return vs new users</H2>
          <P muted>
            Track the number of return users, and new people trying Spill
          </P>
        </div>
      </div>
      <Separator className="bg-spill-grey-200" />
      <div className="flex flex-col items-start gap-8 w-full">
        <div className="flex flex-col items-start gap-3">
          <H2>Check Ins</H2>
          <P muted>Track the number of employees being surveyed by Spill </P>
        </div>
      </div>
      <Separator className="bg-spill-grey-200" />
    </div>
  )
}
