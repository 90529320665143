import { FunctionComponent } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { Button, CopyField, H2, H3, P } from "@spillchat/puddles"
import { toast } from "sonner"
import { LinkIcon } from "@heroicons/react/24/outline"

import { NotFound404Page } from "common/components/NotFound404Page"

import { platformTypesInfo } from "."

export const SetupSuccessUnauthenticated: FunctionComponent = () => {
  const { platformType } = useParams()
  const { pathname } = useLocation()
  const platformTypeInfo = platformTypesInfo[platformType ?? ""]

  const url = new URL(window.location.href)

  if (!platformTypeInfo) return <NotFound404Page />

  return (
    <div>
      <div className="flex flex-col gap-y-12">
        <div className="flex flex-col">
          <H2>That's all done!</H2>
          <P muted>
            Spill has been securely added to your {platformTypeInfo.name}{" "}
            {platformTypeInfo.companyPlatformTerm}.
          </P>
          <P muted>
            We have let the Spill admin know via email and have sent them the
            link below so they can finish setting up Spill.
          </P>
          <div className="mt-8 flex flex-col gap-y-2">
            <P weight="bold" size="xs">
              Link
            </P>
            <CopyField
              copyValue={`${url.toString()}?showSignIn=true`}
              icon={<LinkIcon />}
              onCopied={() => {
                toast.success(
                  `You can now paste it into a message to your ${
                    platformTypeInfo?.name ?? ""
                  } admin.`
                )
              }}
              onFail={() =>
                toast.error(
                  "There was an issue copying the link. You can copy it manually from the URL bar."
                )
              }
            />
          </div>
        </div>
        <hr></hr>
        <div className="flex flex-col">
          <H3>Are you the owner of the Spill account?</H3>
          <P muted className="mt-3 mb-5">
            You can finish setting up Spill by signing into your Spill admin
            dashboard.
          </P>
          <Button variant="primary" asChild>
            <Link to={`/signin?redirect=${encodeURIComponent(pathname)}`}>
              Sign in
            </Link>
          </Button>
        </div>
      </div>
    </div>
  )
}
