import { z } from "zod"

import { PackageState, PackageType } from "types/graphql"

export const formSchema = z.object({
  numberSessions: z.number().min(0).max(0),
  forAllEmployees: z.boolean(),
  coPayEnabled: z.boolean(),
  numberMonthsToUse: z.number().nullable().optional().default(null),
  adminReason: z
    .string()
    .max(250, {
      message: "Please limit your note to 250 characters",
    })
    .optional(),
  iconUrl: z.string().min(1),
  userFacingDescription: z.string().min(1, {
    message: "Please choose a description",
  }),
  lifeEventId: z.string(),
  packageType: z.literal(PackageType.ADHD),
  visibleToAllEmployees: z.boolean(),
  icon: z.string().min(1, {
    message: "Please choose an icon",
  }),
  name: z.string().trim().min(1, {
    message: "Please choose a name",
  }),
  state: z.enum([
    PackageState.ACTIVE,
    PackageState.AWAITING_COUNSELLORS,
    PackageState.DISABLED,
  ]),
  companyContribution: z
    .number({ message: "Must select an amount" })
    .min(0, { message: "Must be a minimum of £0" })
    .max(995, { message: "Cannot exceed total of £995" }),
})

export type InviteCustomForm = z.infer<typeof formSchema>
