import { useMutation, useQuery } from "@apollo/client"
import { Button, H2, Indicator, P } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { toast } from "sonner"
import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"

import { LoadingPage } from "common/components/LoadingPage"
import { convertPlatformTypeToIndicator } from "features/admin/helpers/convertPlatformTypeToIndicator"
import {
  AccessGetUserQuery,
  AccessSendInvitesMutation,
  AccessSendInvitesMutationVariables,
  PlatformStatus,
  PlatformType,
  SpillSubscriptionPlan,
} from "types/graphql"
import { Dialog } from "common/components/Dialog"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

import { convertPlatformTypeToName } from "../admin/helpers/convertPlatformTypeToName"

import { queries } from "./access.queries"
import { AccessList } from "./components/AccessList"
import { mutations } from "./access.mutations"
import { EmailAccess } from "./components/EmailAccess"
import { IntegrationsAccess } from "./components/IntegrationsAccess"

export interface CompanyPlatform {
  id: string
  name: string
  hasAnnouncedOnboarding: boolean
  platformType: PlatformType
  avatarUrl?: string | null
  externalId: string
  allowAccessAll: boolean
  status: PlatformStatus
  groups: Array<{
    id: string
    isPrivate: boolean
    name: string
  }>
}

export const Access: FunctionComponent = () => {
  const { flags } = useUser()

  const [showInviteConfirmation, setShowInviteConfirmation] =
    useState<boolean>(false)
  const [targetPlatformId, setTargetPlatformId] = useState<string | null>(null)

  const openInviteDialog = (companyPlatformId: string) => {
    setShowInviteConfirmation(true)
    setTargetPlatformId(companyPlatformId)
  }

  const closeInviteDialog = () => {
    setShowInviteConfirmation(false)
    setTargetPlatformId(null)
  }

  const { data, loading, refetch } = useQuery<AccessGetUserQuery>(
    queries.accessGetUser
  )

  const [sendInvites] = useMutation<
    AccessSendInvitesMutation,
    AccessSendInvitesMutationVariables
  >(mutations.sendInvites)

  const sendPlatformInvites = async (companyPlatformId: string) => {
    try {
      const response = await sendInvites({
        variables: {
          companyPlatformId: companyPlatformId,
        },
      })
      if (response.data?.broadcastOnboardingMessage !== undefined) {
        toast.success("Onboarding messages sent")
        closeInviteDialog()
      }
    } catch {
      toast.error("Failed to send invites. Please try again.")
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  const platforms = data?.user?.company?.platforms ?? []
  const companyId = data?.user?.company?.id ?? ""
  const subscriptionPlan = data?.user?.company?.subscriptionPlan

  // This is temporary until we are happy to use new design
  // Partially for linking new buttons to the correct flows as I don't know if they exist yet
  const newAccessMethods = flags[FEATURE_FLAGS.NEW_ACCESS_PAGE]

  return (
    <>
      <Helmet title="Access | Spill" />
      <main className="flex flex-col p-0 gap-y-12">
        <AccessList />

        <hr className="border"></hr>

        <div className="flex flex-col gap-y-8">
          <div className="flex flex-col gap-y-3">
            <H2>Access methods</H2>
            <P muted>How your team is able to log into and user Spill</P>
          </div>

          {newAccessMethods ? (
            <AccessMethods
              companyId={companyId}
              platforms={platforms}
              userId={data?.user?.id ?? ""}
              openInviteDialog={openInviteDialog}
              refetch={refetch}
            />
          ) : (
            <OldAccessMethods
              companyId={companyId}
              platforms={platforms}
              subscriptionPlan={subscriptionPlan}
              openInviteDialog={openInviteDialog}
            />
          )}
        </div>

        <InviteDialog
          targetPlatformId={targetPlatformId ?? ""}
          showInviteConfirmation={showInviteConfirmation}
          closeInviteDialog={closeInviteDialog}
          sendPlatformInvites={sendPlatformInvites}
        />
      </main>
    </>
  )
}

type AccessMethodsProps = {
  companyId: string
  platforms: CompanyPlatform[]
  userId: string
  openInviteDialog: (companyPlatformId: string) => void
  refetch: () => void
}

const AccessMethods: FunctionComponent<AccessMethodsProps> = ({
  companyId,
  platforms,
  userId,
  openInviteDialog,
  refetch,
}) => {
  const emailPlatform = platforms.find(
    platform => platform.platformType == PlatformType.EMAIL
  )

  return (
    <div className="flex flex-col gap-y-8">
      {emailPlatform && (
        <EmailAccess companyId={companyId} emailPlatform={emailPlatform} />
      )}
      <hr></hr>
      <IntegrationsAccess
        userId={userId}
        companyId={companyId}
        companyPlatforms={platforms}
        openInviteDialog={openInviteDialog}
        refetch={refetch}
      />
    </div>
  )
}

type OldAccessMethodsProps = {
  companyId: string
  platforms: CompanyPlatform[]
  subscriptionPlan?: SpillSubscriptionPlan
  openInviteDialog: (companyPlatformId: string) => void
}

const OldAccessMethods: FunctionComponent<OldAccessMethodsProps> = ({
  companyId,
  platforms,
  subscriptionPlan,
  openInviteDialog,
}) => {
  const platformTypes = new Set(
    platforms.map(platform => platform.platformType)
  )

  const channels = new Set(
    platforms
      .filter(platform => platform.groups.length > 0)
      .flatMap(platform => platform.groups.map(group => group.name))
  )

  const hasSlackOrTeams =
    platformTypes.has(PlatformType.SLACK) ||
    platformTypes.has(PlatformType.TEAMS)

  const emailPlatform = platforms.find(
    platform => platform.platformType == PlatformType.EMAIL
  )

  const canCreatePlatform =
    subscriptionPlan != undefined
      ? [SpillSubscriptionPlan.TEAM, SpillSubscriptionPlan.ESSENTIAL].includes(
          subscriptionPlan
        )
      : false

  const companyPlatform = platforms?.find(({ platformType }) =>
    [PlatformType.SLACK, PlatformType.TEAMS].includes(platformType)
  )

  const targetCompanyPlatform =
    companyPlatform ?? (platforms[0] as CompanyPlatform)

  const platformTypeName = convertPlatformTypeToName(
    targetCompanyPlatform.platformType
  )

  const showOnboardingInvite =
    targetCompanyPlatform.platformType !== PlatformType.EMAIL &&
    !targetCompanyPlatform.hasAnnouncedOnboarding

  return (
    <div>
      {(platformTypes.size > 0 || channels.size > 0) && (
        <>
          <div>
            <div className="grid lg:grid-cols-3 gap-4 w-full">
              {platformTypes.size > 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Access methods</P>
                  <div className="flex items-center gap-2">
                    {Array.from(platformTypes.values()).map(platform => {
                      return (
                        <Indicator
                          key={platform}
                          variant={convertPlatformTypeToIndicator(platform)}
                        >
                          <span>{convertPlatformTypeToName(platform)}</span>
                        </Indicator>
                      )
                    })}
                  </div>
                  {!hasSlackOrTeams && canCreatePlatform && (
                    <Button variant="tertiary" size="sm" asChild>
                      <Link
                        to="/admin/settings/access/install"
                        className="!p-0"
                      >
                        Add Slack or MS Teams access
                      </Link>
                    </Button>
                  )}
                  {subscriptionPlan === SpillSubscriptionPlan.STARTER && (
                    <Button variant="tertiary" size="sm" asChild>
                      <div className="flex items-center gap-1 !p-0">
                        <InformationCircleIcon className="w-4 h-4" />
                        <Link to="/admin/settings/support" className="!p-0">
                          Go to Therapy to invite new employees
                        </Link>
                      </div>
                    </Button>
                  )}
                </div>
              )}
              {channels.size > 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Channels</P>
                  <div className="flex flex-wrap items-center gap-2">
                    {Array.from(channels.values()).map(channel => {
                      return (
                        <Indicator variant="ok" key={channel}>
                          <span className="whitespace-nowrap">#{channel}</span>
                        </Indicator>
                      )
                    })}
                  </div>
                  <Button variant="tertiary" size="sm" asChild>
                    <div className="flex items-center gap-1 !p-0">
                      <InformationCircleIcon className="w-4 h-4" />
                      <Link
                        to="https://spill.notion.site/Change-the-channel-Spill-is-in-9292d7ef384e4dd689a52a77648989bb"
                        target="_blank"
                        className="!p-0"
                      >
                        Want to change the channel Spill is in?
                      </Link>
                    </div>
                  </Button>
                </div>
              )}
              {hasSlackOrTeams && channels.size == 0 && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Channels</P>
                  <div className="flex items-start gap-2">
                    <ExclamationCircleIcon className="size-4 text-yellow-400" />
                    <div className="flex flex-col gap-2">
                      <P size="xs">
                        Finish adding Spill to a channel to give
                        employees&nbsp;access.
                      </P>
                      <Button variant="tertiary" size="sm" asChild>
                        <Link
                          to={`/admin/settings/access/install/${targetCompanyPlatform.platformType.toLowerCase()}/success?companyPlatformId=${targetCompanyPlatform.id}`}
                        >
                          Add now
                        </Link>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {showOnboardingInvite && (
                <div className="flex flex-col space-y-4 w-full">
                  <P weight="medium">Onboarding notification</P>

                  <P size="xs">
                    Notify your team members that they have access to Spill
                    through {platformTypeName}
                  </P>
                  <div className="flex flex-row space-x-4 items-baseline">
                    <div className="flex">
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() =>
                          openInviteDialog(targetCompanyPlatform.id)
                        }
                      >
                        Send invites via {platformTypeName}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {emailPlatform && (
            <div className="mt-8">
              <EmailAccess
                companyId={companyId}
                emailPlatform={emailPlatform}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

type InviteDialogProps = {
  targetPlatformId: string
  showInviteConfirmation: boolean
  closeInviteDialog: () => void
  sendPlatformInvites: (companyPlatformId: string) => void
}

const InviteDialog: FunctionComponent<InviteDialogProps> = ({
  targetPlatformId,
  showInviteConfirmation,
  closeInviteDialog,
  sendPlatformInvites,
}) => {
  return (
    <Dialog
      isOpen={showInviteConfirmation}
      title="Are you sure?"
      canClose
      onClose={() => closeInviteDialog()}
      buttons={[
        {
          key: 123,
          variant: "secondary",
          children: "Cancel",
          onClick: () => closeInviteDialog(),
        },
        {
          key: 321,
          variant: "primary",
          children: "Confirm",
          onClick: () => sendPlatformInvites(targetPlatformId),
        },
      ]}
    >
      <P size="default">
        You&apos;re about to message every person you&apos;ve given access to.
        We're just checking you're happy to do this!
      </P>
    </Dialog>
  )
}
