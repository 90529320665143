import { gql, useMutation } from "@apollo/client"
import { EnvelopeIcon } from "@heroicons/react/24/outline"
import { Button, H3, Input, P, Select } from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { toast } from "sonner"
import { Link } from "react-router-dom"

import { useAnalytics } from "common/context/analyticsContext"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"
import { Dialog } from "common/components/Dialog"
import {
  UpdateEmailPlatformAccessMethodMutation,
  UpdateEmailPlatformAccessMethodMutationVariables,
} from "types/graphql"

import { CompanyPlatform } from "../access.page"

interface EmailAccessProps {
  companyId: string
  emailPlatform: CompanyPlatform
}

const mutation = gql(`
  mutation UpdateEmailPlatformAccessMethod(
    $companyPlatformId: ID!,
    $allowAccessAll: Boolean!,
    $emailDomain: String!
  ) {
    updateAccessMethod(
      companyPlatformId: $companyPlatformId,
      allowAccessAll: $allowAccessAll,
      externalId: $emailDomain
    ) {
      allowAccessAll
      externalId
    }
  }
`)

export const EmailAccess: FunctionComponent<EmailAccessProps> = ({
  companyId,
  emailPlatform,
}) => {
  const { flags } = useUser()
  const { track } = useAnalytics()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const emailDomain = emailPlatform.externalId

  const [editing, setEditing] = useState<boolean>(false)
  const [allowAccessAllFormValue, setAllowAccessAllFormValue] =
    useState<string>(`${emailPlatform.allowAccessAll}`)
  const [emailDomainFormValue, setEmailDomainFormValue] = useState<string>(
    companyId == emailDomain ? "" : emailDomain
  )

  const [saveAccessUpdate] = useMutation<
    UpdateEmailPlatformAccessMethodMutation,
    UpdateEmailPlatformAccessMethodMutationVariables
  >(mutation)

  if (!flags[FEATURE_FLAGS.DOMAIN_ACCESS]) {
    return <></>
  }

  const toggleEdit = () => {
    setEditing(!editing)
  }

  // When the user tries to update email access method
  const attemptToSave = async () => {
    if (allowAccessAllFormValue == "true") {
      setOpenDialog(true)
    } else {
      await saveEmailAccess()
    }
  }

  // When the user confirms on the dialog they want to continue
  const dialogClick = async () => {
    await saveEmailAccess()
    setOpenDialog(false)
  }

  // To perform the update on the backend
  const saveEmailAccess = async () => {
    const emailDomainSubmission = emailDomainFormValue.startsWith("@")
      ? emailDomainFormValue.slice(1)
      : emailDomainFormValue

    await saveAccessUpdate({
      variables: {
        companyPlatformId: emailPlatform.id,
        allowAccessAll: allowAccessAllFormValue == "true",
        emailDomain: emailDomainSubmission,
      },
    })

    track("Admin updates email domain access", {
      companyId: companyId,
      allowAccessAll: allowAccessAllFormValue == "true",
      emailDomain: emailDomainSubmission,
      sourceUrl: window.location.href,
    })

    setEditing(false)
    toast.success("Saved.")
  }

  return (
    <>
      <DomainAccessDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogClick={dialogClick}
      />
      <div className="flex flex-col gap-y-3 w-2/3">
        <div className="flex flex-row justify-between">
          <EnvelopeIcon className="size-6" />
          <Button variant="tertiary" onClick={toggleEdit}>
            {editing ? "Cancel" : "Edit"}
          </Button>
        </div>
        <H3>Email</H3>
        {editing ? (
          <>
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <P weight="medium">Domain access</P>
                <Select.Root
                  value={allowAccessAllFormValue}
                  onValueChange={setAllowAccessAllFormValue}
                >
                  <Select.Trigger className="w-full">
                    <Select.Value placeholder="Choose access" />
                  </Select.Trigger>
                  <Select.Content>
                    <Select.Item value="false">
                      Only people you invite
                    </Select.Item>
                    <Select.Item value="true">
                      Anyone with your email domain
                    </Select.Item>
                  </Select.Content>
                </Select.Root>
              </div>
              {allowAccessAllFormValue == "true" && (
                <div className="flex flex-col gap-y-2 mt-2">
                  <P weight="medium">Domain name</P>
                  <Input
                    value={emailDomainFormValue}
                    onChange={newValue =>
                      setEmailDomainFormValue(newValue.target.value)
                    }
                  ></Input>
                </div>
              )}
              <Button onClick={attemptToSave}>Save</Button>
            </div>
          </>
        ) : (
          <>
            <P muted>
              {allowAccessAllFormValue == "true"
                ? `Anyone with @${emailDomainFormValue} domain`
                : "Only people you invite can log in"}
            </P>
          </>
        )}
        <Button asChild variant="secondary">
          <Link to="/admin/settings/access/invite/email" className="mt-2">
            Add person via email
          </Link>
        </Button>
      </div>
    </>
  )
}

interface DomainAccessDialogProps {
  openDialog: boolean
  setOpenDialog: (open: boolean) => void
  dialogClick: () => void
}

const DomainAccessDialog: FunctionComponent<DomainAccessDialogProps> = ({
  openDialog,
  setOpenDialog,
  dialogClick,
}) => {
  return (
    <Dialog
      isOpen={openDialog}
      title="Adding email domain"
      canClose={true}
      onClose={() => setOpenDialog(false)}
      className="p-12"
      maxWidth="xl"
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <P weight="medium">
            Employees will no longer have personal email option
          </P>
          <P muted>
            Email domain ensures anonymity for employees who sign up to Spill.
            This also means that when an employee leaves, the Spill admin cannot
            deactivate their account within Spill because they will not know
            whether the leaver was using the service.
          </P>
          <P muted>
            Removing the option to add a personal email means employees can only
            log into Spill via their work email. If they lose access to it, they
            also lose access to Spill. We will let the employees know this is
            changing.{" "}
            <Button variant="tertiary" type="button" asChild>
              <Link
                target="_blank"
                to={
                  "https://spill.notion.site/Adding-email-domain-19849f92fc6b8042a7b4fcc47e7d8937?pvs=4"
                }
              >
                Learn more.
              </Link>
            </Button>
          </P>
        </div>
        <Button type="button" onClick={dialogClick}>
          I understand
        </Button>
      </div>
    </Dialog>
  )
}
