import { Column, ColumnDef } from "@tanstack/react-table"
import {
  DataTable,
  Indicator,
  P,
  DataTableColumnSort,
  Button,
} from "@spillchat/puddles"
import { FunctionComponent, ReactElement } from "react"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"

import {
  AccountStatus,
  PlatformType,
  SpillSubscriptionPlan,
  UserRole,
} from "types/graphql"
import { convertPlatformTypeToName } from "features/admin/helpers/convertPlatformTypeToName"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"

import { AccessTableAction } from "./AccessTableAction"

export type TableUser = {
  accountStatus: AccountStatus
  fullName: string
  identifier?: string
  platformType?: PlatformType
  role: Exclude<UserRole, UserRole.PLUS_ONE>
  email?: string
} & ({ userId: string } | { platformUserId: string })

type AccessTableProps = {
  data: TableUser[]
  userId?: string
  subscriptionPlan?: SpillSubscriptionPlan
  hasSlack?: boolean
}

const roleMap = {
  [UserRole.ADMIN]: "Admin",
  [UserRole.STANDARD]: "Standard",
}

const statusMap = {
  [AccountStatus.ACTIVE]: "Active",
  [AccountStatus.NO_ACCESS]: "Disabled",
  [AccountStatus.NO_ACTIVE_SUBSCRIPTION]: "Active",
  [AccountStatus.NOT_FOUND]: "Not Found",
  [AccountStatus.PENDING_INTEGRATION]: "Pending",
}

export const AccessTable: FunctionComponent<AccessTableProps> = ({
  data,
  userId,
  subscriptionPlan,
  hasSlack,
}: AccessTableProps) => {
  const { flags } = useUser()

  const columns: ColumnDef<TableUser>[] = [
    {
      accessorKey: "identifier",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return <DataTableColumnSort<TableUser> name="Name" column={column} />
      },
      cell: ({ row }) => {
        const person = row.original
        return (
          <div className="flex gap-1 items-center">
            <span>{person.identifier}</span>
            {person.accountStatus === AccountStatus.NOT_FOUND && (
              <ExclamationCircleIcon className="text-red-600 size-4" />
            )}
          </div>
        )
      },
    },
    {
      accessorKey: "role",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return <DataTableColumnSort<TableUser> name="Role" column={column} />
      },
      cell: ({ row }) => roleMap[row.original.role],
    },
    {
      accessorKey: "platformType",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return (
          <DataTableColumnSort<TableUser> name="Invited via" column={column} />
        )
      },
      cell: ({ row }) =>
        row.original.platformType != null
          ? convertPlatformTypeToName(row.original.platformType)
          : "-",
    },
    {
      accessorKey: "accountStatus",
      header: ({
        column,
      }: {
        column: Column<TableUser, unknown>
      }): ReactElement => {
        return (
          <DataTableColumnSort<TableUser>
            name="Billing status"
            column={column}
          />
        )
      },
      cell: ({ row }) => {
        const accountStatus = row.original.accountStatus
        const status = statusMap[accountStatus]

        let indicator: "ok" | "issue" | "warning"

        if (
          accountStatus == AccountStatus.ACTIVE ||
          accountStatus == AccountStatus.NO_ACTIVE_SUBSCRIPTION
        ) {
          indicator = "ok"
        } else if (accountStatus == AccountStatus.PENDING_INTEGRATION) {
          indicator = "warning"
        } else {
          indicator = "issue"
        }

        return <Indicator variant={indicator}>{status}</Indicator>
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const person = row.original
        return (
          <AccessTableAction
            person={person}
            originalPlatform={row.original.platformType}
            loggedInUserId={userId}
          />
        )
      },
    },
  ]

  const newAccessMethods = flags[FEATURE_FLAGS.NEW_ACCESS_PAGE]

  return (
    <div>
      <P weight="bold" className="-mb-3">
        Find a team member
      </P>
      <DataTable
        data={data}
        columns={columns}
        showPagination={true}
        searchColumn="identifier"
        searchPlaceholder="e.g. Jane Doe"
        tableActions={
          newAccessMethods ? (
            <></>
          ) : (
            <AccessTableActions
              subscriptionPlan={subscriptionPlan}
              hasSlack={hasSlack}
            />
          )
        }
      />
    </div>
  )
}

interface AccessTableActionsProps {
  subscriptionPlan?: SpillSubscriptionPlan
  hasSlack?: boolean
}

const AccessTableActions: FunctionComponent<AccessTableActionsProps> = ({
  subscriptionPlan,
  hasSlack,
}) => {
  const hasEmailInvite =
    subscriptionPlan != null
      ? [SpillSubscriptionPlan.TEAM, SpillSubscriptionPlan.ESSENTIAL].includes(
          subscriptionPlan
        )
      : false

  return (
    <>
      {hasEmailInvite && (
        <div className="flex gap-2">
          {hasSlack === true && (
            <Button asChild size="sm" variant="secondary">
              <Link to="/admin/settings/access/invite/slack">
                Add person via Slack
              </Link>
            </Button>
          )}
          <Button asChild size="sm" variant="secondary">
            <Link to="/admin/settings/access/invite/email">
              Add person via email
            </Link>
          </Button>
        </div>
      )}
    </>
  )
}
