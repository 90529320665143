import { Form, H2, P, Input, Select, Button } from "@spillchat/puddles"
import { FormFieldWrapperNested } from "components/form/FormFieldWrapperNested"
import countryCodeEmoji from "country-code-emoji"
import { FunctionComponent } from "react"

import { phoneCountryCodes } from "common/helpers/phone-country-codes"
import { monthMap } from "common/helpers/validateDateOfBirth"

import { TherapyAdhdCopayRequestSchema } from "../therapy-adhd-copay-request.schema"

export const TherapyAdhdCopayRequestDetails: FunctionComponent = () => {
  const context = Form.useFormContext<TherapyAdhdCopayRequestSchema>()

  const validateEmail = async () => {
    return await context.trigger("email")
  }

  const validateDateOfBirth = async () => {
    const valid = await context.trigger("dateOfBirth")

    if (!valid) {
      context.resetField("dateOfBirth")
    }

    return valid
  }

  const validatePhoneNumber = async () => {
    return await context.trigger(["phoneCountryCode", "phoneNumber"])
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <H2>Check your details</H2>
        <P muted>
          Once you submit your request, your company admin will review and
          process it within 7 days. If you get approved, we'll let you know by
          email.
        </P>
        <P muted>
          We'll also pass on your name, phone number and email address to
          Diverse Diagnostics, who will carry out your assessment.
        </P>
      </div>
      <div className="flex flex-col gap-5">
        <FormFieldWrapperNested<TherapyAdhdCopayRequestSchema>
          onSubmit={async () => await validateEmail()}
          description="The email we'll use to contact you about your request"
          label="Email"
          defaultOpen={true}
          displayValue={context.getValues("email")}
          values={["email"]}
        >
          <Form.Field
            control={context.control}
            name="email"
            render={({ field }) => (
              <Form.Item>
                <Form.Label>Email</Form.Label>
                <Form.Control>
                  <Input {...field} />
                </Form.Control>
                <Form.Message />
              </Form.Item>
            )}
          />
        </FormFieldWrapperNested>
        <FormFieldWrapperNested<TherapyAdhdCopayRequestSchema>
          onSubmit={async () => await validateDateOfBirth()}
          description="We'll pass this onto Diverse Diagnostics for their records"
          defaultOpen={true}
          label="Date of birth"
          displayValue={[
            context.getValues("dateOfBirth").day,
            context.getValues("dateOfBirth").month,
            context.getValues("dateOfBirth").year,
          ]
            .filter(value => value)
            .join("/")}
          values={["dateOfBirth.day", "dateOfBirth.month", "dateOfBirth.year"]}
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-col sm:flex-row gap-4">
              <Form.Field
                control={context.control}
                name="dateOfBirth.day"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Day</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={e => {
                          // Pad the day with a 0 if it's a single digit when the user leaves the input
                          if (e.target.value.length === 1) {
                            e.target.value = "0" + e.target.value
                            field.onChange(e.target.value)
                          }
                        }}
                        type="number"
                        placeholder="e.g. 01"
                        min={1}
                        max={31}
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={context.control}
                name="dateOfBirth.month"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Month</P>
                    </Form.Label>
                    <Form.Control>
                      <Select.Root
                        {...field}
                        onValueChange={v => field.onChange(v)}
                      >
                        <Select.Trigger>
                          <Select.Value placeholder={`Select month`} />
                        </Select.Trigger>
                        <Select.Content>
                          {monthMap.map(option => (
                            <Select.Item
                              key={option.value}
                              value={option.displayValue}
                            >
                              {option.label}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <Form.Field
                control={context.control}
                name="dateOfBirth.year"
                render={({ field }) => (
                  <Form.Item className="w-full">
                    <Form.Label htmlFor={field.name}>
                      <P weight="medium">Year</P>
                    </Form.Label>
                    <Form.Control>
                      <Input
                        {...field}
                        onBlur={e => {
                          // Pad the day with a 0 if it's a single digit when the user leaves the input
                          if (e.target.value.length === 2) {
                            //If the 2 digit year is less than current year, it is assumed to be in the 21st century
                            if (
                              parseInt(e.target.value) <
                              new Date().getFullYear() % 100
                            ) {
                              e.target.value = "20" + e.target.value
                            } else {
                              e.target.value = "19" + e.target.value
                            }

                            field.onChange(e.target.value)
                          }
                        }}
                        type="number"
                        placeholder="e.g. 1984"
                        min={1900}
                        max={new Date().getFullYear()}
                      />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
            </div>
            <Form.Field
              control={context.control}
              name="dateOfBirth"
              render={() => (
                <Form.Item className="w-full">
                  <Form.Message />
                </Form.Item>
              )}
            />
          </div>
        </FormFieldWrapperNested>
        <FormFieldWrapperNested<TherapyAdhdCopayRequestSchema>
          onSubmit={async () => await validatePhoneNumber()}
          description="The phone number we'll pass onto Diverse Diagnostics"
          label="Phone number"
          defaultOpen={true}
          displayValue={`(+${context.getValues("phoneCountryCode")}) ${context.getValues("phoneNumber")}`}
          values={["phoneCountryCode", "phoneNumber"]}
        >
          <div className="flex gap-4 items-start w-full">
            <Form.Field
              control={context.control}
              name="phoneCountryCode"
              render={({ field }) => (
                <Form.Item>
                  <Form.Label>Country code</Form.Label>
                  <Form.Control>
                    <Select.Root
                      defaultValue={field.value}
                      onValueChange={value => {
                        context.setValue("phoneCountryCode", value, {
                          shouldDirty: true,
                        })
                      }}
                      required
                    >
                      <Select.Trigger>
                        <Select.Value />
                      </Select.Trigger>
                      <Select.Content>
                        {phoneCountryCodes.map(code => {
                          return (
                            <Select.Item
                              key={code.countryCode}
                              value={code.phonePrefix}
                            >
                              {countryCodeEmoji(code.countryCode)} +
                              {code.phonePrefix}
                            </Select.Item>
                          )
                        })}
                      </Select.Content>
                    </Select.Root>
                  </Form.Control>
                  <Form.Message />
                </Form.Item>
              )}
            />
            <Form.Field
              control={context.control}
              name="phoneNumber"
              render={({ field }) => (
                <Form.Item className="grow">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Item>
                    <Form.Control>
                      <Input {...field} />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                </Form.Item>
              )}
            />
          </div>
        </FormFieldWrapperNested>
      </div>
      <Button
        type="submit"
        variant="primary"
        disabled={!context.formState.isValid}
      >
        Submit request
      </Button>
    </div>
  )
}
