import { gql } from "@apollo/client"

export const queries = {
  getPackage: gql`
    query TherapySpecialisedSupportPackage($id: ID!) {
      user {
        id
        therapyUsageCap
        numberSessionsUsedInCapPeriod
        additionalApprovedTherapySessions
        appointments {
          startsAt
          status
        }
        packageRequests {
          id
          companyPackageSetting {
            id
            numberSessions
            numberMonthsToUse
          }
          status
          createdAt
        }
        therapyPackages {
          id
          numberSessionsGiven
          therapyPackages {
            numberSessionsUsed
            expirationDate
          }
          companyPackageSetting {
            id
            numberSessions
          }
          mostRecentPackageRequest {
            id
          }
        }
      }
      getCompanyPackageSettingById(companyPackageSettingId: $id) {
        id
        name
        icon
        iconUrl
        userFacingDescription
        numberSessions
        numberMonthsToUse
        lifeEventId
        packageType
      }
    }
  `,
  getLifeEvent: gql`
    query TherapySpecialisedSupportPackageLifeEvent($id: String!) {
      getLifeEventFromId(id: $id) {
        id
        name
        slug
      }
    }
  `,
}
