import { useMutation, useQuery } from "@apollo/client"
import { FunctionComponent, useEffect, useState } from "react"
import { useParams, useSearchParams, useNavigate } from "react-router-dom"
import { Button, Combobox, H2, P } from "@spillchat/puddles"
import { toast } from "sonner"

import { NotFound404Page } from "common/components/NotFound404Page"
import { LoadingPage } from "common/components/LoadingPage"
import {
  RestrictUsersMutation,
  RestrictUsersMutationVariables,
  RestrictUsersPlatformUsersQuery,
  RestrictUsersPlatformUsersQueryVariables,
} from "types/graphql"

import { queries } from "./graphql/platform-setup.queries"
import { mutations } from "./graphql/platform-setup.mutations"

interface RemoveableUser {
  id: string
  name: string
  email?: string | null
}

interface Props {
  setParentPlatformType: (platformType: string | undefined) => void
}

export const RestrictUsers: FunctionComponent<Props> = ({
  setParentPlatformType,
}) => {
  const { platformType } = useParams()
  useEffect(() => {
    setParentPlatformType(platformType)
  }, [platformType])
  const [searchParams] = useSearchParams()
  const companyPlatformId = searchParams.get("companyPlatformId")
  const navigate = useNavigate()

  const [usersToRemove, setUsersToRemove] = useState<RemoveableUser[]>([])

  const { data, loading } = useQuery<
    RestrictUsersPlatformUsersQuery,
    RestrictUsersPlatformUsersQueryVariables
  >(queries.getPlatformUsers, {
    variables: {
      companyPlatformId: companyPlatformId ?? "",
    },
  })

  const [restrictUsers] = useMutation<
    RestrictUsersMutation,
    RestrictUsersMutationVariables
  >(mutations.restrictUsers)

  const platformUsers = data?.company.platforms[0]?.platformUsers

  const initialUserOptions = () => {
    return platformUsers!.map(platformUser => {
      const email = platformUser.email !== null ? `(${platformUser.email})` : ""
      return {
        label: `${platformUser.name} ${email}`,
        value: platformUser.id,
        selected: false,
      }
    })
  }

  const onChange = (selectedUserIds: string[]) => {
    const selectedUserIdsSet = new Set(selectedUserIds)

    const updatedRemovableUsers = platformUsers!.filter(platformUser => {
      return selectedUserIdsSet.has(platformUser.id)
    })

    setUsersToRemove(updatedRemovableUsers)
  }

  const removeUsers = async () => {
    const { data } = await restrictUsers({
      variables: {
        platformUserIds: usersToRemove.map(user => user.id),
      },
    })

    if (data?.disablePlatformUsers.success === true) {
      toast.success("Restricted selected users")
      nextPage()
    } else {
      toast.error(
        data?.disablePlatformUsers.errorMessage ??
          "Could not restrict users. Please try again."
      )
    }
  }

  const nextPage = () => {
    navigate(
      `/admin/settings/access/install/${platformType}/confirmation?companyPlatformId=${companyPlatformId}`
    )
  }

  if (companyPlatformId === null) {
    return <NotFound404Page />
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <H2>Remove individuals from Spill access</H2>
        <P muted>
          Remove any individuals who should not have access to Spill by
          selecting them from the list below.
        </P>
        {platformUsers != null && (
          <Combobox
            label="Choose individuals"
            placeholder="Search users"
            allowMultiple={true}
            onChangeMultiple={onChange}
            options={initialUserOptions()}
          />
        )}
        <div className="flex flex-row justify-between">
          <Button type="button" onClick={removeUsers}>
            Remove users
          </Button>
          <Button variant="secondary" type="button" onClick={nextPage}>
            Skip
          </Button>
        </div>
      </div>
    </>
  )
}
