import { useMutation } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  H2,
  H3,
  P,
  Tabs,
  ModalFullScreenInner,
  Breadcrumbs,
  Button,
  Alert,
  InputStepper,
  Label,
  SwitchBlock,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import { LightBulbIcon } from "@heroicons/react/24/outline"

import {
  CreateOrUpdateCoPayMutation,
  CreateOrUpdateCoPayMutationVariables,
  CreateSupportPackageMutation,
  CreateSupportPackageMutationVariables,
  PackageType,
  SpillSubscriptionPlan,
} from "types/graphql"
import { useGoBack } from "common/hooks/useGoBack"
import { useUser } from "common/context/userContext"
import { FEATURE_FLAGS } from "common/constants/flags"
import { getPackageDefaults } from "features/admin-therapy/packages/specialised-package-config"

import { mutations } from "../admin-specialised-support-create.mutations"

import { formSchema } from "./admin-therapy-specialised-support-create-adhd.schema"

import type { InviteCustomForm } from "./admin-therapy-specialised-support-create-adhd.schema"

export const AdminTherapySpecialisedSupportCreateAdhd: FunctionComponent =
  () => {
    const user = useUser()
    const { subscriptionPlan } = user.company ?? {}
    const goBack = useGoBack({ isAdmin: true })
    const [step, setStep] = useState(0)

    const values = getPackageDefaults(PackageType.ADHD)

    const form = Form.useForm<InviteCustomForm>({
      reValidateMode: "onChange",
      resolver: zodResolver(formSchema),
      defaultValues: {
        numberSessions: values.numberSessions,
        forAllEmployees: values.forAllEmployees,
        numberMonthsToUse: values.numberMonthsToUse,
        adminReason: values.adminReason,
        packageType: PackageType.ADHD,
        lifeEventId: values.lifeEventId,
        iconUrl: values.iconUrl,
        userFacingDescription: values.userFacingDescription,
        visibleToAllEmployees: values.visibleToAllEmployees,
        icon: values.icon,
        name: values.name,
        state: values.state,
        coPayEnabled: values.coPayEnabled,
        companyContribution: values.companyContribution,
      },
    })

    const [createPackage] = useMutation<
      CreateSupportPackageMutation,
      CreateSupportPackageMutationVariables
    >(mutations.createSupportPackage, {
      refetchQueries: ["AdminTherapySpecialisedSupport"],
    })

    const [updateCoPay] = useMutation<
      CreateOrUpdateCoPayMutation,
      CreateOrUpdateCoPayMutationVariables
    >(mutations.updateAdhdCoPayAddon)

    const handleSubmit = async (values: InviteCustomForm) => {
      await createPackage({
        variables: {
          ...values,
          iconUrl: `https://spill-public-assets.s3.eu-west-2.amazonaws.com/custom-support/thumbnails/${values.iconUrl}`,
        },
      })
      if (
        values.coPayEnabled === true &&
        user.flags[FEATURE_FLAGS.ADHD_CO_PAY]
      ) {
        await updateCoPay({
          variables: {
            enabled: true,
            companyContribution: values?.companyContribution,
          },
        })
      }
      setStep(2)
    }

    const handleTerms = async () => {
      if (!user.flags[FEATURE_FLAGS.ADHD_CO_PAY]) {
        return await handleSubmit(form.getValues())
      }

      setStep(1)
    }

    const tabs = [
      { label: "Introduction", isActive: step >= 0, step: 0 },
      { label: "Add ons", isActive: step >= 1, step: 1 },
      { label: "Confirmation", isActive: step >= 2, step: 2 },
    ]

    return (
      <>
        <Helmet title="Set up ADHD support | Spill" />
        <ModalFullScreenInner
          title="Set up ADHD support"
          onClose={() => goBack()}
        >
          <div className="max-w-xl mx-auto w-full py-6 lg:py-12 px-3 lg:px-0">
            <Tabs.Root
              defaultValue={step.toString()}
              value={step.toString()}
              className="flex flex-col gap-6 lg:gap-12"
            >
              <Form.Root {...form}>
                <Breadcrumbs
                  onStepChange={setStep}
                  currentStep={step}
                  tabs={tabs}
                ></Breadcrumbs>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                  <Tabs.Content value="0">
                    <div className="flex flex-col gap-12">
                      <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-2">
                          <H2>Before you start</H2>
                          <P muted>
                            Some things to note before you configure this
                            support
                          </P>
                        </div>
                        {subscriptionPlan === SpillSubscriptionPlan.TEAM && (
                          <div className="flex flex-col gap-2">
                            <H3>You won't be charged extra</H3>
                            <P muted>
                              There is no fee for enabling this support. Nice!
                            </P>
                          </div>
                        )}
                        <div className="flex flex-col gap-2">
                          <H3>Regular sessions can be used as ADHD sessions</H3>
                          <P muted>
                            Employees will be able to use their regular therapy
                            sessions to book ADHD sessions. If they run out of
                            sessions they will be able to request more sessions
                            or pay for them privately.
                          </P>
                        </div>
                        {subscriptionPlan ===
                          SpillSubscriptionPlan.ESSENTIAL && (
                          <div className="flex flex-col gap-2">
                            <H3>How it's paid for</H3>
                            <P muted>
                              You're on the Essentials plan, which means you'll
                              pay for each specialist session any employees use.
                              These will come out of your overall company
                              budget, just like regular session credits.
                            </P>
                          </div>
                        )}
                      </div>
                      <Button
                        onClick={async () => await handleTerms()}
                        variant="primary"
                      >
                        I understand
                      </Button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="1">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>Add ons</H2>
                        <P muted>Choose the add ons you'd like in the policy</P>
                      </div>
                      <div className="flex flex-col gap-4">
                        <Form.Field
                          control={form.control}
                          name="visibleToAllEmployees"
                          render={() => (
                            <Form.Item>
                              <Form.Control>
                                <SwitchBlock
                                  icon={LightBulbIcon}
                                  defaultChecked={form.getValues(
                                    "coPayEnabled"
                                  )}
                                  onCheckedChange={value => {
                                    form.setValue("coPayEnabled", value)
                                  }}
                                  label="Assessment Co-Pay"
                                  description="Pay in part for an ADHD assessments"
                                />
                              </Form.Control>
                              <Form.Message />
                            </Form.Item>
                          )}
                        />
                      </div>
                      {form.watch("coPayEnabled") === true && (
                        <div className="flex flex-col gap-2">
                          <H3>How much do you want to pay?</H3>
                          <div className="flex flex-col gap-2">
                            <Label htmlFor="companyContribution">
                              Contribution per person
                            </Label>
                            <Form.Field
                              control={form.control}
                              name="companyContribution"
                              render={({ field }) => (
                                <Form.Item className="flex flex-col">
                                  <Form.Control>
                                    <InputStepper
                                      step="50"
                                      max={600}
                                      min={0}
                                      value={field.value.toString()}
                                      onStepperChange={value => {
                                        form.setValue(
                                          "companyContribution",
                                          parseInt(value)
                                        )
                                      }}
                                      {...form.register("companyContribution", {
                                        setValueAs: (value: string) => {
                                          if (isNaN(parseInt(value))) {
                                            return ""
                                          } else {
                                            return parseInt(value)
                                          }
                                        },
                                      })}
                                    />
                                  </Form.Control>
                                  <Form.Message className="!mt-0" />
                                  <P size="xs" muted>
                                    An assessment on Spill is £995
                                  </P>
                                </Form.Item>
                              )}
                            />
                          </div>
                        </div>
                      )}
                      <Alert title="Why add this?" variant="info">
                        <P size="xs">
                          An ADHD assessment may give people a better
                          understanding of symptoms they experience, as well as
                          access to further treatment or medication.
                        </P>
                        <P size="xs">
                          Offering this on Spill will be faster than going via
                          the NHS.
                        </P>
                      </Alert>
                      <Button type="submit" variant="primary">
                        Confirm
                      </Button>
                    </div>
                  </Tabs.Content>
                  <Tabs.Content value="2">
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-4">
                        <H2>ADHD Support has been set up</H2>
                        <P muted>
                          We've added ADHD Support for your team. You'll now be
                          able to edit the settings for this support, as well as
                          add individual people.
                        </P>
                      </div>
                      <Button variant="primary" asChild>
                        <Link to="/admin/specialised-support">Finish</Link>
                      </Button>
                    </div>
                  </Tabs.Content>
                </form>
              </Form.Root>
            </Tabs.Root>
          </div>
        </ModalFullScreenInner>
      </>
    )
  }
