import { z } from "zod"

import { PackageState, PackageType } from "types/graphql"

export const formSchema = z
  .object({
    numberSessions: z
      .number({ message: "Must select a session count" })
      .max(100, { message: "You cannot have more than of 100 sessions" }),
    forAllEmployees: z.boolean(),
    minSessions: z.number().optional(),
    numberMonthsToUse: z.number().nullable().optional().default(null),
    packageType: z.nativeEnum(PackageType),
    adminReason: z
      .string()
      .max(250, {
        message: "Please limit your note to 250 characters",
      })
      .optional(),
    iconUrl: z.string().min(1),
    lifeEventId: z.string().optional(),
    userFacingDescription: z.string().min(1, {
      message: "Please choose a description",
    }),
    visibleToAllEmployees: z.boolean(),
    icon: z.string().min(1, {
      message: "Please choose an icon",
    }),
    name: z.string().trim().min(1, {
      message: "Please choose a name",
    }),
    state: z.enum([
      PackageState.ACTIVE,
      PackageState.AWAITING_COUNSELLORS,
      PackageState.DISABLED,
    ]),
  })
  .superRefine((data, ctx) => {
    const minSessions = data.minSessions ?? 2
    if (data.numberSessions < minSessions) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: minSessions,
        type: "number",
        inclusive: true,
        message: `Must have a minimum of ${minSessions} sessions`,
        path: ["numberSessions"],
      })
    }
  })

export type CreatePackageForm = z.infer<typeof formSchema>
