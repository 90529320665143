import { Button, H2, P } from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import happyLaptop from "common/assets/images/product/happy-laptop.jpg"
import usingDesktop from "common/assets/images/product/using-desktop.jpg"
import { SpillSubscriptionPlan } from "types/graphql"

type UserInvitesEmptyProps = {
  subscriptionPlan: SpillSubscriptionPlan
}

export const UserInvitesEmpty: FunctionComponent<UserInvitesEmptyProps> = ({
  subscriptionPlan,
}: UserInvitesEmptyProps) => {
  return (
    <div className="max-w-xl text-center">
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="flex -space-x-8">
          <img
            src={usingDesktop}
            alt="A person using a desktop"
            className="rounded-lg w-48 h-48 aspect-square object-cover"
          />
          <img
            src={happyLaptop}
            alt="A happy person using a laptop"
            className="rounded-lg w-48 h-48 aspect-square object-cover mt-12"
          />
        </div>
        <div className="flex flex-col items-center gap-4">
          {subscriptionPlan === SpillSubscriptionPlan.STARTER ? (
            <>
              <H2>Support someone who needs&nbsp;it</H2>
              <P>
                This is your chance to set up accounts for anyone who might need
                some immediate support from Spill. Adding names to your account
                will help you keep track of the therapy invites you share with
                your&nbsp;team.
              </P>
              <Button asChild variant="primary">
                <Link to="/admin/settings/access/invite">Add first person</Link>
              </Button>
            </>
          ) : (
            <>
              <H2>Add your team to&nbsp;Spill</H2>
              <P>
                Before you can give specific therapy support to your team you
                first need to grant access via Slack, MS Team or Email. You can
                do this from the access&nbsp;tab.
              </P>
              <Button asChild variant="primary">
                <Link to="/admin/settings/access">Invite team</Link>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
