import { useMutation, useQuery } from "@apollo/client"
import {
  Button,
  H2,
  InputTags,
  ModalFullScreenInner,
  P,
} from "@spillchat/puddles"
import { FunctionComponent, useState } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "sonner"

import {
  InviteEmailCreatePlatformUsersMutation,
  InviteEmailCreatePlatformUsersMutationVariables,
  CompanyOnboardingStep,
  InviteEmailGetUserQuery,
  PlatformType,
} from "types/graphql"
import { useAuth } from "common/context/authContext"
import { LoadingSpinner } from "common/components/LoadingSpinner"
import { useOnboarding } from "common/context/onboardingContext"
import { useGoBack } from "common/hooks/useGoBack"

import { mutations } from "./invite-email.mutations"
import { queries } from "./invite-email.queries"

export const InviteEmail: FunctionComponent = () => {
  const { isUserLoading } = useAuth()
  const goBack = useGoBack({ isAdmin: true })
  const { markAsComplete, stepsProgress } = useOnboarding()

  const [emails, setEmails] = useState<string[]>([])

  const { data, loading: userLoading } = useQuery<InviteEmailGetUserQuery>(
    queries.getUser,
    {
      fetchPolicy: "cache-first",
    }
  )

  const [createPlatformUser, { loading: platformUserLoading }] = useMutation<
    InviteEmailCreatePlatformUsersMutation,
    InviteEmailCreatePlatformUsersMutationVariables
  >(mutations.createPlatformUsers)

  if (isUserLoading || userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner sizeInPixels={64} />
      </div>
    )
  }

  // Used when the button is pressed to send invites
  const submitEmails = async () => {
    if (emails.length == 0) {
      toast.warning("Please add an email to be invited.")
      return
    }

    // PlatformId of platform to attach the users to is required
    const platformId = data?.user?.company?.platforms.find(
      platform => platform.platformType === PlatformType.EMAIL
    )?.id

    if (platformId == null) {
      // No email platform - this is an error
      toast.error("Can't add emails to this company - contact hi@spill.chat")
      return
    }

    // Attempt to create the platform user for each email
    const { data: platformUser } = await createPlatformUser({
      variables: {
        platformId,
        platformUsers: emails.map(email => {
          return {
            externalId: email,
            name: email.split("@")[0] ?? email,
          }
        }),
      },
    })

    // Let the user know the outcome
    if (
      platformUser?.createPlatformUsers !== undefined &&
      platformUser.createPlatformUsers?.length > 0
    ) {
      // If the first time inviting then mark invitation step as complete
      if (!stepsProgress.hasAddedTeam && markAsComplete) {
        await markAsComplete(CompanyOnboardingStep.ADDED_TEAM)
      }
      toast.success(
        `Invited ${platformUser.createPlatformUsers.length} ${platformUser.createPlatformUsers.length === 1 ? "user" : "users"} successfully`
      )
    } else {
      toast.success(
        "Something went wrong. Please check the email address is correct"
      )
    }

    setEmails([])
    goBack()
  }

  const isLoading = platformUserLoading

  return (
    <>
      <Helmet title="Invite via email | Spill" />

      <ModalFullScreenInner title="Invite via email" onClose={() => goBack()}>
        <div className="max-w-screen-sm mx-auto w-full py-6 lg:py-8 px-3 lg:px-0">
          <div className="flex flex-col gap-4">
            <H2>Invite team members via&nbsp;email</H2>
            <P muted>
              Add the email addresses of the employees you&apos;d like to send
              Spill access to now. You can always invite more people later.
            </P>
          </div>
          <InputTags
            label="Add users"
            placeholder="Email..."
            tags={emails}
            setTags={setEmails}
            validate={isValidEmail}
            className="my-8 w-full"
          />
          <div className="flex items-center gap-4">
            <Button
              loading={isLoading}
              type="button"
              variant="primary"
              onClick={submitEmails}
            >
              Finish
            </Button>
          </div>
        </div>
      </ModalFullScreenInner>
    </>
  )
}

function isValidEmail(email: string): string | undefined {
  const errorMessage = "Invalid email"

  if (!email.includes("@")) return errorMessage

  const [localPart, domainPart] = email.split("@")

  // Ensure both local and domain parts exist
  if (
    localPart == "" ||
    domainPart == "" ||
    localPart == null ||
    domainPart == null
  )
    return errorMessage

  // Ensure domain has a dot (.) and TLD
  if (!domainPart.includes(".")) return errorMessage

  const domainParts = domainPart.split(".")
  const tld = domainParts[domainParts.length - 1]

  // TLD should be at least 2 characters long
  if (tld == null || tld.length < 2) return errorMessage

  return undefined
}
