import { Button, Combobox, H3, P } from "@spillchat/puddles"
import { gql, useMutation } from "@apollo/client"
import { FunctionComponent, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "sonner"

import {
  ChannelChooserAddAppToExternalGroupsMutation,
  ChannelChooserAddAppToExternalGroupsMutationVariables,
  ExternalGroup,
  PlatformType,
} from "types/graphql"
import { PlatformTypeInfo } from "features/access/components/IntegrationsAccess"

const mutations = {
  addAppToExternalGroups: gql`
    mutation ChannelChooserAddAppToExternalGroups(
      $companyPlatformId: ID!
      $groupExternalIds: [String!]!
      $allowAccessAll: Boolean!
    ) {
      addAppToExternalGroups(
        companyPlatformId: $companyPlatformId
        groupExternalIds: $groupExternalIds
        allowAccessAll: $allowAccessAll
      ) {
        id
        success
        errorMessage
      }
    }
  `,
}

interface ChannelChooserProps {
  platformData: {
    id: string
    name: string
    platformType: PlatformType
    avatarUrl?: string | null
    allowAccessAll: boolean
    externalGroups: ExternalGroup[]
  }
  platformTypeInfo: PlatformTypeInfo
  gatewayUrl?: string
  afterConfirmation: (success: boolean, errorMessage: string) => Promise<void>
}

type ExternalGroupComboboxOption = {
  value: string
  label: string
  selected: boolean
}

export const ChannelChooser: FunctionComponent<ChannelChooserProps> = ({
  platformData,
  platformTypeInfo,
  gatewayUrl,
  afterConfirmation,
}) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [allChannels, setAllChannels] = useState<boolean>(
    platformData.allowAccessAll
  )

  // Convert the external groups into ComboboxOptions
  const sortedGroups: ExternalGroupComboboxOption[] = [
    ...platformData.externalGroups,
  ]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(group => {
      return {
        value: group.id,
        label: group.name,
        selected: !allChannels && group.hasSyncGroup,
      }
    })
  const [channels, setChannels] = useState<ExternalGroupComboboxOption[]>(
    sortedGroups.filter(group => group.selected)
  )

  const [addAppToExternalGroups] = useMutation<
    ChannelChooserAddAppToExternalGroupsMutation,
    ChannelChooserAddAppToExternalGroupsMutationVariables
  >(mutations.addAppToExternalGroups)

  // The function that handles communicating the user's choice to the backend
  // and then uses the provided `afterConfirmation()` to control what happens next
  const handleConfirm = async ({
    selectedExternalGroupIds: groupExternalIds,
    allowAccessAll,
  }: {
    allowAccessAll: boolean
    selectedExternalGroupIds: string[]
  }) => {
    // Teams doesn't work with platform syncing yet
    if (platformData.platformType == PlatformType.TEAMS && allowAccessAll) {
      toast.error(
        "You must select channels for Spill to access users from your organization"
      )
      return
    }

    try {
      const { data: data } = await addAppToExternalGroups({
        variables: {
          companyPlatformId: platformData.id,
          allowAccessAll,
          groupExternalIds,
        },
      })

      const success = data?.addAppToExternalGroups.success ?? true
      await afterConfirmation(
        success,
        data?.addAppToExternalGroups.errorMessage ?? ""
      )
    } catch {
      toast.error("We weren't able to add Spill. Please try again.", {
        description: "If the issue persists, please get in touch",
      })
    }
  }

  // Creates string for showing the user the channels they selected
  const displaySelectedChannels = (): string => {
    return channels.map(channel => `#${channel.label}`).join(" ")
  }

  // When a user clicks Next we want to submit their choices
  const submitNext = async () => {
    setIsConfirming(true)
    await handleConfirm({
      allowAccessAll: allChannels,
      selectedExternalGroupIds: channels.map(group => group.value),
    })
    setIsConfirming(false)
  }

  // Update the state with the channels selected by the user
  // Runs each time the user selects or deselects from the list of groups
  const updateChannels = (selectedChannelsIds: string[]) => {
    const selectedChannelsIdsSet = new Set(selectedChannelsIds)
    const selectedChannels: ExternalGroupComboboxOption[] = []
    for (const group of sortedGroups) {
      if (selectedChannelsIdsSet.has(group.value)) {
        selectedChannels.push(group)
      }
    }

    setChannels(selectedChannels)
    setAllChannels(selectedChannelsIds.length == 0)
  }

  return (
    <div className="flex flex-col items-center justify-center gap-8 w-full">
      <div className="w-full flex flex-col gap-2">
        <label className="text-xs font-semibold">
          {platformTypeInfo.name} {platformTypeInfo.companyPlatformTerm}
        </label>

        {/* Integration information */}
        <div className="flex flex-row justify-between rounded-lg border p-3 w-full">
          <div className="flex flex-row gap-4">
            <div className="flex aspect-square h-12 w-12 items-center justify-center rounded-md font-bold">
              {platformData.avatarUrl != null ? (
                <img
                  src={platformData.avatarUrl}
                  className="rounded-md"
                  alt=""
                />
              ) : (
                <div className="w-full h-full bg-spill-teal-600 rounded-xl"></div>
              )}
            </div>
            <div>
              <H3 className="text-lg font-bold">{platformData.name}</H3>
              <P muted>
                {allChannels ? "All channels" : displaySelectedChannels()}
              </P>
            </div>
          </div>
          <div className="flex item-center">
            {gatewayUrl != null && (
              <Button variant="tertiary" asChild>
                <Link to={gatewayUrl}>Edit workspace</Link>
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* Groups combobox */}
      <div className="flex flex-col gap-2 w-full">
        <Combobox
          label={"Choose channels"}
          options={sortedGroups}
          allowMultiple={true}
          onChangeMultiple={updateChannels}
          widthClass="w-[1000px]"
        />
      </div>

      {/* Selection buttons */}
      <div className="flex flex-row justify-start w-full">
        <Button loading={isConfirming} variant="primary" onClick={submitNext}>
          Confirm channels
        </Button>
      </div>
    </div>
  )
}
