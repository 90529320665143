import { FunctionComponent } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { H1, P, H3, H2, Button, SwitchBlock } from "@spillchat/puddles"
import { Helmet } from "react-helmet-async"
import { LightBulbIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"

import { LoadingSpinner } from "common/components/LoadingSpinner"
import imageAdhd from "common/assets/images/product/specialised-support/therapy-adhd.png"
import {
  AddOnType,
  InviteAdhdSettingsQuery,
  InviteAdhdSettingsQueryVariables,
  InviteAdhdToggleMutation,
  InviteAdhdToggleMutationVariables,
  InviteAdhdUpdateCoPayMutation,
  InviteAdhdUpdateCoPayMutationVariables,
  PackageState,
} from "types/graphql"
import { FEATURE_FLAGS } from "common/constants/flags"
import { useUser } from "common/context/userContext"

import { mutations } from "./invite-adhd.mutations"
import { queries } from "./invite-adhd.queries"

export const InviteAdhd: FunctionComponent = () => {
  const { flags } = useUser()

  const {
    data,
    called,
    loading: queryLoading,
  } = useQuery<InviteAdhdSettingsQuery, InviteAdhdSettingsQueryVariables>(
    queries.getSettings
  )

  const { adhdCompanyPackageSetting } = data?.user?.company ?? {}
  const coPaySettings = adhdCompanyPackageSetting?.addOns.find(
    addon => addon.addOnType === AddOnType.CO_PAY
  )

  const packageSettingId = adhdCompanyPackageSetting?.id
  const packageSettingState = adhdCompanyPackageSetting?.state
  const packageEnabled =
    adhdCompanyPackageSetting?.state === PackageState.ACTIVE

  const [updateSetting, { loading: settingMutationLoading }] = useMutation<
    InviteAdhdToggleMutation,
    InviteAdhdToggleMutationVariables
  >(mutations.toggleAdhdSupport, { refetchQueries: [queries.getSettings] })

  const [updateAdhdCoPayAddon] = useMutation<
    InviteAdhdUpdateCoPayMutation,
    InviteAdhdUpdateCoPayMutationVariables
  >(mutations.updateAdhdCoPayAddon, { refetchQueries: [queries.getSettings] })

  const togglePackageState = async () => {
    if (packageSettingId != null) {
      await updateSetting({
        variables: {
          packageSettingId,
          state:
            packageSettingState === PackageState.ACTIVE
              ? PackageState.DISABLED
              : PackageState.ACTIVE,
        },
      })
    }
  }

  const toggleCoPayState = async (enabled: boolean) => {
    if (packageSettingId != null) {
      await updateAdhdCoPayAddon({
        variables: {
          enabled: enabled ?? coPaySettings?.properties.enabled,
          companyContribution:
            coPaySettings?.properties.companyContribution ?? 600,
        },
      })
    }
  }

  if (!called || queryLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner sizeInPixels={32} />
      </div>
    )
  }

  return (
    <>
      <Helmet title="ADHD support | Spill" />
      <section className="flex flex-col gap-12">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-4 max-w-screen-sm">
            <H1>ADHD support</H1>
            <H3>How it works</H3>
            <P>
              With ADHD support, you can provide specialist help for employees
              who experience ADHD.
            </P>
            <P>
              This can be turned on for your whole team, and employees can
              access it regardless of whether they have received an official
              diagnosis or not. Employees do not need to make themselves known
              to an admin in order to access the support.
            </P>
          </div>
          <div className="hidden lg:flex">
            <img
              className="rounded-lg"
              src={imageAdhd}
              alt="Someone has a Spill session using their laptop"
            />
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <H2>Support for your team</H2>
            <P muted>Choose whether you want to turn ADHD support on.</P>
          </div>
          <SwitchBlock
            label={`Support is turned ${packageEnabled ? "on" : "off"}`}
            description="Anyone at your company can access this support."
            defaultChecked={packageEnabled}
            disabled={settingMutationLoading}
            onCheckedChange={async () => await togglePackageState()}
          />
          {flags[FEATURE_FLAGS.ADHD_CO_PAY] && (
            <>
              <hr />
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  <H2>Add ons</H2>
                  <P muted>Choose the add ons you'd like in the policy</P>
                </div>
                <div className="flex flex-col gap-2 max-w-md">
                  <SwitchBlock
                    icon={LightBulbIcon}
                    label="Assessment Co-Pay"
                    description="Pay in part for ADHD assessments"
                    defaultChecked={coPaySettings?.properties.enabled ?? false}
                    onCheckedChange={async value => {
                      return await toggleCoPayState(value)
                    }}
                  />
                  {coPaySettings?.properties.enabled === true && (
                    <Button variant="tertiary" asChild>
                      <Link to="/admin/settings/access/invite/adhd/co-pay">
                        Settings
                      </Link>
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  )
}
