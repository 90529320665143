import { ColumnDef } from "@tanstack/react-table"
import {
  Button,
  DataTable,
  Indicator,
  IndicatorProps,
} from "@spillchat/puddles"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

import {
  PackageRequest,
  PackageRequestStatus,
  RequestType,
} from "types/graphql"
import { formatDate } from "common/helpers/formatDate"

export type AdminTherapySpecialisedSupportRequestTableRow = PackageRequest

type AdminTherapySpecialisedSupportRequestTableProps = {
  data: AdminTherapySpecialisedSupportRequestTableRow[]
}

const requestTypeMap = {
  [RequestType.ACCESS_REQUEST]: "Policy access",
  [RequestType.ADHD_COPAY]: "Co-Pay",
}

export const AdminTherapySpecialisedSupportRequestTable: FunctionComponent<
  AdminTherapySpecialisedSupportRequestTableProps
> = ({ data }: AdminTherapySpecialisedSupportRequestTableProps) => {
  const columns: ColumnDef<AdminTherapySpecialisedSupportRequestTableRow>[] = [
    {
      accessorKey: "userDisplayName",
      header: "Name",
      cell: ({ row }) => {
        const invite = row.original
        return (
          <span className="block py-2">
            {invite.user?.displayName ?? "A user"}
          </span>
        )
      },
    },
    {
      accessorKey: "requestType",
      header: "Request type",
      cell: ({ row }) => {
        const invite = row.original
        return (
          <span className="block py-2">
            {invite.requestType != null ? (
              requestTypeMap[invite.requestType]
            ) : (
              <>&ndash;</>
            )}
          </span>
        )
      },
    },
    {
      accessorKey: "createdAt",
      header: "Request date",
      cell: ({ row }) => {
        const invite = row.original
        return (
          <span className="block py-2">
            {invite.createdAt != null
              ? formatDate(new Date(invite.createdAt))
              : "N/A"}
          </span>
        )
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const indicatorMap = {
          [PackageRequestStatus.PENDING]: {
            value: "Pending",
            variant: "warning",
          },

          [PackageRequestStatus.APPROVED]: {
            value: "Approved",
            variant: "ok",
          },
          [PackageRequestStatus.DECLINED]: {
            value: "Declined",
            variant: "issue",
          },
          [PackageRequestStatus.CANCELLED_BY_USER]: {
            value: "Cancelled",
            variant: "issue",
          },
        }

        const invite = row.original
        const indicatorProps =
          indicatorMap[invite.status as keyof typeof indicatorMap]
        return (
          <Indicator
            variant={
              (indicatorProps.variant as IndicatorProps["variant"]) ?? "info"
            }
          >
            {indicatorProps.value}
          </Indicator>
        )
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        const invite = row.original
        if (invite.status === PackageRequestStatus.PENDING) {
          return (
            <Button variant="secondary" size="sm" asChild>
              <Link to={`request/${invite.id}`}>Review</Link>
            </Button>
          )
        }

        return <></>
      },
    },
  ]

  return <DataTable data={data} columns={columns} showPagination={true} />
}
