import { Navigate, Route, Routes } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { FunctionComponent } from "react"
import { Helmet } from "react-helmet-async"

import { NotFoundPage } from "common/components/NotFoundPage"
import {
  RequireSubscriptionPlan,
  fragments as RequireSubscriptionPlanFragments,
} from "features/auth/components/RequireSubscriptionPlan"
import { CheckoutRouter } from "features/admin/pages/CheckoutRouter"
import { Dashboard } from "features/admin/pages/Dashboard/Dashboard"
import { CheckIns } from "features/pulse/pages/CheckIns"
import {
  PackageType,
  AdminRouterGetUserQuery as QueryData,
  AdminRouterGetUserQueryVariables as QueryVars,
  SpillSubscriptionPlan,
  SpillSubscriptionStatus,
} from "types/graphql"
import { ChangeSessionPack } from "features/change-session-pack/change-session-pack.page"
import { useAdminOnboarding } from "common/hooks/useAdminOnboarding"
import { AdminActivity } from "features/admin-activity/admin-activity.page"
import { AdminTherapySpecialisedSupportCreateAdhd } from "features/admin-therapy-specialised-support-create/adhd/admin-therapy-specialised-support-create-adhd.page"
import { AdminTherapySpecialisedSupportEdit } from "features/admin-therapy-specialised-support-edit/admin-therapy-specialised-support-edit.page"
import { AdminTherapySpecialisedSupportEnrol } from "features/admin-therapy-specialised-support-enrol/admin-therapy-specialised-support-enrol.page"
import { AdminTherapySpecialisedSupportGuide } from "features/admin-therapy-specialised-support-guide/admin-therapy-specialised-support-guide.page"
import { AdminTherapySpecialisedSupportReview } from "features/admin-therapy-specialised-support-review/admin-therapy-specialised-support-review.page"
import { AdminTherapySpecialisedSupport } from "features/admin-therapy-specialised-support/admin-therapy-specialised-support.page"
import { CreateSupportPackage } from "features/admin-therapy-specialised-support-create/admin-specialised-support-create.page"

import { QuoteView } from "./pages/QuoteView"
import { AdminDeclineTherapyExtension } from "./pages/Settings/AdminDeclineTherapyExtension"
import { AdminHome } from "./pages/Home"
import { GetHelp } from "./pages/GetHelp"
import { Settings } from "./pages/Settings"

export const fragments = {
  queryFields: gql`
    fragment AdminRouterQueryFields on Query {
      user {
        id
        role
        company {
          id
          hasInvitedUsers
          subscriptionPlan
          subscriptionStatus
          featuresAndSettings {
            preApproveTherapy {
              value
            }
            therapyPackage {
              active
            }
            adhdSupport {
              active
              value
            }
            customSupport {
              active
            }
          }
        }
      }
      ...RequireSubscriptionPlanQueryFields
    }
    ${RequireSubscriptionPlanFragments.queryFields}
  `,
}

export const queries = {
  getUser: gql`
    query AdminRouterGetUser {
      ...AdminRouterQueryFields
    }
    ${fragments.queryFields}
  `,
}

export const AdminRouter: FunctionComponent = () => {
  const { data, loading } = useQuery<QueryData, QueryVars>(queries.getUser)

  const subscriptionPlan = data?.user?.company?.subscriptionPlan
  const { helpers } = useAdminOnboarding({ subscriptionPlan })

  const isSubscribed =
    data?.user?.company?.subscriptionStatus === SpillSubscriptionStatus.ACTIVE

  const checkInviteAndIntegration = () => {
    if (subscriptionPlan == null) {
      return false
    }

    return (
      helpers?.hasInvitedUsers === true || helpers?.hasAddedIntegration === true
    )
  }

  if (loading) return null

  const hasCustomSupport =
    data?.user?.company?.featuresAndSettings?.customSupport?.active === true

  const hasAdhdSupport =
    data?.user?.company?.featuresAndSettings?.adhdSupport?.active === true

  const hasTherapyPackage =
    data?.user?.company?.featuresAndSettings?.therapyPackage?.active === true

  return (
    <Routes>
      <Route index element={<AdminHome />} />
      <Route path="dashboard/*" element={<Dashboard />} />
      <Route
        // if this path changes the frontFormUrl also needs updating in the
        // front form config
        path="help"
        element={
          <GetHelp frontFormUrl="https://webhook.frontapp.com/forms/59a6bbf15a7ec52638c7/GUOp2rRUSGk5fwkbm_BR-fBM0O71dO3wJhX4J0Uq-Jy4FFyfjr-HGSADmBz3EAFYgVQ9AR4YO_97TRPl2ZWC7QflrtAET4elbB0M5217sZuJTUodMW-oV3wEZstLRw" />
        }
      />
      <Route
        path="therapy/extension/decline/:id"
        element={<AdminDeclineTherapyExtension />}
      />
      <Route path="session-pack" element={<ChangeSessionPack />} />
      <Route path="settings/*" element={<Settings />} />
      {!isSubscribed && <Route path="quote" element={<QuoteView />} />}

      {checkInviteAndIntegration() && (
        <Route path="activity" element={<AdminActivity />} />
      )}
      <Route
        path="check-ins"
        element={
          <RequireSubscriptionPlan
            subscriptionPlan={SpillSubscriptionPlan.TEAM}
          >
            <CheckIns />
          </RequireSubscriptionPlan>
        }
      />
      <Route path="checkout/*" element={<CheckoutRouter />} />

      <Route path="*" element={<NotFoundPage className="grow" />} />

      <Route
        path="/specialised-support"
        element={
          <>
            <Helmet title="Specialised support | Spill" />

            <AdminTherapySpecialisedSupport />
          </>
        }
      />
      {hasAdhdSupport && (
        <>
          <Route
            path="/specialised-support/adhd-support"
            element={
              <>
                <Helmet title="ADHD support | Specialised support | Spill" />
                <AdminTherapySpecialisedSupportGuide
                  supportPackageType={PackageType.ADHD}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/create/adhd-support"
            element={
              <>
                <AdminTherapySpecialisedSupportCreateAdhd />
              </>
            }
          />
        </>
      )}
      {hasCustomSupport && (
        <>
          <Route
            path="/specialised-support/guide"
            element={
              <>
                <Helmet title="Specialised support guide | Spill" />
                <AdminTherapySpecialisedSupportGuide
                  supportPackageType={PackageType.CUSTOM}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/create/custom"
            element={
              <>
                <CreateSupportPackage supportPackageType={PackageType.CUSTOM} />
              </>
            }
          />
        </>
      )}
      {hasTherapyPackage && (
        <>
          <Route
            path="/specialised-support/parenthood-support"
            element={
              <>
                <Helmet title="Parenthood support | Specialised support | Spill" />
                <AdminTherapySpecialisedSupportGuide
                  supportPackageType={PackageType.PARENTHOOD}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/bereavement-support"
            element={
              <>
                <Helmet title="Bereavement support | Specialised support | Spill" />
                <AdminTherapySpecialisedSupportGuide
                  supportPackageType={PackageType.BEREAVEMENT}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/create/parenthood-support"
            element={
              <>
                <CreateSupportPackage
                  supportPackageType={PackageType.PARENTHOOD}
                />
              </>
            }
          />
          <Route
            path="/specialised-support/create/bereavement-support"
            element={
              <>
                <CreateSupportPackage
                  supportPackageType={PackageType.BEREAVEMENT}
                />
              </>
            }
          />
        </>
      )}
      <Route
        path="/specialised-support/:packageId"
        element={
          <>
            <AdminTherapySpecialisedSupportEdit />
          </>
        }
      />
      <Route
        path="/specialised-support/:packageId/enrol"
        element={
          <>
            <AdminTherapySpecialisedSupportEnrol />
          </>
        }
      />
      <Route
        path="/specialised-support/:packageId/request/:requestId"
        element={
          <>
            <AdminTherapySpecialisedSupportReview />
          </>
        }
      />

      {/* Legacy */}
      {[
        { from: "billing", to: "/admin/settings/billing" },
        { from: "access", to: "/admin/settings/access" },
        {
          from: "access/invite/email",
          to: "/admin/settings/access/invite/email",
        },
        {
          from: "access/invite/slack",
          to: "/admin/settings/access/invite/slack",
        },
        {
          from: "access/invite/bereavement",
          to: "/admin/settings/access/invite/bereavement",
        },
        {
          from: "access/invite/parenthood",
          to: "/admin/settings/access/invite/parenthood",
        },
        {
          from: "access/invite/adhd",
          to: "/admin/settings/access/invite/adhd",
        },
        {
          from: "access/invite/adhd/co-pay",
          to: "/admin/settings/access/invite/adhd/co-pay",
        },
        {
          from: "access/invite/custom",
          to: "/admin/settings/access/invite/custom",
        },
        {
          from: "therapy/activity",
          to: "/admin/activity",
        },
        {
          from: "therapy/specialised-support",
          to: "/admin/specialised-support",
        },
        {
          from: "therapy/settings",
          to: "/admin/settings/plan-settings",
        },
        {
          from: "therapy/needs",
          to: "/admin/settings/needs",
        },
        {
          from: "therapy/specialised-support",
          to: "/admin/specialised-support",
        },
        {
          from: "therapy/specialised-support/guide",
          to: "/admin/specialised-support/guide",
        },
        {
          from: "therapy/specialised-support/*",
          to: "/admin/specialised-support",
        },
      ].map(({ from, to }) => (
        <Route key={from} path={from} element={<Navigate to={to} replace />} />
      ))}
    </Routes>
  )
}
