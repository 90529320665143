/**
 * Once the user has added the Spill app to their company platform, they'll need
 * to link already existing Spill accounts to the Slack/Teams accounts.
 */

import { FunctionComponent, useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { H2, P } from "@spillchat/puddles"

import { AccountLinking } from "features/admin/components/AccountLinking"
import { NotFoundPage } from "common/components/NotFoundPage"

interface Props {
  setParentPlatformType: (platformType: string | undefined) => void
  restrictFlag: boolean
}

export const LinkUserAccounts: FunctionComponent<Props> = ({
  setParentPlatformType,
  restrictFlag,
}) => {
  const navigate = useNavigate()
  const { platformType } = useParams()
  useEffect(() => {
    setParentPlatformType(platformType)
  }, [platformType])
  const [searchParams] = useSearchParams()
  const companyPlatformId = searchParams.get("companyPlatformId")

  if (companyPlatformId === null || platformType == undefined)
    return <NotFoundPage />

  return (
    <div className="flex flex-col w-fit">
      <H2>Link existing accounts</H2>
      <P muted className="mt-3 mb-6">
        Some of your employees already have Spill access. Link the current
        accounts so you’re only charged once per user and so that they can
        access their old account data in their new account
      </P>
      <AccountLinking
        onLinkAll={() =>
          navigate(
            restrictFlag
              ? `/admin/settings/access/install/${platformType}/restrict?companyPlatformId=${companyPlatformId}`
              : `/admin/settings/access/install/${platformType}/confirmation?companyPlatformId=${companyPlatformId}`
          )
        }
      />
      <br></br>
    </div>
  )
}
